import React from 'react'; 
import ReactDOM from 'react-dom';
import createCache from '@emotion/cache';

import { getCss } from './get-css';
import { CONSTANTS, PAGE_BUILDER_ENDPOINTS } from './constants';
import { createSession, getSession, updateSession } from './Session';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BreakingNews, BreakingNewsTwo, QuickViewTwo, ListViewTwo, HomeGridViewTwo, Cell, GridView, Header, Footer, Link, BreadCrumbs, ListView, HeroLayout, HeroLayout2, AuthorShow, PageNotFound, VideoShow, Row, Text, Textnode, ArticleShow, SlideShow, WebStories, WebStoriesTwo, HeroLayoutTwo, GridViewTwo, WebStoriesListTwo, MediaListTwo, PhotoListTwo, ArticleShowTwo, VideoShowTwo, SlideShowTwo, FooterTwo, HeaderTwo, BreadCrumbsTwo, AuthorShowTwo, PageNotFoundTwo, RhsHomeOtherTwo, RhsHomeTwo, HeaderTwoAmp, FooterTwoAmp, ArticleShowTwoAmp, BreadCrumbsTwoAmp, PhotoShowTwoAmp, VideoShowTwoAmp, BreakingNewsTicker, PodcastTwo,  BreakingNewsThree, BreakingNewsThreeTicker,QuickViewThree, ListViewThree, HomeGridViewThree, WebStoriesThree, WebStoriesListThree, HeroLayoutThree, HeroLayoutHrThree, RhsHomeThree, RhsHomeOtherThree, GridViewThree, MediaListThree, PhotoListThree, ArticleShowThree, VideoShowThree, PodcastThree, SlideShowThree, FooterThree, HeaderThree, BreadCrumbsThree, AuthorShowThree, StaticPageThree, PageNotFoundThree, ArticleShowThreeAmp, HeaderThreeAmp, FooterThreeAmp, BreadCrumbsThreeAmp, PhotoShowThreeAmp, VideoShowThreeAmp,HeroLayoutwithHeader, FourLinks, HomeWebStories, HomePhotoGallery, HomeFaq, FaqList, OtherListView, Experts  } from 'm360-react-components';
import { faTwitter, faFacebookF, faLinkedinIn, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { GeneralComponent } from 'm360-react-components';
import AddScripts from './../components/AddScripts/AddScripts';
import AddScriptsTwo from './../components/AddScriptsTwo/AddScriptsTwo';
import AddScriptsThree from './../components/AddScriptsThree/AddScriptsThree';
import { hashSync } from 'm360-react-components';
import axios from 'axios';
import { getComponentFromNameAndThemeId } from './facto-components';
import { json } from 'react-router';
let infoToastId = null;
let errorToastId = null;
let warnToastId = null;


export const getComponentsFromStorage = () => {
    let comps = localStorage.getItem('comps');
    if (!comps) {
        return [];
    }
    return JSON.parse(comps);
}

export const getCssFromStorage = () => {
    let css = localStorage.getItem('css');
    if (!css) {
        return [];
    }
    return JSON.parse(css);
}

export const getFormattedCss = (css) => {
    let i_css = css;
    try {
        i_css = JSON.parse(i_css);
    } catch (error) {
        console.error('Error parsing css:', error);
    }
    return i_css;
}

export const persistData = (comps, css) => {
    comps = comps.replaceAll(CONSTANTS.ENV.M360_STAGING, CONSTANTS.ENV.M360_PROD);

    /* 
    let compsJ = JSON.parse(comps);
    let themeId = 9;
    console.log("BEFORE",compsJ);
    fixComponentsNames(compsJ, themeId);
    console.log("AFTER",compsJ);
 */
    localStorage.setItem('comps', comps);
    localStorage.setItem('css', css);

    if (comps == '' && css == '') {
        localStorage.removeItem("gjsProject");
        localStorage.removeItem("comps");
        localStorage.removeItem("css");
    }

}

export const checkForDummyIDs = (comps, defaultIds = []) => {
    for (let i in comps) {
        let comp = comps[i];

        if (comp.components !== undefined && comp.components && comp.components.length > 0) {
            if (checkForDummyIDs(comp.components, defaultIds)) {
                return true;
            }
        }

        if (comp && comp.type && ((comp.attributes.STATIC_MAPPING_TRAIT && !comp.attributes.dataMappingTrait) || (comp.attributes.dataMappingTrait && comp.attributes.dataMappingTrait.componentBinder && comp.attributes.dataMappingTrait.componentBinder == 'static' && !comp.attributes.dataMappingTrait.selectContainer))) {
            return true;
        }
    }
}

export const getClasses = (component) => {
    let classes = '';
    if (component && component.classes) {
        classes = component.classes.map(e => e.name).join(' ');
    }
    if ((!classes || classes == ' ') && component && component.classes) {
        return component.classes.join(' ');
    }
    return classes;
};

export const getTag = (component) => {
    let TagName = "div"
    if (component && component.tagName) {
        TagName = component.tagName;
    }
    return TagName;
};

export const getQueryParams = (queryParams) => {
    if (!queryParams) {
        return '';
    }
    return Object.entries(queryParams).map(e => e.join('=')).join('&');
}

export const reRenderComponent = (editor, props) => {
    if (editor && props) {
        let compId = props.id;
        let comp = null;
        let comps = editor.getComponents().filter(e => e.ccid === compId);
        if (!comps || !comps.length) {
            editor.getComponents().forEach(i_c => {
                i_c.forEachChild(i_i_c => {
                    if (i_i_c.cid === compId) {
                        comp = i_i_c;
                        return false;
                    }
                });
            })
        } else {
            comp = comps[0];
        }

        const ElmToRerender = comp.attributes.component;
        let ElmRender = <ElmToRerender {...comp.attributes.attributes} />
        //createRoot(comp.getEl()).render(ElmRender);
        ReactDOM.render(ElmRender, comp.getEl());
    }
}

export const loadStyles = (editor, styles) => {
    if (editor) {
        editor.addStyle(styles);
        let css = getCss(editor);
        css = uniqueStyles(editor, css);
        editor.setStyle('');
        editor.setStyle(css);
    }
}

export const uniqueStyles = (editor, style) => {

    let ids = [];
    editor.getComponents().forEach(cc => {
        ids.push(".css_" + cc.cid);
        cc.forEachChild(i_cc => {
            ids.push(".css_" + i_cc.cid);
        });
    });

    if (!ids || !ids.length) {
        return '';
    }

    return style.split('}')
        .map(function (rule) {
            return rule ? rule.trim() + '}' : '';
        })
        .filter(function (rule, index, self) {
            return (rule.includes(".css_") && ids.includes(rule.split("_", 2).join("_")) || !rule.includes(".css_")) && self.indexOf(rule) === index;
        })
        .join(' ');
}

export const openTraitManager = (editor, tags, compId) => {

    if (!editor) {
        return;
    }

    editor.getComponents().forEach(cc => {
        cc.set("status", "");
        cc.set("hoverable", false);
        cc.forEachChild(i_cc => {
            i_cc.set("status", "");
            i_cc.set("hoverable", false);
        });
    });

    editor.getComponents().forEach(comp => {
        if (comp.attributes && comp.attributes.attributes && comp.attributes.attributes.COMPONENT && comp.attributes.attributes.COMPONENT.tags) {
            if (comp.ccid != compId) {
                comp.attributes.attributes.COMPONENT.tags.added = false;
            }
        }

    });

    if (editor && tags && compId) {
        let tagLabels = Object.keys(tags);
        tagLabels.forEach(tagLabel => {
            let tagObj = tags[tagLabel];

            if (tagObj.trait && tagObj.traits) {
                let flattedObj = flatten(tagObj);
                tagObj.traits.forEach(traitObj => {

                    let traitName = Object.keys(traitObj)[0];
                    let traitHeading = traitObj[traitName];
                    let comp = null;
                    let comps = editor.getComponents().filter(e => e.ccid === compId);
                    if (!comps || !comps.length) {
                        editor.getComponents().forEach(i_c => {
                            i_c.forEachChild(i_i_c => {
                                if (i_i_c.cid === compId) {
                                    comp = i_i_c;
                                    return false;
                                }
                            });
                        })
                    } else {
                        comp = comps[0];
                    }

                    if (comp.getTrait("id")) {
                        comp.removeTrait("id");
                    }
                    if (comp.getTrait("title")) {
                        comp.removeTrait("title");
                    }

                    let i_traitName = compId + "_" + tagLabel + "_" + traitName;
                    let traitValue = '';
                    for (let i_key in flattedObj) {
                        if (traitName.endsWith(tagLabel + "." + i_key)) {
                            traitValue = flattedObj[i_key];
                            break;
                        }
                    }
                    if (!comp.getTrait(i_traitName)) {
                        let traitLabel = traitName;
                        if (traitLabel.includes('.')) {
                            let traitLabelArr = traitLabel.split('.');
                            traitLabel = traitLabelArr[traitLabelArr.length - 1];
                        }

                        if (traitHeading) {
                            traitLabel = traitHeading;
                        }

                        let traitType = 'text';
                        if (i_traitName.toLocaleLowerCase().includes("color")) {
                            traitType = "color";
                        }

                        comp.addTrait({
                            type: traitType,
                            label: traitLabel,
                            name: traitName,
                            value: traitValue
                        });

                        const ElmToRerender = comp.attributes.component;
                        let ElmRender = <ElmToRerender {...comp.attributes.attributes} />
                        ReactDOM.render(ElmRender, comp.getEl());

                        editor.TraitManager.addType(traitType, {
                            onChange(value, oldValue) {
                                setTimeout(() => {
                                    let nestedAttributePath = this.model.attributes.name;
                                    const nestedAttributeParts = nestedAttributePath.split('.');

                                    const i_component = editor.getSelected();
                                    let currentObj = i_component.get('attributes');
                                    for (let i = 0; i < nestedAttributeParts.length - 1; i++) {
                                        currentObj = currentObj[nestedAttributeParts[i]];
                                    }

                                    currentObj[nestedAttributeParts[nestedAttributeParts.length - 1]] = value.target.value;
                                    editor.store();

                                    const ElmToRerender = this.getComponent().attributes.component;
                                    let ElmRender = <ElmToRerender {...this.getComponent().attributes.attributes} />
                                    //createRoot(this.getComponent().getEl()).render(ElmRender)
                                    ReactDOM.render(ElmRender, this.getComponent().getEl());

                                    //i_component.views.forEach( view => view.render() );
                                    /* i_component.set("hoverable", false);
                                    i_component.set("status", "");
                                    i_component.trigger('rerender');
                                    i_component.set("hoverable", false);
                                    i_component.set("status", ""); */
                                });

                            }
                        });
                    }

                });

            }

        });

        tags.added = true;
    }

}

export const addTraitsAndDynamicStyles = (editor, tagObj, compId, tagLabel) => {

    if (editor) {
        editor.getComponents().forEach(cc => {
            cc.set("status", "");
            cc.set("hoverable", false);
            cc.forEachChild(i_cc => {
                i_cc.set("status", "");
                i_cc.set("hoverable", false);
            })
        });
    }

    if (tagObj !== undefined && tagObj && compId) {
        let classNameValue = "";
        let styles = '';

        if (tagObj.style) {
            Object.keys(tagObj.style).forEach(ikey => {
                styles += (camelToSnakeCase(ikey) + ":" + tagObj.style[ikey]) + ";";
            });
        }

        if (tagObj.className) {
            classNameValue = tagObj.className;
        }

        let dynamicClass = "css_" + compId + "_" + tagLabel;
        let dynamicStyle = '';
        if (styles) {
            classNameValue += (" " + dynamicClass);
            dynamicStyle = "." + dynamicClass + "{" + styles + "}";
        }

        if (editor && styles) {
            editor.CssComposer.remove("." + dynamicClass);
            editor.addStyle(dynamicStyle);
        }

        return classNameValue;
    }
    return '';
};

function flatten(obj) {
    var newObj = {};
    for (var key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            var temp = flatten(obj[key])
            for (var key2 in temp) {
                newObj[key + "." + key2] = temp[key2];
            }
        } else {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const getID = (component) => {
    if (component && component.attributes && component.attributes.id) {
        return component.attributes.id;
    }
    return '';
};

export const getAttr = (component, attr) => {
    if (component && component.attributes && component.attributes[attr] !== undefined) {
        return component.attributes[attr];
    }
    return '';
};

export const getMUICache = () => {
    let classes = document.getElementsByClassName("gjs-frame");
    if (classes && classes.length > 0) {
        const container = classes[0].contentWindow.document.head;
        return createCache({
            key: 'css',
            container: container,
            prepend: true
        });
    }

    return createCache({
        key: 'css',
        prepend: true
    });;
}

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

export const getComponent = (json) => {
    const Components = {
        "Row": Row,
        "Cell": Cell,
        "link": Link,
        "text": Text,
        "textnode": Textnode,
        "General Component": GeneralComponent,

        "Breaking News": BreakingNews,
        "Breaking News": BreakingNewsTwo,
        "Breaking News Ticker": BreakingNewsTicker,
        "Quick View": QuickViewTwo,
        "List View Two": ListViewTwo,
        "Home Grid View Two": HomeGridViewTwo,
        "WebStories Two": WebStoriesTwo,
        "HeroLayout Two": HeroLayoutTwo,
        "GridView Two": GridViewTwo,
        "GridView Other Two": GridViewTwo,
        "WebStoriesList Two": WebStoriesListTwo,
        "MediaList Two": MediaListTwo,
        "PhotoList Two": PhotoListTwo,
        "Article Show Two": ArticleShowTwo,
        "Video Show Two": VideoShowTwo,
        "Slide Show Two": SlideShowTwo,
        "Footer Two": FooterTwo,
        "Header Two": HeaderTwo,
        "Bread Crumbs Two": BreadCrumbsTwo,
        "Author Show Two": AuthorShowTwo,
        "Static Page Two": StaticPageTwo,
        "Page Not Found Two": PageNotFoundTwo,
        "RhsHome Other Two": RhsHomeOtherTwo,
        "Podcast Ywo": PodcastTwo,
        "RhsHome Two": RhsHomeTwo,
        "Header Two Amp": HeaderTwoAmp,
        "Footer Two Amp": FooterTwoAmp,
        "Article Show Two Amp": ArticleShowTwoAmp,
        "Bread Crumbs Two Amp": BreadCrumbsTwoAmp,
        "Photo Show Two Amp": PhotoShowTwoAmp,
        "Video Show Two Amp": VideoShowTwoAmp,
        "List View": ListView,
        "Grid View": GridView,
        "Grid View2": GridView,
        "Header": Header,
        "Footer": Footer,
        "Article Show": ArticleShow,
        "Bread Crumbs": BreadCrumbs,
        "Hero Layout": HeroLayout,
        "Hero Layout2": HeroLayout2,
        "Author Show": AuthorShow,
        "Page Not Found": PageNotFound,
        "Video Show": VideoShow,
        "Carousel Card Block": GridView,
        "Similar Stories": ListView,
        "Slide Show": SlideShow,
        "Web Stories": WebStories,
        "Web Stories2": WebStories,
        "Add Scripts": AddScripts,
        "Add Scripts Amp": AddScripts,


        "HeroLayout Hr Three": HeroLayoutHrThree,
        "Breaking News": BreakingNewsThree,
        "Breaking News Three Ticker": BreakingNewsThreeTicker,
        "Quick View": QuickViewThree,
        "List View Three": ListViewThree,
        "Home Grid View Three": HomeGridViewThree,
        "WebStories Three": WebStoriesThree,
        "HeroLayout Three": HeroLayoutThree,
        "GridView Three": GridViewThree,
        "GridView Other Three": GridViewThree,
        "WebStoriesList Three": WebStoriesListThree,
        "MediaList Three": MediaListThree,
        "PhotoList Three": PhotoListThree, 
        "Home Photo Gallery": HomePhotoGallery, 
        "Home Web Stories": HomeWebStories, 
        "Article Show Three": ArticleShowThree,
        "Video Show Three": VideoShowThree,
        "Slide Show Three": SlideShowThree,
        "Footer Three": FooterThree,
        "Header Three": HeaderThree,
        "Bread Crumbs Three": BreadCrumbsThree,
        "Author Show Three": AuthorShowThree,
        "Static Page Three": StaticPageThree,
        "Page Not Found Three": PageNotFoundThree,
        "RhsHome Other Three": RhsHomeOtherThree,
        "Podcast Ywo": PodcastThree,
        "RhsHome Three": RhsHomeThree,
        "Header Three Amp": HeaderThreeAmp,
        "Footer Three Amp": FooterThreeAmp,
        "Article Show Three Amp": ArticleShowThreeAmp,
        "Bread Crumbs Three Amp": BreadCrumbsThreeAmp,
        "Photo Show Three Amp": PhotoShowThreeAmp,
        "Video Show Three Amp": VideoShowThreeAmp,
        "HeroLayout with Header":HeroLayoutwithHeader,
        "Four Links":FourLinks,
        "Home Faq":HomeFaq,
        "Faq List":FaqList,
        "Experts":Experts,
        "OtherList View":OtherListView,


    }

    return json.map((component, i) => {
        let ComponentName = null;
        if (component.name === undefined && component.type === undefined) {
            ComponentName = GeneralComponent;
        } else {
            ComponentName = Components[component.name ? component.name : component.type];
        }

        if (!ComponentName) {
            return "";
        }
        if (component.attributes && component.attributes.functions) {
            Object.keys(component.attributes.functions).forEach(i_key => {
                component.attributes[i_key] = eval(component.attributes.functions[i_key]);
            });
        }
        return <ComponentName key={i} data={component} {...component.attributes}></ComponentName>
    });
};

export const getPlainComponents = (components) => {
    components.map((component, i) => {
        return component.content;
    });
}

export const getEndpoint = (props, name = "MAIN") => {
    if (props.ENDPOINTS) {
        return props.ENDPOINTS.find((e) => e.name === name);
    }

    return null;
}

export const convertEpochTime = (epochTimestamp, publishedOrUpdated, dateTimeFormat) => {
    let formattedDateTime;
    let prefix;
    const date = new Date(+epochTimestamp);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // const ampm = date.getHours() >= 12 ? "PM" : "AM";
    // const day = date.getDate();
    // const month = months[date.getMonth()];
    // const year = date.getFullYear();
    // const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    // const minutes = date.getMinutes();

    if (publishedOrUpdated === 'published') {
        prefix = 'Published: ';
    } else {
        prefix = 'Updated: ';
    }
    if (dateTimeFormat == 'MM-dd-yy h:mm a') {
        const month = months[date.getMonth()];
        var day = date.getDate().toString().padStart(2, '0');
        var year = date.getFullYear();
        var hour = date.getHours().toString().padStart(2, '0');
        var minute = date.getMinutes().toString().padStart(2, '0');
        formattedDateTime = `${prefix} ${month} ${day}, ${year} ${hour}:${minute} IST`;
    }
    // formattedDateTime = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    return formattedDateTime;
}

export const createSessionObject = () => {
    return createSession();

}
export const getSessionObject = (objectName) => {
    return getSession(objectName);

}

export const updateSessionObject = (objectName, data, type) => {
    return updateSession(objectName, data, type);
}

export const appendStyleSheet = (componentName, styles) => {
    try {
        let iframes = document.getElementsByClassName("gjs-frame");
        if (iframes) {
            let iframeHead = iframes[0].contentWindow.document.head;
            if (iframeHead) {
                let alreadyExists = false;
                let existingStyles = iframeHead.getElementsByTagName("style");
                for (let eStyle of existingStyles) {
                    if (eStyle.getAttribute("name") && eStyle.getAttribute("name") == componentName) {
                        alreadyExists = true;
                        if (eStyle.getAttribute("name") == "GLOBALSTYLES") {
                            //override styles on change event
                            eStyle.innerHTML = styles
                        }
                    }
                }

                if (!alreadyExists) {
                    const style = document.createElement("style");
                    iframeHead.appendChild(style);
                    style.setAttribute("name", componentName);
                    style.innerHTML = styles;
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const objectToStylesheet = (cssObject) => {
    let stylesheet = '';

    for (const selector in cssObject) {
        if (cssObject.hasOwnProperty(selector)) {
            const rules = cssObject[selector];

            let rulesString = '';
            for (const property in rules) {
                if (rules.hasOwnProperty(property)) {
                    const value = rules[property];
                    rulesString += `  ${property}: ${value};\n`;
                }
            }

            stylesheet += `${selector} {\n${rulesString}}\n\n`;
        }
    }

    return stylesheet;
}

export const showSuccessToast = (msg) => {
    if (infoToastId) {
        toast.dismiss(infoToastId); // Dismiss the previous toast if it's still visible
    }

    const newToast = toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000
    });

    infoToastId = newToast; // Set the active toast ID

    // Add a focus event listener to dismiss the toast when the tab becomes active
    window.addEventListener('focus', () => {
        toast.dismiss(newToast); // Dismiss the toast when tab becomes active
        infoToastId = null; // Reset the active toast ID
    });
};

export const showErrorToast = (msg) => {
    if (errorToastId) {
        toast.dismiss(errorToastId); // Dismiss the previous toast if it's still visible
    }

    const newToast = toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000
    });

    errorToastId = newToast; // Set the active toast ID

    // Add a focus event listener to dismiss the toast when the tab becomes active
    window.addEventListener('focus', () => {
        toast.dismiss(newToast); // Dismiss the toast when tab becomes active
        errorToastId = null; // Reset the active toast ID
    });
};

export const showWarningToast = (msg) => {
    if (warnToastId) {
        toast.dismiss(warnToastId); // Dismiss the previous toast if it's still visible
    }

    const newToast = toast.warn(msg, {
        position: toast.POSITION.TOP_RIGHT,
        style: { width: '370px', marginLeft: '-40px', marginTop: '50px' },
        autoClose: 3000,
    });

    warnToastId = newToast; // Set the active toast ID

    // Add a focus event listener to dismiss the toast when the tab becomes active
    window.addEventListener('focus', () => {
        toast.dismiss(newToast); // Dismiss the toast when tab becomes active
        warnToastId = null; // Reset the active toast ID
    });
};

export const getSocialClassName = (name) => {
    let className = faFacebookF;

    switch (name) {
        case "facebookprofilepage": {
            className = faFacebookF
            break;
        }
        case "twitterhandle": {
            className = faTwitter
            break;
        }
        case "instagramhandle": {
            className = faInstagram
            break;
        }
        // case "Instagram" : {
        //     className = "fa-instagram"
        //     break;
        // }
        // case "Youtube" : {
        //     className = "fa-youtube"
        //     break;
        // }
        // case "Flickr" : {
        //     className = "fa-flickr"
        //     break;
        // }
        // case "Google" : {
        //     className = "fa-google"
        //     break;
        // }
        case "Pintrest": {
            className = faPinterestP
            break;
        }
        // case "Amazon" : {
        //     className = "fa-amazon"
        //     break;
        // }
        // case "Desktop" : {
        //     className = "fa-desktop"
        //     break;
        // }
    }

    return className;
}

export const getUrl = (props, pageId = 1, isLoadMore = false, name = "MAIN", isNotListing = false) => {
    let url = '';
    const endpoint = getEndpoint(props, name);

    if (endpoint && endpoint.LIVE_API_URL) {
        url = endpoint.LIVE_API_URL;
        if (props.ENV === 'M360_STAGING') {
            url = endpoint.STAG_API_URL;
        }

        //Case of article show URL
        if (isNotListing && props.title && props.title === "Article Show" || "StaticPage" || "StaticPageTwo") {
            url += `?msid=${props.MSID}&&fv=1000&hostId=83`;
            return url;
        } else if (isNotListing) {
            return url;
        }

        let pp = 10;
        if (endpoint.queryParams && endpoint.queryParams.pp !== undefined && endpoint.queryParams.pp) {
            pp = endpoint.queryParams.pp;
        }

        if (typeof endpoint.queryParams.so != 'undefined') {
            if (!isLoadMore) {
                url += `?msid=${endpoint.queryParams.msid}&fv=${endpoint.queryParams.fv}&hostId=${endpoint.queryParams.hostId}&cp=${pageId}&apiType=${endpoint.queryParams.apiType}&ct=${endpoint.queryParams.ct}&st=${endpoint.queryParams.st}&so=${endpoint.queryParams.so}&pp=${endpoint.queryParams.pp}`
            } else {
                url += `?msid=${endpoint.queryParams.msid}&fv=${endpoint.queryParams.fv}&hostId=${endpoint.queryParams.hostId}&cp=${pageId}&apiType=${endpoint.queryParams.apiType}&ct=${endpoint.queryParams.ct}&st=${endpoint.queryParams.st}&so=${endpoint.queryParams.so}&pp=20`
            }
        } else {
            if (!isLoadMore) {
                url += `?msid=${props.MSID}&source=toi&dm=t&cp=${pageId}&apiType=h&tn=html&onlyItems=4&pp=${pp}`
            } else {
                url += `?msid=${props.MSID}&source=toi&dm=t&cp=${pageId}&apiType=h&tn=html&onlyItems=4&pp=20`
            }
        }
    }
    return url;
}

//This will return Author data along with its source URLs.
export const showMultipleAuthor = (authorData, baseDomain) => {
    if (authorData && authorData.length > 0) {
        let multipleAuthor = '';
        if (authorData.length == 1) {
            return `<a href=${createAuthorLink(baseDomain, authorData[0])}> ${authorData[0].name}</a>`;
        }
        if (authorData.length == 2) {
            return `<a href=${createAuthorLink(baseDomain, authorData[0])}> ${authorData[0].name}</a> & <a href=${createAuthorLink(baseDomain, authorData[1])}> ${authorData[1].name}</a></>`;
        }
        for (let index = 0; index < authorData.length - 1; index++) {
            multipleAuthor += `<a href=${createAuthorLink(baseDomain, authorData[index])}> ${authorData[index].name}</a>` + ', ';
        }
        multipleAuthor = multipleAuthor.substring(0, multipleAuthor.length - 2) + ' & ' + `<a href=${createAuthorLink(baseDomain, authorData[authorData.length - 1])}> ${authorData[authorData.length - 1].name}</a>`;
        return multipleAuthor;
    } else {
        return 'M360 Desk';
    }
}

export const appendScriptToHeadOrBody = (componentName, content) => {
    try {
        let newElement;
        let iframes = document.getElementsByClassName("gjs-frame");
        if (iframes) {
            let iframe = iframes[0].contentWindow.document;
            let targetElement;

            if (componentName === 'header_script') {
                targetElement = iframe.head;
            } else if (componentName === 'body_script') {
                targetElement = iframe.body;
            }

            if (targetElement) {
                const existingElements = targetElement.getElementsByClassName(componentName);

                let alreadyExists = false;
                for (let existingElement of existingElements) {
                    existingElement.remove();
                    alreadyExists = true;
                }

                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');
                if (componentName === 'header_script') {
                    if (doc.head.firstChild === null)
                        newElement = doc.body.firstChild;
                    else
                        newElement = doc.head.firstChild;
                }
                else if (componentName === 'body_script') {
                    if (doc.body.firstChild === null)
                        newElement = doc.head.firstChild;
                    else
                        newElement = doc.body.firstChild;
                }

                if (newElement) {
                    newElement.classList.add(componentName);
                    targetElement.appendChild(newElement);
                }
            }
        }

        return newElement;
    } catch (error) {
        console.log(error);
    }
}

function isValidHTML(htmlString) {
    const validTagRegex = /^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s*\/>)$/i;
    return validTagRegex.test(htmlString);
}

export const validateAdScript = (inputString) => {
    const scriptTagRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/;
    const match = inputString.match(scriptTagRegex);
    return match !== null && match[0] === inputString;
}

function validateHTML(htmlString) {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlString, 'text/html');

    // Check if parsing resulted in valid HTML
    if (document.querySelector('parsererror')) {
        return false; // Invalid HTML
    }

    return true; // Valid HTML
}

export const createAuthorLink = (baseDomain, authorData) => {
    const authorLink = '<base-domain>/<seo-name>/author/<author-id>.html';
    if (authorData)
        return authorData && authorLink.replace('<base-domain>', baseDomain).replace('<seo-name>', authorData.seoname).replace('<author-id>', authorData.id);
}

export const logoutUserSession = () => {

    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/logoutUserSession";

    const config = {
        url: url,
        method: 'POST',
    };

    httpMiddleware(config)
        .then((response) => {
            if (response.status) {
                window.iamAuthSDK.logout();
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });

}

const iamAuthLogout = () => {
    localStorage.removeItem('identity.principal');
    //.getCookie(this.cookieName) && A.deleteCookie(this.cookieName),

    const cookies = document.cookie.split(';').reduce((cookiesObj, cookie) => {
        const [name, value] = cookie.trim().split('=');
        cookiesObj[name] = value;
        return cookiesObj;
    }, {});

    if (cookies.includes('_iam_auth')) {

    }
}

export const createSessionifMissing = async () => {

    return new Promise((resolve) => {
        const sessoinKey = "metaData";
        let host = window.location.href.replace(/\/$/, "") + "/";
        host = host.replace(/#createSite$/, "");
        const YOUR_RETURN_URL = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/userAuthenticate";
        getUserSession()
            .then(response => {
                if (response != null && response) {
                    updateSessionObject(sessoinKey, response);
                    resolve(response);
                } else {
                    window.iamAuthSDK.login();
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                }
            }).catch((error) => {
                console.log("Error while getting session data: ", error);
                if (error.response && error.response.status === 401) {
                    window.iamAuthSDK.login();
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                }
            });

    });
}

export const getUserSession = () => {

    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/session";

    const config = {
        url: url,
        method: 'GET',
    };

    return httpMiddleware(config)
        .then((response) => {
            if (response && response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
            return null;
        });


}
export const httpMiddleware = (config) => {
    // Send the request using Axios

    // if (sessionStorage.getItem("metaData") == null && !config.url.includes("/newsroomApi/session")) {
    //     window.location.href = window.location.origin + "/";
    // }

    config.withCredentials = true;
    return axios(config)
        .then((response) => {
            // const headerKey = Object.keys(response.headers);
            // headerKey.forEach((value, key) => {
            //     if (value == "send-redirect") {
            //         window.location.href = response.headers['send-redirect'];
            //     }
            // });
            //window.iamAuthSDK.logout();

            return response;
        }).catch((error) => {
            // Handle request errors or global error handling
            console.error('Request Error:', error);
            if (error.response && error.response.status === 401) {
                window.iamAuthSDK.logout();
                //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/logout?ru=" + window.location.href.replace("/Home", "/");
            }
        });
};

// let metaData = JSON.parse(sessionStorage.getItem('metaData'))
// console.log("metaData", metaData);
// let retrievedWebsiteId = metaData && metaData.websiteId;
// let retrievedClientId = metaData && metaData.clientId;

export const getDefaultMsids = () => {
    let metaData = JSON.parse(sessionStorage.getItem('metaData'))
    let retrievedWebsiteId = metaData && metaData.websiteId;
    let retrievedClientId = metaData && metaData.clientId;

    if (retrievedWebsiteId === 0) {
        retrievedClientId = 0;
    }

    let url = PAGE_BUILDER_ENDPOINTS.HEADER_DATA_API;
    const params = `?clientId=${retrievedClientId}&websiteId=${retrievedWebsiteId}`;
    const headerString = hashSync(params, 6);
    url += params;

    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.withCredentials = true;
        xhr.send();
        if (xhr.status === 200) {
            let siteRes = JSON.parse(xhr.responseText);
            if (retrievedWebsiteId === 0) {
                siteRes.themeId = localStorage.getItem("defaultThemeId");
            }
            return siteRes;
        } else {
            console.error('Request getSiteResources failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");
        }
    } catch (error) {
        // Handle and possibly log the error
        console.error('Error:', error);
        throw error; // You may choose to rethrow the error if needed
    }

};

export const getLogoWidthAndHeight = (msid) => {
    let hostId = sessionStorage.getItem('hostId');
    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getImageHeightAndWidth";

    const params = `?hostId=${hostId}&msid=${msid}`;
    url += params;

    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.withCredentials = true;
        xhr.send();
        if (xhr.status === 200) {
            let jsonResponse = JSON.parse(xhr.responseText);
            if (jsonResponse != null && jsonResponse.originalImageHeightPx != null && jsonResponse.originalImageWidthPx != null) {
                return jsonResponse;
            } else {
                console.log("cannot fetch height and width");
                return null;
            }

            return "";;
        } else {
            console.error('Request getSiteResources failed with status: ' + xhr.status);
            // showErrorToast("Some Went Wrong, Please try again later!");
        }
    } catch (error) {
        console.error('Error:', error);
    }

};

// export const saveWebsiteResource = (formData) => {
//     let globalJson = JSON.parse(getSessionObject('globalJson'));
//     let metaData = JSON.parse(sessionStorage.getItem('metaData'))
//     let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/updateSiteResources";
//     const params = `?clientId=${metaData.clientId}&websiteId=${metaData.websiteId}`;
//     url += params;
//     try {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', url, false); // Change to POST method
//         xhr.setRequestHeader('Content-Type', 'application/json');
//         xhr.withCredentials = true;
//         const data = formData;

//         xhr.send(JSON.stringify(data));

//         if (xhr.status === 200) {
//             if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
//                 appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
//                 appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
//             }
//             return JSON.parse(xhr.responseText);
//         } else {
//             console.error('Request addSiteResources failed with status: ' + xhr.status);
//             showErrorToast("Some Went Wrong, Please try again later!");
//         }
//     } catch (error) {
//         console.error('Request addSiteResources failed with status: ' + xhr.status);
//         showErrorToast("Some Went Wrong, Please try again later!");

//     }
// };

// export const saveWebsiteResource = (formData) => {
//     console.log(formData);
//     let globalJson = JSON.parse(getSessionObject('globalJson'));
//     let metaData = JSON.parse(sessionStorage.getItem('metaData'));
//     let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/updateSiteResources";
//     const params = `?clientId=${metaData.clientId}&websiteId=${metaData.websiteId}`;
//     url += params;

//     return new Promise((resolve, reject) => {
//         try {
//             const xhr = new XMLHttpRequest();
//             xhr.open('POST', url, true); // Use asynchronous (true) instead of synchronous (false)
//             xhr.setRequestHeader('Content-Type', 'application/json');
//             xhr.withCredentials = true;
//             const data = formData;

//         if (xhr.status === 200) {
//             return JSON.parse(xhr.responseText);
//         } else {
//             console.error('Request addSiteResources failed with status: ' + xhr.status);
//             showErrorToast("Some Went Wrong, Please try again later!");
//             reject(error);
//         }
//     });
// };

export const saveWebsiteResource = (formData) => {
    let globalJson = JSON.parse(getSessionObject('globalJson'));
    let metaData = JSON.parse(sessionStorage.getItem('metaData'))
    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/updateSiteResources";
    const params = `?clientId=${metaData.clientId}&websiteId=${metaData.websiteId}`;
    url += params;

    return new Promise((resolve, reject) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, false); // Change to POST method
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;
            const data = formData;
    
            xhr.send(JSON.stringify(data));
    
            if (xhr.status === 200) {
                // return JSON.parse(xhr.responseText);
                resolve(JSON.parse(xhr.responseText));
            } else {
                console.error('Request addSiteResources failed with status: ' + xhr.status);
                showErrorToast("Some Went Wrong, Please try again later!");
                reject("");
            }
        } catch (error) {
            console.error('Request addSiteResources failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");
            reject(error);
    
        }
    }
);

};

function validateJSON(jsonData) {
    for (let obj of jsonData) {
        // Validate parent node
        if (obj.type === 1 && obj.secid === "") {
            return false;
        } else if (obj.type === 2 && obj.tagpath === "") {
            return false;
        } else if (obj.type === 3 && obj.path === "") {
            return false;
        }

        // Validate sections
        if (obj.sections && obj.sections.length > 0) {
            for (let section of obj.sections) {
                if (section.type === 1 && section.secid === "") {
                    return false;
                } else if (section.type === 2 && section.tagpath === "") {
                    return false;
                } else if (section.type === 3 && section.path === "") {
                    return false;
                }
            }
        }
    }
    return true;
}


export const saveandupdateMenu = (formData, type) => {
    return new Promise((resolve, reject) => {
      let isValid = validateJSON(formData);
      if (isValid) {
        let msg = null;
        let globalJson = JSON.parse(getSessionObject('globalJson'));
        let pageMetaData = JSON.parse(getSessionObject("metaData"));
        const retrievedHostId = sessionStorage.getItem('hostId');
        const websiteId = pageMetaData.websiteId;
        let url = `${PAGE_BUILDER_ENDPOINTS.DOMAIN}/menuManagement/updateMenu?hostId=${retrievedHostId}&key=${type}&websiteId=${websiteId}`;
        const xhr = new XMLHttpRequest();
  
        try {
          xhr.open('POST', url, false);
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              console.log(xhr.responseText);
              resolve(JSON.parse(xhr.responseText)); // Resolve the promise
            }
          };
  
          xhr.withCredentials = true;
          xhr.send(JSON.stringify(formData));
  
          if (xhr.status === 200) {
            msg = 'Menu has been updated successfully';
            if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
                appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
                appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
            }
            toast.success(msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          } else {
            console.error('Request failed with status: ' + xhr.status);
            showErrorToast("Something went wrong, please try again later!");
            reject('Request failed'); // Reject the promise
          }
        } catch (error) {
          console.error('Request failed:', error);
          showErrorToast("Something went wrong, please try again later!");
          reject(error); // Reject the promise
        }
      } else {
        showErrorToast("Some menu fields values are missing");
        reject('Validation failed'); // Reject the promise
      }
    });
  };
  


export const getImageData = (msId) => {
    let hostId = sessionStorage.getItem('hostId');
    let url = PAGE_BUILDER_ENDPOINTS.LISTING_API;
    const params = `?msid=${msId}&fv=2000&hostId=${hostId}&apiType=d`;
    url += params;

    const headerString = hashSync(params, 6);
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('p-nonce', headerString);
        xhr.send();
        if (xhr.status === 200) {
            return JSON.parse(xhr.responseText);
        } else {
            console.error('Request getImageData failed with status: ' + xhr.status);
        }
    } catch (error) {
        console.error('Request getImageData failed with status: ' + xhr.status);
    }


}

export const getThemePagesByWebsite = (clientId, websiteId) => {
    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getThemeByClientAndWebsite?clientId=" + clientId + "&websiteId=" + websiteId + "&env=0";

    try {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.withCredentials = true;
        xhr.send();
        if (xhr.status === 200) {
            return JSON.parse(xhr.responseText);
        } else {
            console.error('Request getThemePagesByWebsite failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");
        }
    } catch (error) {
        console.error('Request getThemePagesByWebsite failed with status: ' + xhr.status);
        showErrorToast("Some Went Wrong, Please try again later!");
    }
};

// export const updateCssJsonWrtThemeId = (env, attribute_object, css_object) => {
//     let pageMetaData = JSON.parse(getSessionObject("metaData"));
//     const body = {
//         themeId: pageMetaData.themeId,
//         clientId: pageMetaData.clientId,
//         websiteId: pageMetaData.websiteId,
//         env: env,
//         css: JSON.stringify(css_object),
//         json: JSON.stringify(attribute_object),
//     };
//     let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updateCssJsonWrtThemeId";

//     try {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', url, false); // The third parameter is set to false to make it synchronous
//         xhr.setRequestHeader('Content-Type', 'application/json');
//         xhr.withCredentials = true;
//         const data = body;

//         xhr.send(JSON.stringify(data));
//         if (xhr.status === 200) {
//             updateSessionObject('globalJson', attribute_object);
//             updateSessionObject('globalCss', css_object);
//             return JSON.parse(xhr.responseText);
//         } else {
//             console.error('Request updateCssJsonWrtThemeId failed with status: ' + xhr.status);
//             showErrorToast("Some Went Wrong, Please try again later!");
//         }
//     } catch (error) {
//         console.error('Request updateCssJsonWrtThemeId failed with status: ' + xhr.status);
//         showErrorToast("Some Went Wrong, Please try again later!");
//     }
// };

export const updateCssJsonWrtThemeId = (env, attribute_object, css_object) => {
    let pageMetaData = JSON.parse(getSessionObject("metaData"));
    const body = {
        themeId: pageMetaData.themeId,
        clientId: pageMetaData.clientId,
        websiteId: pageMetaData.websiteId,
        env: env,
        css: JSON.stringify(css_object),
        json: JSON.stringify(attribute_object),
    };
    let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updateCssJsonWrtThemeId";

    return new Promise((resolve, reject) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true); // Set to asynchronous
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;
            
            xhr.onload = () => {
                if (xhr.status === 200) {
                    updateSessionObject('globalJson', attribute_object);
                    updateSessionObject('globalCss', css_object);
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    console.error('Request updateCssJsonWrtThemeId failed with status: ' + xhr.status);
                    showErrorToast("Something Went Wrong, Please try again later!");
                    reject(xhr.status);
                }
            };

            xhr.onerror = () => {
                console.error('Request updateCssJsonWrtThemeId failed with status: ' + xhr.status);
                showErrorToast("Something Went Wrong, Please try again later!");
                reject(xhr.status);
            };

            xhr.send(JSON.stringify(body));
        } catch (error) {
            console.error('Request updateCssJsonWrtThemeId failed with error: ', error);
            showErrorToast("Something Went Wrong, Please try again later!");
            reject(error);
        }
    });
};

export const isValidUrl = (url) => {
    if (url != undefined && url === '') {
        return true;
    }
    if (url && url.length > 0) {
        //Logic for without domain url validation
        if (url.charAt(0) === '/') {
            const urlRegex = /^\/[^\s]+([-._~:/?#[\]@!$&'()*+,;=])?[^\s]*$/;
            return urlRegex.test(url);
        }
        //Logic for with domain url validation
        else {
            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
            return urlRegex.test(url);
        }
    }
}

export const findValueByKeyInJson = (json, key) => {
    let result = [];
    function traverse(obj, parentValue) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (prop === key) {
                    if (key === "mainHeadingHref") {
                        result.push(obj[prop] + " | " + obj['mainHeading'] + " | " + parentValue);
                    } else if (key === "viewAllUrl"
                        || ((key === "headAdScript" || key === "bodyAdScript" || key === "bodyAdMobileScript") && obj['showAdSlot'])
                        || ((key === "headAdScript2" || key === "bodyAdScript2" || key === "bodyAdMobileScript2") && obj['showAdSlot2'])) {
                        result.push(obj[prop] + " | " + obj['title'] + " | " + parentValue);
                    }
                } else if (typeof obj[prop] === 'object') {
                    traverse(obj[prop], obj['type']);
                }
            }
        }
    }
    traverse(json, '');
    return result;
}

export const checkFrValidUrlsInPageTemplate = (comps) => {
    let urlBreakingNews = findValueByKeyInJson(JSON.parse(comps), 'mainHeadingHref');
    for (let index = 0; index < urlBreakingNews.length; index++) {
        const element = urlBreakingNews[index];

        const urlValues = element.split(" | ");
        const url = urlValues[0];
        const title = urlValues[1];
        const component = urlValues[2];

        if (!isValidUrl(url)) {
            if (title && title != "") {
                showErrorToast("Title URL is not correct! Please check component having heading name : " + title);
            } else {
                showErrorToast("Title URL is not correct! Please check " + component + " component");
            }
            return false;
        }
    }

    let urlViewAll = findValueByKeyInJson(JSON.parse(comps), 'viewAllUrl');
    for (let index = 0; index < urlViewAll.length; index++) {
        const element = urlViewAll[index];

        const urlValues = element.split(" | ");
        const url = urlValues[0];
        const title = urlValues[1];
        const component = urlValues[2];

        if (!isValidUrl(url)) {
            if (title && title != "") {
                showErrorToast("View All Url is not correct! Please check component having heading name : " + title);
            } else {
                showErrorToast("View All Url is not correct! Please check " + component + " component");
            }
            return false;
        }
    }

    let headAdScript = findValueByKeyInJson(JSON.parse(comps), 'headAdScript');
    for (let index = 0; index < headAdScript.length; index++) {
        const element = headAdScript[index];
        const urlValues = element.split(" | ");
        const url = urlValues[0];
        const title = urlValues[1];
        const component = urlValues[2];
        // if (url === '') {
        //     showErrorToast("Head script cannot be blank in adslot for component : " + component + " with title: " + title);
        //     return false;
        // }
        if (!url === "" && !hasScriptAttributes(url)) {
            showErrorToast("Head script must include async, defer, type=\"module\" : " + component + " with title: " + title);
            return false;
        }
    }

    let bodyAdScript = findValueByKeyInJson(JSON.parse(comps), 'bodyAdScript');
    let bodyAdMobileScript = findValueByKeyInJson(JSON.parse(comps), 'bodyAdMobileScript');
    for (let index = 0; index < bodyAdScript.length; index++) {
        const element = bodyAdScript[index];
        const urlValues = element.split(" | ");
        const url = urlValues[0];
        const title = urlValues[1];
        const component = urlValues[2];

        const element1 = bodyAdMobileScript[index];
        const urlValues1 = element1.split(" | ");
        const url1 = urlValues1[0];
        if (url === '' && url1 === '') {
            showErrorToast("Both body script cannot be blank in adslot for component : " + component + " with title: " + title);
            return false;
        }
    }
    return true;
}

function hasScriptAttributes(scriptString) {
    const attributesRegex = /\b(?:async|defer|type\s*=\s*["']\s*module\s*["'])\b/g;
    const matches = scriptString.match(attributesRegex) || [];
    return matches.includes('async') || matches.includes('defer') || matches.includes('type="module"');
}

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}

export const iamAuthSDKCheck = () => {
    return window.iamAuthSDK && window.iamAuthSDK.authenticate();
}


