import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import './MyAutoComplete.css'

const MyAutocompleteComponent = ({ isDisabledAuto, selectContainerOnChange, selectContainerInnerOnChange, values, formData }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [searchedSectionInside, setSearchedSectionInside] = useState([]);
    //const [somethingMeaningful, setSomethingMeaningful] = useState(1);

    useEffect(() => {
        setSelectedValues(values);
    }, [values])

    const handleChange = (event, newValue) => {
        if (event.currentTarget.getAttribute("data-testid") === "CancelIcon") {
            setSelectedValues(newValue);
            selectContainerOnChange(newValue);
        } else if (event.currentTarget.getAttribute("title") === "Clear") {
            setSelectedValues(newValue);
            selectContainerOnChange(newValue);
        } else {
            newValue.pop()
            newValue.push(event.currentTarget.innerHTML.split(" - ")[1]);
            const uniqueArray = [...new Set(newValue)]
            setSelectedValues(uniqueArray);
            selectContainerOnChange(uniqueArray);
        }
    };

    return (
        <Autocomplete
            multiple
            id="autocomplete-select-container-id"
            disabled={isDisabledAuto}
            options={searchedSectionInside}
            getOptionLabel={(option) => {
                if (typeof option.msid === 'undefined') {
                    return Array.from(option.split(","))
                }
                return `${option.name} - ${option.msid} - ${option.path}`
            }
            }
            style={{
                fontSize: 'unset',
                background: isDisabledAuto ? '#F6F6F6' : 'rgb(248, 250, 252)'
            }}
            filterSelectedOptions
            value={selectedValues}
            onChange={handleChange}
            renderInput={(params) => {
                //params.inputProps.value = tempTextFieldValue
                if (params.inputProps.ref.current != null) {
                    if (formData?.filteringType != 'cp' && formData?.filteringType != 'ch' && selectedValues?.length !== 0) {
                        params.inputProps.ref.current.style.display = 'none';
                        if (searchedSectionInside?.length !== 0) {
                            setSearchedSectionInside([]);
                        }
                    } else {
                        params.inputProps.ref.current.style.display = 'block';
                    }
                }
                return (
                    <TextField
                    className='manageHeightTextfild'
                        {...params}
                        disabled={isDisabledAuto}
                        placeholder="Add a container"
                        onChange={(e) => {
                            const top100films = selectContainerInnerOnChange(e);
                            setSearchedSectionInside(top100films);
                        }}
                    />
                )

            }}
        />
    );
};

export default MyAutocompleteComponent;
