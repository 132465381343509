import React ,{ useState, useEffect } from "react";
import { showWarningToast } from "../Utils";

export const ShowActiveUsersPopup = ({ jsonData }) => {
 
    useEffect(() => {
      if (jsonData != null) {
        const userIdsArray = Object.keys(jsonData);
        if(userIdsArray.length>0){
        createToastrMsg(userIdsArray);
        }
      }
      
    }, [jsonData]);
  
    const createToastrMsg=(userIdsArray)=>{

      let headerMsg="Attention: Collaborative Editing In Progress!"
      let msg=null;
      if(userIdsArray.length==1)
      {
        msg=(
          <div>
            <strong>{headerMsg}</strong>
            <br />
            {userIdsArray[0].split("@")[0]} is simultaneously editing this page.
          </div>
        );
        showWarningToast(msg);

      }
      else if(userIdsArray.length>1)
      {
        let finalmsg=null;
        for (let i = 0; i < userIdsArray.length; i++) {
          if (msg === null) {
            msg = userIdsArray[i].split("@")[0];
          }
          else
          {
            msg = msg+", "+userIdsArray[i].split("@")[0];
          }
          finalmsg=(
            <div>
              <strong>{headerMsg}</strong>
              <br />
              {msg} are simultaneously editing this page.
            </div>
          );
      }
      showWarningToast(finalmsg);

    }
    
  };
  
  
}  
  
  
  
  