import { Box, Button, Checkbox, Dialog, FormControl, FormGroup, FormHelperText, Grid, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import './AddConfiguration.css'
import { useEffect, useState } from "react";
import { PAGE_BUILDER_ENDPOINTS } from "../../core/constants";
import { showSuccessToast } from "../../core/Utils";
import { ClosePopup } from "./ClosePopup";


export const AddConfiguraiton = (props) => {
    const [configFormData, setConfigFormData] = useState([]);
    //const [checkboxValues, setCheckboxValues] = useState({});
    const [error, setError] = useState({});
    const [closeOverlay, setCloseOverlay] = useState(false);

    useEffect(() => {
        if (props.selectedItem != null) {
            setConfigFormData(props.selectedItem.feedFields);
        }
    }, [props.selectedItem]);

    function submitCustomFeed() {
        const newErrorState = {};
        let hasError = false;
        configFormData.forEach((item) => {
            if (item.tagName === '' && item.checkbox === true) {
                newErrorState[item.name] = true;
                hasError = true;
            } else {
                newErrorState[item.name] = false;
            }
        });

        setError(newErrorState);
        if (hasError) {
            return;
        }


        let sendData = props.selectedItem;
        sendData.feedFields = configFormData;

        let metaData = JSON.parse(sessionStorage.getItem('metaData'));
        let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + `/feeds/createOrUpdate?websiteId=${metaData.websiteId}`;
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, false); // Change to POST method
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;

            xhr.send(JSON.stringify(sendData));

            if (xhr.status === 201) {
                const out = JSON.parse(xhr.responseText);
                showSuccessToast("Feed created successfully with feed id: " + out?.feedId);
                props.handleCloseOverlay();
            } else if (xhr.status === 200) {
                const out = JSON.parse(xhr.responseText);
                showSuccessToast("Feed updated successfully with feed id: " + out?.feedId);
                props.handleCloseOverlay();
            } else {
                console.error('Request addSiteResources failed with status: ' + xhr.status);
                showErrorToast("Some Went Wrong, Please try again later!");
            }
        } catch (error) {
            console.error('Request addSiteResources failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");
        }
    }

    const handleInputChange = (name, value) => {

        const existingItemIndex = configFormData.findIndex((item) => item.name === name);
        let newFormData;
        if (existingItemIndex !== -1) {
            // Update existing item
            if (name === 'pubDate' || name === 'updatedDate') {
                newFormData = [...configFormData];
                newFormData[existingItemIndex] = { ...newFormData[existingItemIndex], 'tagName': value, 'format': configFormData?.find((item) => item["name"] === name)?.format || 'mm-dd-yyyy h:mm:ss a' };
            } else {
                newFormData = [...configFormData];
                newFormData[existingItemIndex] = { ...newFormData[existingItemIndex], 'tagName': value };
            }
        } else {
            // Add new item
            if (name === 'pubDate' || name === 'updatedDate') {
                const item = { 'name': name, 'checkbox': false, 'tagName': value, 'format': configFormData?.find((item) => item["name"] === name)?.format || 'mm-dd-yyyy h:mm:ss a' }
                newFormData = [...configFormData, item];
            } else {
                const item = { 'name': name, 'checkbox': false, 'tagName': value }
                newFormData = [...configFormData, item];
            }
        }
        if (value !== "") {
            //check for the errors and remove it
            setError({ ...error, [name]: false });
        }
        setConfigFormData(newFormData);
    };

    const handleInputFromatChange = (name, value) => {

        const existingItemIndex = configFormData.findIndex((item) => item.name === name);
        let newFormData;
        if (existingItemIndex !== -1) {
            // Update existing item
            newFormData = [...configFormData];
            newFormData[existingItemIndex] = { ...newFormData[existingItemIndex], 'format': value };
        } else {
            // Add new item
            const item = { 'name': name, 'checkbox': false, 'tagName': '', 'format': value }
            newFormData = [...configFormData, item];
        }
        setConfigFormData(newFormData);
    };

    const handleCheckboxChange = (name, value) => {
        const existingItemIndex = configFormData.findIndex((item) => item.name === name);
        let newFormData;
        if (existingItemIndex !== -1) {
            if (name === 'pubDate' || name === 'updatedDate') {
                newFormData = [...configFormData];
                newFormData[existingItemIndex] = { ...newFormData[existingItemIndex], checkbox: (value), format: configFormData?.find((item) => item["name"] === name)?.format || 'mm-dd-yyyy h:mm:ss a' };
            }
            else {
                newFormData = [...configFormData];
                newFormData[existingItemIndex] = { ...newFormData[existingItemIndex], checkbox: (value) };
            }

            if (value && newFormData[existingItemIndex].tagName === '') {
                newFormData[existingItemIndex].tagName = name;
            }
        } else {
            if (name === 'pubDate' || name === 'updatedDate') {
                const item = { 'name': name, 'checkbox': (value), 'tagName': '', 'format': configFormData?.find((item) => item["name"] === name)?.format || 'mm-dd-yyyy h:mm:ss a' }
                if (value && item.tagName === '') {
                    item.tagName = name;
                }
                newFormData = [...configFormData, item];
            }
            else {
                const item = { 'name': name, 'checkbox': (value), 'tagName': '' }
                if (value && item.tagName === '') {
                    item.tagName = name;
                }
                newFormData = [...configFormData, item];
            }
        }
        setConfigFormData(newFormData);
    };

    const onCloseOverlay = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setCloseOverlay(false);
    };

    const closeTheWindow = () => {
        if (props?.selectedItem?.feedFields !== null && JSON.stringify(props?.selectedItem?.feedFields) !== JSON.stringify(configFormData))
            setCloseOverlay(true)
        else
            props.handleCloseOverlay();

    }

    // const sanitizeInput = (value) => {
    //     // Remove invalid characters
    //     const sanitizedValue = value.replace(/[^a-zA-Z0-9._]/g, '');
    //     // Ensure the first character is not a digit or period
    //     if (/^[0-9.]/.test(sanitizedValue)) {
    //         return sanitizedValue.slice(1);
    //     }
    //     return sanitizedValue;
    // };

    const sanitizeInput = (value) => {
        if (!/^[a-zA-Z_]/.test(value) || /XML|Xml|XMl|xml|xML|xmL/.test(value)) {
            return "";
        }
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\._-]/g, '');
        if (/^[0-9.]/.test(sanitizedValue)) {
            return sanitizedValue.slice(1);
        }

        return sanitizedValue;
    };

    return (
        <>
            <div className="RectangleFeed" >
                {(
                    <>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <FormGroup>
                                    <div className='LandingTopWrapperCss'>
                                        <div className='LandingHeading'>
                                            <p className='welcomeText'>Configure Feed</p>
                                        </div>
                                        <Grid container spacing={2}>
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Item</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-7`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            //value={(typeof formData.orIncludeTagIds === 'object' && formData.orIncludeTagIds.length > 0) ? formData.orIncludeTagIds[0] : formData.orIncludeTagIds || ''}
                                                            value={configFormData?.find((item) => item.name === 'item')?.tagName || ''}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('item', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.item && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>

                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Title</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-8`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'title')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('title', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.title && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>



                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "category")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('category', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Category</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-9`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'category')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('category', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.category && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>

                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Description</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-10`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'description')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('description', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.description && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>

                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "body")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('body', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Body</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-11`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'body')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('body', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.body && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "author")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('author', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Author</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-12`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'author')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('author', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.author && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Link</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-13`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'link')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('link', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.link && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Item ID</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-14`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'itemId')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('itemId', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.itemId && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>


                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "pubDate")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('pubDate', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Published Date</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-15`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'pubDate')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('pubDate', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.pubDate && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>

                                                <div className="formSelectWrapper">

                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <Select
                                                            required
                                                            id="filled-required-16"
                                                            className="customInputFiled"
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            value={configFormData?.find((item) => item["name"] === "pubDate")?.format || 'mm-dd-yyyy h:mm:ss a'}
                                                            onChange={(e) => handleInputFromatChange('pubDate', e.target.value)}
                                                            style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                        >
                                                            <MenuItem value={'mm-dd-yyyy h:mm:ss a'}>mm-dd-yyyy h:mm:ss a</MenuItem>
                                                            <MenuItem value={'dd-mm-yyyy hh:mm:ss'}>dd-mm-yyyy hh:mm:ss</MenuItem>
                                                            <MenuItem value={'mm-dd-yyyy'}>mm-dd-yyyy</MenuItem>
                                                            <MenuItem value={'yyyy-mm-dd'}>yyyy-mm-dd</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            {/* 
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "pubDate")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('pubDate', e.target.checked) }} />
                                                </Grid>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Published Date</p>
                                                </Grid>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-15`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'pubDate')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('pubDate', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.pubDate && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </Grid>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <Select
                                                            required
                                                            id="filled-required-16"
                                                            className="customInputFiled"
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            value={configFormData?.find((item) => item["name"] === "pubDate")?.format || 'mm-dd-yyyy h:mm:ss a'}
                                                            onChange={(e) => handleInputFromatChange('pubDate', e.target.value)}
                                                            style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                        >
                                                            <MenuItem value={'mm-dd-yyyy h:mm:ss a'}>mm-dd-yyyy h:mm:ss a</MenuItem>
                                                            <MenuItem value={'dd-mm-yyyy hh:mm:ss'}>dd-mm-yyyy hh:mm:ss</MenuItem>
                                                            <MenuItem value={'mm-dd-yyyy'}>mm-dd-yyyy</MenuItem>
                                                            <MenuItem value={'yyyy-mm-dd'}>yyyy-mm-dd</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid> */}




                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "updatedDate")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('updatedDate', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Updated Date</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-17`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'updatedDate')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('updatedDate', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.updatedDate && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>

                                                <div className="formSelectWrapper">

                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <Select
                                                            required
                                                            id="filled-required-18"
                                                            className="customInputFiled"
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            value={configFormData?.find((item) => item["name"] === "updatedDate")?.format || 'mm-dd-yyyy h:mm:ss a'}
                                                            onChange={(e) => handleInputFromatChange('updatedDate', e.target.value)}
                                                            style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                        >
                                                            <MenuItem value={'mm-dd-yyyy h:mm:ss a'}>mm-dd-yyyy h:mm:ss a</MenuItem>
                                                            <MenuItem value={'dd-mm-yyyy hh:mm:ss'}>dd-mm-yyyy hh:mm:ss</MenuItem>
                                                            <MenuItem value={'mm-dd-yyyy'}>mm-dd-yyyy</MenuItem>
                                                            <MenuItem value={'yyyy-mm-dd'}>yyyy-mm-dd</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>


                                            {/* 
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "updatedDate")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('updatedDate', e.target.checked) }} />
                                                </Grid>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Updated Date</p>
                                                </Grid>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-17`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'updatedDate')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('updatedDate', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.updatedDate && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </Grid>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <Select
                                                            required
                                                            id="filled-required-18"
                                                            className="customInputFiled"
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            value={configFormData?.find((item) => item["name"] === "updatedDate")?.format || 'mm-dd-yyyy h:mm:ss a'}
                                                            onChange={(e) => handleInputFromatChange('updatedDate', e.target.value)}
                                                            style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                        >
                                                            <MenuItem value={'mm-dd-yyyy h:mm:ss a'}>mm-dd-yyyy h:mm:ss a</MenuItem>
                                                            <MenuItem value={'dd-mm-yyyy hh:mm:ss'}>dd-mm-yyyy hh:mm:ss</MenuItem>
                                                            <MenuItem value={'mm-dd-yyyy'}>mm-dd-yyyy</MenuItem>
                                                            <MenuItem value={'yyyy-mm-dd'}>yyyy-mm-dd</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid> */}


                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} disabled checked />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Media</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-19`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'media')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('media', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.media && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>
                                            <div className="mainFormCntWrapper">
                                                <div className="checkBoxWraper">
                                                    <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }}
                                                        checked={configFormData?.find((item) => item["name"] === "source")?.checkbox || false}
                                                        onChange={(e) => { handleCheckboxChange('source', e.target.checked) }} />
                                                </div>
                                                <div className="inputTextItemWrapper">
                                                    <p className="inputTextForConfigFeed">Source</p>
                                                </div>
                                                <div className="formControlWrapperFild">
                                                    <FormControl fullWidth className="formControlWrapper">
                                                        <OutlinedInput
                                                            required
                                                            id={`filled-required-20`}
                                                            variant="filled"
                                                            className="customInputFiled"
                                                            inputProps={{
                                                                style: { background: '#F8FAFC' },
                                                            }}
                                                            value={configFormData?.find((item) => item.name === 'source')?.tagName || ''}
                                                            //error={tagError}
                                                            onChange={(e) => {
                                                                const sanitizedValue = sanitizeInput(e.target.value);
                                                                handleInputChange('source', sanitizedValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {error?.source && <FormHelperText style={{ color: "red" }}>Field cannot be empty</FormHelperText>}
                                                </div>
                                            </div>
                                        </Grid>
                                        <FormControl fullWidth className="formControlWrapper">
                                            <Grid container spacing={2}>
                                                <Grid item sm={12}>
                                                    <Box display="flex" justifyContent="center" gap={2}>
                                                        <Button variant="contained" onClick={submitCustomFeed}>Submit</Button>
                                                        <Button variant="outlined" onClick={closeTheWindow}>Cancel</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </div>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div >
            <Dialog open={closeOverlay} onClose={onCloseOverlay} className='closePopup'>
                <ClosePopup handleCloseOverlay={onCloseOverlay} parentClose={props.handleCloseOverlay}></ClosePopup>
            </Dialog >
            <div className="Clear" style={{ width: 36, height: 36, position: 'absolute', right: 0, cursor: 'pointer' }} onClick={closeTheWindow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 64 64" fill="none">
                    <path d="M44.6263 40.8662C45.0168 41.2568 45.0168 41.8899 44.6263 42.2804L42.2805 44.6262C41.89 45.0168 41.2568 45.0168 40.8663 44.6262L32.0001 35.76L23.1339 44.6262C22.7433 45.0168 22.1102 45.0168 21.7196 44.6262L19.3739 42.2804C18.9833 41.8899 18.9833 41.2568 19.3739 40.8662L28.2401 32L19.3739 23.1338C18.9833 22.7433 18.9833 22.1101 19.3739 21.7196L21.7196 19.3738C22.1102 18.9833 22.7433 18.9833 23.1339 19.3738L32.0001 28.24L40.8663 19.3738C41.2568 18.9833 41.89 18.9833 42.2805 19.3738L44.6263 21.7196C45.0168 22.1101 45.0168 22.7433 44.6263 23.1338L35.7601 32L44.6263 40.8662Z" fill="black" />
                </svg>
            </div>
        </>
    );
}