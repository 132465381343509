import { v4 as uuidv4 } from 'uuid';

// Function to create a new session
export const createSession = () => {
  const sessionId = uuidv4(); // Generate a unique session ID
  // sessionStorage.setItem('sessionId', sessionId); // Store it in local storage
  sessionStorage.setItem('metaData', JSON.stringify(getMetaData()))
  sessionStorage.setItem('hostId', 0)
  return sessionStorage;
};

// Function to get the current session ID
export const getSessionId = () => {
  return sessionStorage.getItem('sessionId');
};

// Function to clear the session
export const clearSession = () => {
  sessionStorage.removeItem('sessionId');
};

export const getMetaData = () => {
  const metaData = {
    themeId: 9,
    clientId: 6,
    websiteId: 16,
    pageId: 0,
    css: "css",
    json: "json",
    name: "name",
    status: 1,
    type: "type",
    createdBy: 12345,
    updatedBy: 12345,
  }
  return metaData;
};

export const updateSession = (objectName, data, type) => {
  if (objectName == "metaData" && type == undefined) {
    //let metaData=JSON.parse(sessionStorage.getItem(objectName));
    data.clientId = data.clientList[0];
    data.websiteId = data.websiteList[0];
    sessionStorage.setItem(objectName, JSON.stringify(data));
  }
  else if (objectName == "metaData" && type == "websiteId") {
    let metaData = JSON.parse(sessionStorage.getItem(objectName));
    metaData.websiteId = data;
    sessionStorage.setItem(objectName, JSON.stringify(metaData));
  }
  else if (objectName == "metaData" && type == "pageId") {
    let metaData = JSON.parse(sessionStorage.getItem(objectName));
    metaData.pageId = data;
    sessionStorage.setItem(objectName, JSON.stringify(metaData));
  }
  else if (objectName == "metaData" && type == "pageType") {
    let metaData = JSON.parse(sessionStorage.getItem(objectName));
    metaData.pageType = data;
    sessionStorage.setItem(objectName, JSON.stringify(metaData));
  } else if (objectName == "metaData" && type == "themeId") {
    let metaData = JSON.parse(sessionStorage.getItem(objectName));
    metaData.themeId = data;
    sessionStorage.setItem(objectName, JSON.stringify(metaData));
  } else if (objectName == "metaData" && type == "pageUpdatedTime") {
    let metaData = JSON.parse(sessionStorage.getItem(objectName));
    metaData.updatedAt = data;
    sessionStorage.setItem(objectName, JSON.stringify(metaData));
  } else
    sessionStorage.setItem(objectName, JSON.stringify(data));

  return sessionStorage.getItem(objectName);
};

export const getSession = (objectName) => {

  return sessionStorage.getItem(objectName);
};
