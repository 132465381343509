import React, { useEffect, useState } from "react";
import "./style.css";
import bookImage from "../../images/book_image.png";
import refreshImage from "../../images/Refresh.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Input, Checkbox } from "@mui/material";
import { PAGE_BUILDER_ENDPOINTS } from "../../core/constants";
import { getThemePagesByWebsite, showErrorToast } from "../../core/Utils";
import classNames from "classnames";

export const AddWebsiteComponent = (props) => {
    const [websiteList, setWebsiteList] = useState([]);
    const [formData, setFormData] = useState({
        createDefaultData: false
    });
    const [page, setPage] = useState(1);
    const [domainError, setDomainError] = useState(false);
    const [websiteNameError, setWebsiteError] = useState(false);
    const [websiteRadioError, setWebsiteRadioError] = useState(false);
    const [radioHelperText, setRadioHelperText] = useState('');
    const [themesArray, setThemesArray] = useState([]);
    const [theme, setTheme] = useState(0);
    const [statusObject, setStatusObject] = useState({
        requestCreated: undefined,
        themeForked: undefined,
        denmarkCreated: undefined,
        cdsCreated: undefined,
        devopsCreated: undefined
    });

    const { siteData } = props;

    useEffect(() => {
        if (siteData.status === false) {
            setPage(4);
            //show the progress page
            if (
                siteData.requestCreated !== undefined &&
                siteData.themeForked !== undefined &&
                siteData.denmarkCreated !== undefined &&
                siteData.cdsCreated !== undefined &&
                siteData.devopsCreated !== undefined
            ) {
                setStatusObject({
                    requestCreated: siteData.requestCreated,
                    themeForked: siteData.themeForked,
                    denmarkCreated: siteData.denmarkCreated,
                    cdsCreated: siteData.cdsCreated,
                    devopsCreated: siteData.devopsCreated
                })
            }
        }
    }, [siteData])


    useEffect(() => {
        if (sessionStorage.getItem('currentWebsiteList') !== undefined
            && sessionStorage.getItem('currentWebsiteList') !== null) {
            const list = sessionStorage.getItem('currentWebsiteList');
            setWebsiteList(JSON.parse(list));
        }
    }, [])

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: (value),
        }));
    };
    const handleInternalInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            internal: {
                ...prevData.internal,
                [name]: value,
            },
        }));
    };

    const handleButtonClick = () => {
        console.log("formData", formData);
        if (formData.websiteDomain === null || formData.websiteDomain === "" || formData.websiteDomain === 'https://') {
            showErrorToast("Please enter website domain");
            return false;
        }
        if (!isValidDomain(formData.websiteDomain)) {
            setDomainError(true);
            return false;
        } else {
            setDomainError(false);
        }
        if (formData.websiteName == null || formData.websiteName == "") {
            showErrorToast("Please enter website name");
            return false;
        }
        if (websiteList.length > 0) {
            if (websiteList.filter((website) => website.websiteName.toLowerCase() === formData.websiteName.toLowerCase()).length > 0) {
                setWebsiteError(true);
                return false;
            }
            else {
                setWebsiteError(false);
            }
        }
        if (formData.primaryLang == null || formData.primaryLang == "") {
            showErrorToast("Please enter primary language");
            return false;
        }


        if (formData.websiteDomain.indexOf('https://') || formData.websiteDomain.indexOf('http://')) {
            //remove https or /
            formData.websiteDomain =
                formData.websiteDomain
                    .replace(/^https?:\/\//, '')
                    .replace(/^http?:\/\//, '')
                    .replace(/\//, '')
        }

        if (page === 1) {
            setPage(2);
            setThemesArray(getThemePagesByWebsite(0, 0));
        }

        //createwebisteRequest(formData);
    }

    const handleSecondClick = () => {
        if (event.target.outerText === 'ENTER NEW DETAILS') {
            //removing the changes
            setWebsiteRadioError(false);
            setRadioHelperText('');
            delete formData.forkedSite

            //moving to next page
            if (page === 2) {
                setPage(3);
            }
        } else if (event.target.outerText === 'IMPORT DETAILS') {
            if (formData.forkedSite === undefined || (formData.forkedSite === undefined && formData.forkedSite.length === 0)) {
                setWebsiteRadioError(true);
                setRadioHelperText('Please choose the website to import details.');
                return false;
            } else {
                setWebsiteRadioError(false);
                setRadioHelperText('');
            }

            //lets create a website request
            createwebisteRequest(formData);
            if (page === 2) {
                setPage(4);
            }
            //props.handleCloseOverlay();
        }
    }

    const createwebisteRequest = (formData) => {
        let metaData = JSON.parse(sessionStorage.getItem('metaData'))
        if (metaData.tokenPayload !== undefined && metaData.tokenPayload !== null) {
            formData.tokenPayload = metaData.tokenPayload;
        }
        if (typeof formData.themeId === 'undefined') {
            if (themesArray.length > 0)
                formData.themeId = themesArray[0].themeId;
        }
        // else {
        //     formData.themeId = 0;
        // }
        let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/createWebsiteRequest";
        const params = `?clientId=${metaData.clientId}&websiteId=${metaData.websiteId}&themeId=${formData.themeId}`;
        url += params;
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, false); // Change to POST method
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;
            const data = formData;

            xhr.send(JSON.stringify(data));

            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                if (
                    response.requestCreated !== undefined &&
                    response.themeForked !== undefined &&
                    response.denmarkCreated !== undefined &&
                    response.cdsCreated !== undefined &&
                    response.devopsCreated !== undefined
                ) {
                    setStatusObject({
                        requestCreated: response.requestCreated,
                        themeForked: response.themeForked,
                        denmarkCreated: response.denmarkCreated,
                        cdsCreated: response.cdsCreated,
                        devopsCreated: response.devopsCreated
                    });
                }
            } else {
                console.error('Request createwebisteRequest failed with status: ' + xhr.status);
                showErrorToast("Some Went Wrong, Please try again later!");
            }
        } catch (error) {
            console.error('Request createwebisteRequest failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");

        }
    }

    const isValidDomain = (domain) => {
        const domainRegex = /[(http)(https)]+:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        let result = domainRegex.test(domain);
        //check if website exist with the domain.
        if (result) {
            result = checkIfWebsiteDomainExists(domain);
        }
        return result;
    };

    const checkIfWebsiteDomainExists = (domain) => {
        domain = domain.replace(/^https?:\/\//, '').replace(/^http?:\/\//, '').replace(/\//, '');
        let metaData = JSON.parse(sessionStorage.getItem('metaData'))
        let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getClientWithDomain";
        const params = `?websiteId=${metaData.websiteId}&domainName=${domain}`;
        url += params;
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;
            xhr.send();

            if (xhr.status === 200) {
                if (JSON.parse(xhr.responseText).length > 0)
                    return false;
                else
                    return true;
            } else {
                console.error('Request checkIfWebsiteDomainExists failed with status: ' + xhr.status);
                showErrorToast("Some Went Wrong, Please try again later!");
            }
        } catch (error) {
            console.error('Request checkIfWebsiteDomainExists failed with status: ' + xhr.status);
            showErrorToast("Some Went Wrong, Please try again later!");

        }
    }

    const getUser = () => {
        const metadata = JSON.parse(sessionStorage.getItem('metaData'));
        if (metadata !== undefined) {
            return metadata.name;
        } else {
            return 'User';
        }
    }

    return (
        <>
            <div className="Rectangle6" >
                {page === 1 && (
                    <>
                        <img className="Literature" src={bookImage} />
                        <div className="userMailId">
                            Hi {getUser()}
                        </div>
                        <div className="WelcomeToPublishstory">
                            Welcome to PublishStory
                        </div>
                        <div className="ToCreateANewWebsitePleaseProvideTheFollowingInformation" >
                            To create a new website, please provide the following information.
                        </div>
                        <>

                            <FormControl fullWidth className="formControlWrapper" style={{ width: '490px' }}>
                                <p className="inputUpperText">Website Domain<sup>*</sup></p>
                                <TextField
                                    required
                                    id="filled-required"
                                    // label="Website Domain"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white', borderRadius: '6px' }
                                    }}
                                    value={formData.websiteDomain || 'https://'}
                                    error={domainError}
                                    onChange={(e) => handleInputChange('websiteDomain', e.target.value)}
                                    helperText={domainError ? 'This domain is invalid or already exists in the publish story' : ''}
                                />
                                <p className="inputUpperText">Website Name<sup>*</sup></p>
                                <TextField
                                    required
                                    id="filled-required"
                                    // label="Website Name"
                                    variant="filled"
                                    className="customInputFiled"
                                    placeholder="Website Name"
                                    InputProps={{
                                        style: { background: 'white', borderRadius: '6px' } // Set background color to white
                                    }}
                                    value={formData.websiteName || ''}
                                    error={websiteNameError}
                                    onChange={(e) => handleInputChange('websiteName', e.target.value)}
                                    helperText={websiteNameError ? 'This website name already exist with this client' : ''}
                                />
                                <p className="inputUpperText">Primary Language</p>
                                <Select
                                    required
                                    id="filled-required"
                                    variant="filled"
                                    className="customInputFiled"
                                    value={formData.primaryLang || ('en' && handleInputChange('primaryLang', 'en'))}
                                    onChange={(e) => handleInputChange('primaryLang', e.target.value)}
                                    style={{ backgroundColor: 'white', textAlign: '-webkit-left' }}
                                >
                                    <MenuItem value={'en'}>ENGLISH</MenuItem>
                                    <MenuItem value={'hi'}>HINDI</MenuItem>
                                    <MenuItem value={'mr'}>MARATHI</MenuItem>
                                    <MenuItem value={'ml'}>MALAYALAM</MenuItem>
                                    <MenuItem value={'te'}>TELUGU</MenuItem>
                                    <MenuItem value={'ta'}>TAMIL</MenuItem>
                                    <MenuItem value={'gu'}>GUJRATI</MenuItem>
                                    <MenuItem value={'bn'}>BENGALI</MenuItem>
                                </Select>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', }}>
                                    <div className="checkBoxWraper">
                                        <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} style={{ color: 'white' }}
                                            checked={formData.createDefaultData}
                                            onChange={(e) => { handleInputChange('createDefaultData', e.target.checked) }} />
                                    </div>
                                    <div className="checkboxTextCss">
                                        Copy dummy content to your new website
                                    </div>
                                </div>
                                <Button variant="contained" onClick={handleButtonClick} className="continueButton">Continue</Button>
                            </FormControl >
                        </>
                    </>)}

                {
                    page === 2 && (
                        <>
                            <div style={{
                                color: 'white',
                                fontSize: 38,
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                wordWrap: 'break-word',
                                textAlign: 'center'
                            }}>
                                CHOOSE AN EXISTING WEBSITE
                            </div>
                            <div className="ToCreateANewWebsitePleaseProvideTheFollowingInformation pageTwo">
                                To import details for your new website.
                            </div>
                            <FormControl fullWidth className="formControlWrapper" error={websiteRadioError} style={{ width: '490px' }}>
                                <RadioGroup className="radioButtonWrapper"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={formData.forkedSite || []}
                                    onChange={(e) => handleInputChange('forkedSite', e.target.value)}
                                    name="radio-buttons-group"
                                    style={{
                                        maxHeight: '200px',
                                        overflowY: 'scroll',
                                        display: 'block'
                                    }}
                                >
                                    {websiteList.map((website) => (
                                        <FormControlLabel
                                            key={website.websiteId}
                                            value={website.websiteId}
                                            control={<Radio />}
                                            label={website.websiteName}
                                            style={{
                                                display: 'table',
                                                width: '100%',
                                                textAlign: 'left'
                                            }}
                                        />
                                    ))}
                                </RadioGroup>
                                <FormHelperText>{radioHelperText}</FormHelperText >
                                <div className="ToCreateANewWebsitePleaseProvideTheFollowingInformation pageTwo">
                                    Select the theme:
                                </div>
                                <Select
                                    value={formData.themeId || themesArray[0]?.themeId}
                                    onChange={(e) => handleInputChange('themeId', e.target.value)}
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Without label'
                                    }}
                                    style={{
                                        width: '-webkit-fill-available',
                                        background: 'white',
                                        marginBottom: '25px'
                                    }}
                                >
                                    {themesArray
                                        .filter((options) => options !== null) // Filter out null or undefined options
                                        .map((options) => (
                                            <MenuItem key={options.themeId} value={options.themeId}>
                                                {options.themeName}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {/* <Button variant="contained" style={{ backgroundColor: 'white', color: '#4097C7' }} onClick={handleSecondClick} className="enterDetails commonPageButtons">ENTER NEW DETAILS</Button> */}
                                <Button variant="contained" onClick={handleSecondClick} className="importDetais commonPageButtons">IMPORT DETAILS</Button>
                                <p className="requiredMsg">* You can change these details anytime after creating your website.</p>
                            </FormControl>
                        </>
                    )
                }
                {
                    page === 3 && (
                        <>
                            <div style={{
                                color: 'white',
                                fontSize: 38,
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                wordWrap: 'break-word',
                                textAlign: 'center'
                            }}>
                                ENTER NEW DETIALS
                            </div>
                            <FormControl fullWidth className="formControlWrapper" style={{ width: '490px' }}>
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Website Name"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.websiteName}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Website Domain"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.websiteDomain}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Website Description"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.websiteDescription || ''}
                                    onChange={(e) => handleInternalInputChange('websiteDescription', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Tagline"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.tagline || ''}
                                    onChange={(e) => handleInternalInputChange('tagline', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Website logo"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.websiteLogo || ''}
                                    onChange={(e) => handleInternalInputChange('websiteLogo', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Favicon"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.favicon || ''}
                                    onChange={(e) => handleInternalInputChange('favicon', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Ads.txt"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.adstst || ''}
                                    onChange={(e) => handleInternalInputChange('adstxt', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Fallback Image"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.fallbackImage || ''}
                                    onChange={(e) => handleInternalInputChange('fallbackImage', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Robots.txt"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.robotstxt || ''}
                                    onChange={(e) => handleInternalInputChange('robotstxt', e.target.value)}
                                />
                                <TextField
                                    required
                                    id="filled-required"
                                    label="GA Code"
                                    variant="filled"
                                    className="customInputFiled"
                                    InputProps={{
                                        style: { background: 'white' },
                                        disabled: true
                                    }}
                                    value={formData.internal && formData.internal.gacode || ''}
                                    onChange={(e) => handleInternalInputChange('gacode', e.target.value)}
                                />

                            </FormControl>
                        </>
                    )
                }
                {
                    page === 4 && (
                        <>
                            <img className="Literature" style={{
                                width: 120,
                                height: 120,
                                margin: '15px 43%'
                            }} src={refreshImage} />
                            <div className="WelcomeToPublishstory" style={{
                                color: 'white',
                                fontSize: 28,
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                lineHeight: '39px',
                                wordWrap: 'break-word',
                                textAlign: 'center'
                            }}>
                                Importing Data Inprogress
                            </div>
                            <div className="mainImportWrapper">
                                <div className="importadWrapper">
                                    {(statusObject.requestCreated === 2 && statusObject.denmarkCreated === 2) ? <FontAwesomeIcon icon={faCheck} /> : <RemoveIcon className="removeIconMui" />}
                                    <div className="ImportedText">
                                        {(statusObject.requestCreated === 2 && statusObject.denmarkCreated === 2) ?
                                            <p>Your account has been successfully created.</p> : <p>Account creation is in progress.</p>
                                        }
                                    </div>
                                </div>
                                <div className="importadWrapper">
                                    {statusObject.themeForked === 2 ? <FontAwesomeIcon icon={faCheck} /> : <RemoveIcon className="removeIconMui" />}
                                    <div className="ImportedText">
                                        {(statusObject.themeForked === 2) ?
                                            <p>Your site's default theme has been configured.</p> : <p>Default theme configuration for your site is underway.</p>
                                        }
                                        <p>Theme is forked for the new website.</p>
                                    </div>
                                </div>
                                <div className="importadWrapper">
                                    {(statusObject.cdsCreated === 2 && statusObject.devopsCreated === 2) ? <FontAwesomeIcon icon={faCheck} /> : <RemoveIcon className="removeIconMui" />}
                                    <div className="ImportedText">
                                        {(statusObject.cdsCreated === 2 && statusObject.devopsCreated === 2) ?
                                            <p>Your site setup is now completed and ready for use.</p> : <p>Website setup is in progress.</p>
                                        }
                                    </div>
                                </div>
                                <p className="requiredMsg">* Website creation is underway. You can close this window. To monitor the configuration progress, simply click on the website name in the dropdown menu.</p>
                            </div>


                        </>
                    )
                }
            </div >
            <div className="Clear" style={{ width: 64, height: 64, position: 'absolute', right: 0, cursor: 'pointer' }} onClick={props.handleCloseOverlay}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M44.6263 40.8662C45.0168 41.2568 45.0168 41.8899 44.6263 42.2804L42.2805 44.6262C41.89 45.0168 41.2568 45.0168 40.8663 44.6262L32.0001 35.76L23.1339 44.6262C22.7433 45.0168 22.1102 45.0168 21.7196 44.6262L19.3739 42.2804C18.9833 41.8899 18.9833 41.2568 19.3739 40.8662L28.2401 32L19.3739 23.1338C18.9833 22.7433 18.9833 22.1101 19.3739 21.7196L21.7196 19.3738C22.1102 18.9833 22.7433 18.9833 23.1339 19.3738L32.0001 28.24L40.8663 19.3738C41.2568 18.9833 41.89 18.9833 42.2805 19.3738L44.6263 21.7196C45.0168 22.1101 45.0168 22.7433 44.6263 23.1338L35.7601 32L44.6263 40.8662Z" fill="white" />
                </svg>
            </div>
        </>
    );
};
