import React, { useEffect, useRef, useState, } from 'react'
import { createSessionifMissing, getMUICache, httpMiddleware, logoutUserSession, updateSessionObject } from '../../core/Utils';
import Grid from '@mui/material/Grid';
import { CacheProvider } from '@emotion/react';
import LandingPage from './LandingPage.css'
import OrgIcon from "../../images/org-icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { PAGE_BUILDER_ENDPOINTS, CONSTANTS, CONST_PERMISSIONS } from "../../core/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FeedExportComponent } from '../FeedExport';
import { UserSessionComponentOtherPages } from '../../core/Service/PageUserSessionService';



const FeedExportPage = (props) => {
    const cache = getMUICache();
    const [show, setShow] = useState(false);
    const [dropShow, setDropShow] = useState(false);
    const [webListShow, setWebListShow] = useState(false);
    const [activeState, desabileState] = useState(false);
    const [activeStateLog, desabileStateLog] = useState(false);
    const [activeWebList, desabileWebList] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const refOne = useRef(null);
    const refTwo = useRef(null);
    const refThree = useRef(null);
    const [websitOptions, setOptions] = useState([]);
    const [websiteValue, setWebsiteValue] = useState({});
    const link = document.querySelector("link[rel~='icon']");
    const dymincUrl = "https://pagebuilder.publishstory.co";
    const [listJson, setListJson] = useState([{
        title: "Authoring",
        desc: "Craft Compelling Content, Welcome to your Authoring Module of Publish Story, the heart of content creation within our CMS! Here, you have everything you need to transform your ideas into engaging content, easily and efficiently.",
        imgPath: dymincUrl + "/stresources/custom-icons/uthoring_org.svg",
        imgPathHover: dymincUrl + "/stresources/custom-icons/Authoring_selected.svg",
        path: "https://app.publishstory.co/authoring",
    },
    {
        title: "Admin Management",
        desc: "Welcome to your Admin Module, the nerve center of Publish Story. Here, you have complete control over managing content, users, settings, and ensuring a smooth operation for your website.",
        imgPath: dymincUrl + "/stresources/custom-icons/dmin_org.svg",
        imgPathHover: dymincUrl + "/stresources/custom-icons/admin_org.svg",
        path: "https://app.publishstory.co/admin",
    },

    {
        title: "Analytics",
        desc: "Unveiling the Power of Data on Publish Story. Here's where content meets insights. We provide the tools to analyse your content's performance and make data-driven decisions that optimise your website's success.",
        imgPath: dymincUrl + "/stresources/custom-icons/nalytics_org.svg",
        imgPathHover: dymincUrl + "/stresources/custom-icons/Analytics_selected.svg",
        path: "https://app.publishstory.co/analytics/",
    },


    {
        title: "Reporting",
        desc: "Publish your story, then delve into the data to measure its impact. Publish Story Reporting Module provides valuable insights to understand how your content performs, empowering you to refine your strategy and achieve success.",
        imgPath: dymincUrl + "/stresources/custom-icons/eporting_org.svg",
        imgPathHover: dymincUrl + "/stresources/custom-icons/Analytics_selected.svg",
        path: "https://app.publishstory.co/reporting/cms-analytics/",
    },


    {
        title: "Page Builder",
        desc: "Welcome to the Page Builder Module, your one-stop shop for crafting stunning and engaging website pages! This intuitive drag-and-drop interface empowers you to bring your content vision to life, with no coding required.",
        imgPath: dymincUrl + "/stresources/custom-icons/age_builder_org.svg",
        imgPathHover: dymincUrl + "/stresources/custom-icons/Page_Builder_selected.svg",
        path: "https://app.publishstory.co/pagebuilder",

    }]);

    function handleLogout() {
        desabileStateLog(activeStateLog => !activeStateLog);
    }

    const disabledStyle = {
        pointerEvents: 'none',
        opacity: 0.5,
    };

    useEffect(() => {
        createSessionifMissing().then(res => {
            if (res && res && res?.tokenPayload?.userEmail) {
                let userEmail = res.tokenPayload.userEmail;
                setUserEmail(userEmail);

                if (
                    //res.permissions && res.websiteId && res.permissions.find((op) => op.websiteId === res.websiteId) !== undefined
                    //&& res.permissions.find((op) => op.websiteId === res.websiteId).permission.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION)
                    res.tenantIdPermissions && res.tenantIdPermissions.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION)) {
                    setListJson([...listJson,
                    {
                        title: "New Channel Creation",
                        desc: "Your stories deserve to be heard! Create New Channel within Publish Story, empowering you to publish your content on various platforms and reach a wider audience.",
                        imgPath: dymincUrl + "/stresources/custom-icons/hannel_creation_org.svg",
                        imgPathHover: dymincUrl + "/stresources/custom-icons/Channel_Configration_selected.svg",
                        path: "https://app.publishstory.co/pagebuilder#createSite",
                    },
                    {
                        title: "Custom Feed",
                        desc: "Empower your content to reach new audiences. Create and export custom news feed tailored for specific partners and aggregators, all while maintaining control over your valuable content.",
                        imgPath: dymincUrl + "/stresources/custom-icons/Publish%20Story%20Custom%20feed%20favicon.svg",
                        imgPathHover: dymincUrl + "/stresources/custom-icons/Channel_Configration_selected.svg",
                        path: "#",
                    }])
                } else {
                    setListJson([...listJson,
                    {
                        title: "New Channel Creation",
                        desc: "Your stories deserve to be heard! Create New Channel within Publish Story, empowering you to publish your content on various platforms and reach a wider audience.",
                        imgPath: dymincUrl + "/stresources/custom-icons/hannel_creation_org.svg",
                        imgPathHover: dymincUrl + "/stresources/custom-icons/Channel_Configration_selected.svg",
                        path: "https://app.publishstory.co/pagebuilder#createSite",
                        onclick: true
                    },
                    {
                        title: "Custom Feed",
                        desc: "Empower your content to reach new audiences. Create and export custom news feed tailored for specific partners and aggregators, all while maintaining control over your valuable content.",
                        imgPath: dymincUrl + "/stresources/custom-icons/Publish%20Story%20Custom%20feed%20favicon.svg",
                        imgPathHover: dymincUrl + "/stresources/custom-icons/Channel_Configration_selected.svg",
                        path: "#",
                        onclick: true
                    }])
                }

                document.addEventListener("click", handleClickOutside, true);

                // document.addEventListener("click", handleClickLogButton, true);
                document.addEventListener("click", handleClickWebList, true);

                // Make an API request to fetch your data
                const config = {
                    method: 'GET',
                    url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getAllWebsitesByClient",
                };

                httpMiddleware(config)
                    .then((response) => {
                        if (!response.status) {
                            throw new Error('Network response was not ok');
                        }

                        return response.data;
                    })
                    .then((data) => {
                        if (data && data.length > 0) {
                            setOptions(data.filter((item) => item.status === true));
                            setWebsiteValue(data.filter((item) => item.status === true)[0]);
                            updateSessionObject("hostId", data.filter((item) => item.status === true)[0]?.denmarkObject?.hostId);
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }


        });
        // add newtitle
        document.title = 'PublishStory Custom Feeds';


        if (link !== null) {
            link.href = 'https://pagebuilder.publishstory.co/stresources/custom-icons/PS%20favicon.ico'; // Replace '/favicon-page1.ico' with your favicon path for Page 1
        };

    }, []);

    const handleClickOutside = (e) => {
        if (refOne && refOne.current && refOne.current.contains(e.target)) {
            // console.log("clicked in side one")
        } else {
            // console.log("clicked out side one")
            setShow(0);
        }

    }

    const handleClickWebList = (e) => {

        if (refThree && refTwo.current && refTwo.current.contains(e.target)) {
            // console.log("clicked in side three")
        } else {
            // console.log("clicked out side two")
            setWebListShow(0);
        }

    }


    function getInitials(input) {
        const words = input.split(" ");
        const initials = words.map(word => word.charAt(0));
        if (initials.length === 1) {
            return initials[0].toUpperCase();
        }
        return (initials[0] + initials[1]).toUpperCase();
    }

    let company_name = "Company Name";
    let company_email = "company.name@gmail.com";
    let company_name_initials = "CN";
    if (sessionStorage.getItem("metaData") && JSON.parse(sessionStorage.getItem('metaData')).name) {
        company_name = JSON.parse(sessionStorage.getItem('metaData')).name;
        company_name_initials = getInitials(company_name);
        if (JSON.parse(sessionStorage.getItem("metaData")).tokenPayload && JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail) {
            company_email = JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail;
        }
    }

    function websiteOnChange(event) {
        if (event.target) {
            updateSessionObject("metaData", event.target.value.websiteId, "websiteId");
            updateSessionObject("hostId", event.target.value.denmarkObject.hostId);
            setWebsiteValue(event.target.value);
        }
    }

    function getlogoImageUrl(data) {
        console.log("CONSTANTS : ", CONSTANTS);
        if (data.websiteDomain && data.websiteResouces && data.websiteResouces.schemaLogo && CONSTANTS && CONSTANTS.API_URL && CONSTANTS.API_URL.VSP_IMAGES) {
            if (CONSTANTS.API_URL.VSP_IMAGES == "") {
                return 'https://' + data.websiteDomain + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            } else {
                return CONSTANTS.API_URL.VSP_IMAGES + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            }
        } else {
            return OrgIcon
        }
    }

    const footerLinksJson = [
        {
            links: [
                {
                    title: "All rights reserved",
                    // path: "/dashboard/copyright"
                },
                {
                    title: "Privacy Policy",
                    path: "/dashboard/privacy-policy"
                },
                {
                    title: "Terms & Conditions and Grievance Redressal Policy",
                    path: "/dashboard/terms-conditions"
                },
                {
                    title: "About us",
                    // path: "/dashboard/about"
                }

            ]
        },

    ]

    if (!(userEmail)) {
        return <></>
    }

    return (userEmail !== '' ? (
        <>
            <UserSessionComponentOtherPages />
            <div className='MainBodyWrapper' styles={LandingPage}>
                <div className='LandingPageHeader'>
                    <div className='LandingPageHeaderWrapper'>
                        <div className='LandingPageLogo'>
                            <figure><img src={dymincUrl + "/stresources/custom-icons/Publish Story Custom feed.svg"} /></figure>
                        </div>
                        <div className='RightSideMenuWrap'>
                            <div className='MenuSelectItems'>
                                <p className='inNewsroomTiitle'>Newsroom</p>
                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                    <Select labelId="SelectPublisher"
                                        id="SelectPublisherHelper"
                                        label="publishers"
                                        value={websiteValue}
                                        onChange={websiteOnChange}>
                                        {websitOptions.map((data, i) => {
                                            return <MenuItem key={'jj_' + i} className='slectItemsCommon' value={data}>
                                                <img src={getlogoImageUrl(data)} />
                                                {data.websiteName}</MenuItem>

                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={`UserNameWrap ${activeStateLog ? "activeStateLog" : ''}`} onClick={handleLogout}>
                                <button className='logButtonDrop' onClick={() => setDropShow(!dropShow)} ref={refTwo}>{company_name_initials}</button>
                                {dropShow ? <div className='logOutWrap'>
                                    <div className='userDetailsWrap'>
                                        <div className='userNameDetails'>{company_name}</div>
                                        <div className='userEmailDetails'>{company_email}</div>
                                    </div>
                                    <Tooltip title="Logout">
                                        <button onClick={logoutUserSession}><FontAwesomeIcon icon={faPowerOff} /> Logout</button>
                                    </Tooltip>
                                </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <FeedExportComponent websiteValue={websiteValue} />
                <div className='landingPageFooter'>
                    <CacheProvider value={cache}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <div className='landingFooterText'>
                                    <p>publishstory.co , Copyright © 2024 Times Internet Ltd.</p>
                                </div>

                                <div className='landingFooterLinks'>
                                    <ul>
                                        {footerLinksJson.map((data, i) => {
                                            return <li key={i}>
                                                {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                                    return <a target='_blank' href={item.path} key={l}><span>{item.title}</span></a>
                                                })}
                                            </li>

                                        })}
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </CacheProvider>
                </div>
            </div>
        </>
    ) : (<></>));
}

export default FeedExportPage;