import { getComponent, getComponentsFromStorage, getCssFromStorage } from "../core/Utils";
// import "./../../index.css";
import React from 'react'

function LayoutRender() {

    let json = getComponentsFromStorage();
    let css = getCssFromStorage();
    let out = getComponent(json);
    // console.log("jss", json);
    // console.log("outout", out);
    return (
        <div>
            <style>{css}</style>
            {out}
        </div>
    )

}

export default LayoutRender;