import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { redirect, route } from 'react-router-dom';

const HealthCheck = () => {
    // const [ipAddress, setIpAddress] = useState(null);
    // useEffect(() => {
    //     getIpAdress();
    // }, []);

    // const getIpAdress = () => {
    //     // Make a request to the ipify service to get the IP address
    //     fetch('https://api.ipify.org?format=json')
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setIpAddress(data.ip);
    //             console.log("IP address : " + data.ip);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching IP address:', error);
    //         });
    // }

    // const json = {
    //     "health": "ok"
    // }
    return <div>ok</div>;
};

export default HealthCheck;