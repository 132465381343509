import React, { Component } from 'react'
import LayoutRender from '../../components/LayoutRender';

class NewsRoom extends Component {

  render() {

    return (
      <div>
        <LayoutRender></LayoutRender>
      </div>
    )

  }
}

export default NewsRoom;