import React, { useEffect, useState } from 'react';
import createComponentJson from '../core/component-list';

const Versioning = () => {
  const [json, setJson] = useState({});

  useEffect(() => {
    createComponentJson().then( (componentJson) => {
      setJson(componentJson);
    } );
    
  }, []);

  return (
    <pre>
      {JSON.stringify(json, null, 2)}
    </pre>
  );
};

export default Versioning;
