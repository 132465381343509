import React from 'react'
import {getMUICache, loadStyles } from '../../core/Utils';
import Grid from '@mui/material/Grid';
import { CacheProvider } from '@emotion/react';
import PageNotFoundStyles from './Error.css'


const PageNotFound = (props) => {
    const cache = getMUICache();

    return(
        <div className='commoncontainer' styles={PageNotFound}>
          <CacheProvider value={cache}>
          <Grid container p={2} spacing={2}>
            <Grid item md={12} xs={12}>
                <div className='errorwrapper'>
                    <div className='erroeheading'>
                        <h3>404</h3>
                    </div>
                    <div className='errorcontent'>
                        <p>Sorry, the content you’re looking for doesn’t exist. Either it was removed, or you mistyped the link.</p>
                        <p></p>
                    </div>
                </div>
                </Grid>
                </Grid>
          </CacheProvider>
        </div>

    )

}

export default PageNotFound;