import { getSessionObject, updateSessionObject, getFormattedCss, appendScriptToHeadOrBody, checkFrValidUrlsInPageTemplate } from "../Utils";
import React, { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { showSuccessToast, showErrorToast, httpMiddleware } from "../Utils";
import { CONSTANTS, CONST_PERMISSIONS, PAGE_BUILDER_ENDPOINTS } from "../constants";
import factoComponents from "../facto-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AddWebsiteComponent } from "../../components/AddWebsiteComponent/AddWebsiteComponent";

var saveDone = 0;

export const savePageTemplate = (pageData) => {

  //let sessionObject=getSession();
  let resPageData = null;
  let url = null;
  let msg = null;
  let metaData = JSON.parse(getSessionObject('metaData'));
  let clientId = metaData.clientId;
  let websiteId = metaData.websiteId;
  let comps = localStorage.getItem('comps');
  let pageCss = localStorage.getItem('css');

  if (!checkFrValidUrlsInPageTemplate(comps)) {
    return;
  }

  if (metaData.pageId > 0) {
    //console.log("metaData:"+selectedPage.pageId);

    const pageList = new Array();
    pageData = {
      ...pageData,
      json: comps,
      css: pageCss,
      pageId: metaData.pageId
    }

    let env = 1
    if (websiteId == 0) {
      env = 0
    }

    url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updatePageTemplate?env=" + env;
    msg = "Changes saved locally. Don't forget to publish to make them live !";

  }

  else if (metaData.pageId == 0) {
    url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/addPageTemplate?env=" + env;
    msg = "Changes saved locally. Don't forget to publish to make them live !";
  }

  return new Promise((resolve, reject) => {

    const config = {
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(pageData),
    };

    console.log("waiting for 1111 ", config);
    saveDone = 1;
    httpMiddleware(config)
      .then((response) => {
        saveDone = 2;
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error('Request failed with status: ' + response.status);
        }
      })
      .then((resPageData) => {
        // Handle the API response here
        updateSessionObject("metaData", resPageData.updatedAt, "pageUpdatedTime");
        showSuccessToast(msg);
        resolve(resPageData);
      })
      .catch((error) => {
        // Handle API call errors here
        showErrorToast('Something went wrong, please try again later!!');
        console.error('API Error:', error);
        reject(error);
      });
  });
}

export const saveandPublishPageTemplate = (pageData) => {
  //let sessionObject=getSession();
  let url = null;
  let msg = null;
  let metaData = JSON.parse(getSessionObject("metaData"));
  let comps = localStorage.getItem("comps");
  let pageCss = localStorage.getItem("css");

  if (!checkFrValidUrlsInPageTemplate(comps)) {
    return;
  }

  if (metaData.pageId > 0) {
    //console.log("metaData:"+selectedPage.pageId);

    const pageList = new Array();
    pageData = {
      ...pageData,
      json: comps,
      css: pageCss,
      pageId: metaData.pageId,
    };

    let env = 2;
    if (metaData.websiteId == 0) {
      env = 0
    }

    url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updatePageTemplate?env=" + env;
    msg = "Congratulations! Your updates are now live.";
  }

  const config = {
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(pageData),
  };

    let myInterval = setInterval(() => {
      console.log("save state is in 1");
      if( saveDone != 1 ) {
        clearInterval(myInterval);

        console.log("waiting for ", config);
        setTimeout( function () {
          console.log("waiting done for ", config);
          httpMiddleware(config)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw new Error('Request failed with status: ' + response.status);
            }
          })
          .then((data) => {
            updateSessionObject("metaData", data.updatedAt, "pageUpdatedTime");
            // Handle the API response here
            showSuccessToast(msg);
          })
          .catch((error) => {
            // Handle API call errors here
            showErrorToast("Something went wrong, please try again later!!");
            console.error("API Error:", error);
          });
        } , 500);
      }
    }, 300);

};

const validateTheJson = (comps) => {
  let urlValue = findValueByKeyInJson(JSON.parse(comps), 'mainHeadingHref');
  for (let index = 0; index < urlValue.length; index++) {
    const element = urlValue[index];
    const urlValues = element.split(" | ");
    const url = urlValues[0];
    const title = urlValues[1];
    const component = urlValues[2];
    if (!isValidUrl(url)) {
      if (title && title != "") {
        showErrorToast("Title URL is not correct! Please check component having heading name : " + title);
      } else {
        showErrorToast("Title URL is not correct! Please check " + component + " component");
      }
      return 'stopExecution';
    }
  }
  let urlValueNew = findValueByKeyInJson(JSON.parse(comps), 'viewAllUrl');
  for (let index = 0; index < urlValueNew.length; index++) {
    const element = urlValueNew[index];
    const urlValues = element.split(" | ");
    const url = urlValues[0];
    const title = urlValues[1];
    const component = urlValues[2];
    if (!isValidUrl(url)) {
      if (title && title != "") {
        showErrorToast("View All Url is not correct! Please check component having heading name : " + title);
      } else {
        showErrorToast("View All Url is not correct! Please check " + component + " component");
      }
      return 'stopExecution';
    }
  }
  let headAdScript = findValueByKeyInJson(JSON.parse(comps), 'headAdScript');
  let bodyAdScript = findValueByKeyInJson(JSON.parse(comps), 'bodyAdScript');
  const combinedArray = headAdScript.concat(bodyAdScript);
  for (let index = 0; index < combinedArray.length; index++) {
    const element = combinedArray[index];
    const urlValues = element.split(" | ");
    const url = urlValues[0];
    const title = urlValues[1];
    const component = urlValues[2];
    if (url === '') {
      showErrorToast("Head script or body script cannot be blank in adslot for component : " + component + " with title: " + title);
      return 'stopExecution';
    }
  }
  return 'continueExecution';
};

export const getPageTemplateById = async (pageMetaData) => {

  let themeId = pageMetaData.themeId;
  let pageId = pageMetaData.pageId;
  let websiteId = pageMetaData.websiteId;
  let env = 1;
  if (websiteId === 0) {
    env = 0
  }

  let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getPageTemplateById?themeId=" + themeId + "&pageTemplateId=" + pageId + "&websiteId=" + websiteId + "&env=" + env;

  const config = {
    url: url,
    method: 'GET',
  };

  try {
    const response = await httpMiddleware(config);

    if (!response.status) {
      throw new Error('Network response was not ok');
    }
    else {
      updateSessionObject("metaData", response.data.updatedAt, "pageUpdatedTime");
    }
    return response.data;
  } catch (error) {
    showErrorToast("Something went wrong, please try again later!!");
    console.error('Error fetching data:', error);
    throw error; // You can choose to re-throw the error or handle it as needed.
  }


}

export const GetAllWebsites = (siteName) => {
  console.log('type', siteName);
  //let sessionObject=getSessionObject();
  let metaData = JSON.parse(getSessionObject("metaData"));

  const [options, setOptions] = useState([]);
  const [pageoptions, setPageOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [pageSelectedValue, setPageSelectedValue] = useState("");
  const [openOverlay, setOpenOverlay] = useState(false);
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    // Make an API request to fetch your data
    const config = {
      method: 'GET',
      url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getAllWebsitesByClient",
    };

    httpMiddleware(config)
      .then((response) => {
        if (!response.status) {
          throw new Error('Network response was not ok');
        }

        return response.data;
      })
      .then((data) => {
        setOptions(data);
        const activeWebsites = data.filter(website => website.status === true);
        sessionStorage.setItem('currentWebsiteList', JSON.stringify(activeWebsites));
        handleSelectChange(data[0], "firstLoad"); // Update the options state with the data from the API
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [siteName.pageTemplateData]); // Include clientId as a dependency



  const getThemePagesByWebsite = (websiteId, clientId) => {
    //fetch(PAGE_BUILDER_ENDPOINTS.DOMAIN+"pageBuilder/getThemeByClientAndWebsite?clientId=4&websiteId=4")
    const config = {
      method: 'GET',
      url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getThemeByClientAndWebsite?clientId=" + clientId + "&websiteId=" + websiteId + "&env=1",
    };

    httpMiddleware(config)
      .then((response) => {
        if (!response.status) {
          throw new Error('Network response was not ok');
        }
        return response.data;
      })
      .then((data) => {
        if (data != null) {
          if (data[0].json)
            updateSessionObject('globalJson', JSON.parse(data[0].json));
          else
            updateSessionObject('globalJson', "");
          if (data[0].css)
            updateSessionObject('globalCss', JSON.parse(data[0].css));
          else
            updateSessionObject('globalCss', "");
          const filteredOptions = data
            .filter((item) => item.env === 1) // Filter the items based on condition
            .flatMap((item) => item.pageTemplateList); // Extract the desired data
          //console.log(filteredOptions)
          setPageOptions(filteredOptions);
          handlePageSelectChange(filteredOptions[0], "firstLoad");
          // setPageSelectedValue(filteredOptions[0]);
          // updateSessionObject(filteredOptions[0]);
          // window.gpEditor.setComponents(JSON.parse(filteredOptions[0].json));
          // window.gpEditor.setStyle(filteredOptions[0].css);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getThemePagesByThemeId = (theme) => {

    const config = {
      method: 'GET',
      url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getThemeById?themeId=" + theme.themeId + "&env=" + theme.env,
    };

    httpMiddleware(config)
      .then((response) => {
        if (!response.status) {
          throw new Error('Network response was not ok');
        }
        return response.data;
      })
      .then((data) => {
        if (data != null) {
          if (data[0]?.json)
            updateSessionObject('globalJson', JSON.parse(data[0].json));
          else
            updateSessionObject('globalJson', "");
          if (data[0]?.css)
            updateSessionObject('globalCss', JSON.parse(data[0].css));
          else
            updateSessionObject('globalCss', "");
          const filteredOptions = data
            //.filter((item) => item.env === 1) // Filter the items based on condition
            .flatMap((item) => item.pageTemplateList); // Extract the desired data
          //console.log(filteredOptions)
          setPageOptions(filteredOptions);
          handlePageSelectChange(filteredOptions[0], "firstLoad");
          // setPageSelectedValue(filteredOptions[0]);
          // updateSessionObject(filteredOptions[0]);
          // window.gpEditor.setComponents(JSON.parse(filteredOptions[0].json));
          // window.gpEditor.setStyle(filteredOptions[0].css);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSelectChange = (event, load) => {
    if (load != "firstLoad" && event.target.name === undefined && event.target.value === undefined) return;

    if (load != "firstLoad" && event.target.value !== undefined && event.target.value.status === false) {
      window.location.hash = 'createSite';
      setSiteData(event.target.value);
      setOpenOverlay(true);
      return;
    } else {
      setSiteData({});
    }

    let globalJson = JSON.parse(getSessionObject('globalJson'));
    const saveGlobalSettingsButton = window.gpEditor.Panels.getButton("views", "open-tm_1");

    if (saveGlobalSettingsButton) {
      window.gpEditor.Panels.getButton("views", "open-tm_1").set("active", true);
    }
    window.gpEditor.runCommand("core:component-delete");

    if (load != "firstLoad") {
      //if(CONSTANTS.API_URL.VSP_IMAGES !== "https://vsp1images.indiatimes.com") CONSTANTS.API_URL.VSP_IMAGES = 'https://'+event.target.value.websiteDomain;
      setSelectedValue(event.target.value);

      if (event.target.value.themeId !== undefined) {
        localStorage.setItem('defaultThemeId', event.target.value.themeId);
        getThemePagesByThemeId(event.target.value)
        updateSessionObject("metaData", 0, "websiteId");
      } else {
        getThemePagesByWebsite(
          event.target.value.websiteId,
          event.target.value.clientId
        );
        updateSessionObject("metaData", event.target.value.websiteId, "websiteId");
        updateSessionObject("hostId", event.target.value.denmarkObject.hostId);
        if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
          appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
          appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
        }
      }
      factoComponents(window.gpEditor);
    } else {
      //if(CONSTANTS.API_URL.VSP_IMAGES !== "https://vsp1images.indiatimes.com") CONSTANTS.API_URL.VSP_IMAGES = 'https://'+event.websiteDomain;
      setSelectedValue(event);
      getThemePagesByWebsite(event.websiteId, event.clientId);
      updateSessionObject("metaData", event.websiteId, "websiteId");
      updateSessionObject("hostId", event.denmarkObject.hostId);
      if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
        appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
        appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
      }
    }
  };

  const resetCanvas = (editor) => {
    editor.DomComponents.clear();
    editor.StyleManager.clear();
    editor.CssComposer.clear();
    editor.UndoManager.clear();
    editor.UndoManager.removeAll();
    //console.clear();
  }

  const handlePageSelectChange = async (event, load) => {
    let globalJson = JSON.parse(getSessionObject('globalJson'));
    window.gpEditor.runCommand("core:component-delete");
    resetCanvas(window.gpEditor)
    factoComponents(window.gpEditor, load != "firstLoad" ? event.target.value : null);
    //console.log(event.target.value);
    if (load != "firstLoad") {
      let selectedPage = event.target.value;
      if (selectedPage == undefined) {
        window.gpEditor.setComponents();
        window.gpEditor.setStyle();
        localStorage.setItem("comps", null);
        localStorage.setItem("css", null);
      }
      else {
        let pageData = await getPageTemplateById(selectedPage);
        setPageSelectedValue(event.target.value);
        updateSessionObject("metaData", pageData.pageId, "pageId");
        updateSessionObject("metaData", pageData.type, "pageType");
        updateSessionObject("metaData", pageData.themeId, "themeId");
        window.gpEditor.setComponents(JSON.parse(pageData.json));
        window.gpEditor.setStyle(getFormattedCss(pageData.css));
        localStorage.setItem("comps", pageData.json);
        localStorage.setItem("css", pageData.css);
        if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
          appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
          appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
        }
        //metaData.themeId=event.target.value.themeId;
        //metaData.pageId=event.target.value.pageId;
        // updateSessionObject("metaData", selectedPage);
      }
    } else {
      let selectedPage = event;
      if (selectedPage == undefined) {
        window.gpEditor.setComponents();
        window.gpEditor.setStyle();
        localStorage.setItem("comps", null);
        localStorage.setItem("css", null);
      }
      else {
        setPageSelectedValue(event);
        updateSessionObject("metaData", event.pageId, "pageId");
        updateSessionObject("metaData", event.type, "pageType");
        updateSessionObject("metaData", event.themeId, "themeId");
        updateSessionObject("metaData", event.updatedAt, "pageUpdatedTime");
        if (window.gpEditor) {
          window.gpEditor.setComponents(JSON.parse(selectedPage.json));
          window.gpEditor.setStyle(getFormattedCss(selectedPage.css));
        }
        localStorage.setItem("comps", selectedPage.json);
        localStorage.setItem("css", selectedPage.css);
        if (false && globalJson && (globalJson.globalHeaderScript || globalJson.globalBodyScript)) {
          appendScriptToHeadOrBody("header_script", globalJson.globalHeaderScript);
          appendScriptToHeadOrBody("body_script", globalJson.globalBodyScript);
        }
        //metaData.themeId=event.target.value.themeId;
        //metaData.pageId=event.target.value.pageId;
        //updateSessionObject("metaData", selectedPage);
      }
    }
  };

  const handleAddWebsite = () => {
    window.location.hash = 'createSite';
    setOpenOverlay(true);
    //event.stopPropagation();
  };

  const handleCloseOverlay = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenOverlay(false);
    if (window.location.hash === '#createSite') {
      const newUrl = window.location.href.replace('#createSite', '');
      history.replaceState(null, '', newUrl);
    }
    siteName.setPageTemplateData();
  };

  useEffect(() => {
    if (window.location.hash.includes("#createSite")) {
      handleAddWebsite();
    }
  }, []);

  return (
    <>
      <Box>
        <FormControl size="small" variant="standard" sx={{ marginRight: 3 }}>
          <InputLabel id="demo-simple-select-label">Select Website</InputLabel>
          <Select
            value={selectedValue}
            onChange={handleSelectChange}
            label="Select"
          >
            {options.map((option) => (
              option.status ? (
                <MenuItem key={option.websiteId === undefined ? option.themeId : option.websiteId} value={option}>
                  {option.websiteName === undefined ? option.themeName : option.websiteName}
                </MenuItem>
              ) : (
                <MenuItem key={option.websiteId} value={option}>
                  {option.websiteName} (In-active)
                </MenuItem>
              )
            ))}
            {metaData &&
              //metaData.permissions &&
              //metaData.permissions.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
              //metaData.permissions.find((op) => op.websiteId === metaData.websiteId) !== undefined &&
              //metaData.permissions.find((op) => op.websiteId === metaData.websiteId).permission.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
              metaData.tenantIdPermissions && metaData.tenantIdPermissions.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
              <MenuItem onClick={handleAddWebsite}>
                Add New Site
              </MenuItem>
            }
          </Select>
        </FormControl>
        {siteName.type !== 'dashboard' &&
          <FormControl size="small" variant="standard">
            <InputLabel id="demo-simple-select-label1">Select PageTemplate</InputLabel>
            <Select
              value={pageSelectedValue}
              onChange={handlePageSelectChange}
              label="Select"
            >
              {pageoptions
                .filter((options) => options !== null) // Filter out null or undefined options
                .map((options) => (
                  <MenuItem key={options.pageId} value={options}>
                    {options.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        }
      </Box>
      {
        metaData &&
        //metaData.permissions &&
        //metaData.permissions.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
        //metaData.permissions.find((op) => op.websiteId === metaData.websiteId) !== undefined &&
        //metaData.permissions.find((op) => op.websiteId === metaData.websiteId).permission.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
        metaData.tenantIdPermissions && metaData.tenantIdPermissions.includes(CONST_PERMISSIONS.WEBSITE_CHANNEL_CREATION) &&
        <Dialog open={openOverlay} onClose={handleCloseOverlay}>
          <AddWebsiteComponent handleCloseOverlay={handleCloseOverlay} siteData={siteData}></AddWebsiteComponent>
        </Dialog >
      }
    </>
  );
}
