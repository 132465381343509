
import { Accordion, AccordionSummary, Button, Dialog, Grid, AccordionDetails, Typography, Switch, IconButton, Divider, Stack, CircularProgress, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from "react";
import { AddNewFeedExport } from "./AddNewFeedExport";
import { Filtering_Type, PAGE_BUILDER_ENDPOINTS } from "../../core/constants";
import { httpMiddleware, showSuccessToast } from "../../core/Utils";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import './index.css'
import { AddConfiguraiton } from './AddConfiguraiton';




export const FeedExportComponent = (props) => {
    const [customFeeds, setCustomFeeds] = useState([]);
    const [openOverlay, setOpenOverlay] = useState(false);
    const [openOverlay1, setOpenOverlay1] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showBlankPage, setShowBlankPage] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCloseOverlay = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenOverlay(false);
    };

    const handleCloseOverlay1 = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenOverlay1(false);
    };

    const createNewFeed = () => {
        setOpenOverlay(true);
        setSelectedItem(null);
    }

    useEffect(() => {
        if (props.websiteValue.websiteId != undefined) {
            getAllFeedsOnPage();
        }
    }, [props.websiteValue]);

    const getAllFeedsOnPage = () => {
        setShowLoader(true);
        const config = {
            url: PAGE_BUILDER_ENDPOINTS.FEED_MANAGEMENT_GETALLFEEDS + `?websiteId=${props.websiteValue.websiteId}`,
            method: 'GET',
        };

        httpMiddleware(config)
            .then((response) => {
                if (response && response.status === 200) {
                    if (response.data.length > 0) {
                        setCustomFeeds(response.data);
                        setShowBlankPage(false);
                        setShowLoader(false);
                    } else {
                        setCustomFeeds([]);
                        setShowBlankPage(true);
                        setShowLoader(false);
                    }
                } else {
                    console.error('Custom feeds failed to fetch with status: ' + response.status);
                    setShowBlankPage(true);
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                console.error("Custom feeds failed to fetch with status:", error);
                setShowBlankPage(true);
                setShowLoader(false);
            });
    }

    const statusColor = (active) => {
        return active === true ? "green" : "red";
    };

    const onClickEditCustomFeed = (item) => {
        setOpenOverlay(true);
        setSelectedItem(item);
    }

    const onClickConfigureCustomFeed = (item) => {
        setOpenOverlay1(true);
        setSelectedItem(item);
    }

    const getFilteringTypeLabel = (value) => {
        const type = Filtering_Type.find(item => item.value === value);
        return type ? type.label : 'Unknown';
    };

    const checkForWebsiteMsid = (listArray) => {
        if (typeof props.websiteValue !== 'undefined' && typeof props.websiteValue.denmarkObject !== 'undefined' && typeof props.websiteValue.denmarkObject.msid !== 'undefined' && typeof listArray !== 'undefined') {
            const websiteMsid = props.websiteValue.denmarkObject.msid;
            if (websiteMsid == listArray[0]) {
                return 'ALL';
            } else {
                return listArray?.join(', ')
            }
        } else {
            return listArray?.join(', ')
        }
    }

    const handleSwitchChange = (name, value, item) => {
        let metaData = JSON.parse(sessionStorage.getItem('metaData'));
        const config = {
            url: PAGE_BUILDER_ENDPOINTS.FEED_MANAGEMENT_UPDATESTATUS + `?websiteId=${metaData.websiteId}&feedId=${item.feedId}&isActive=${value}`,
            method: 'POST',
        };

        httpMiddleware(config)
            .then((response) => {
                if (response && response.status === 200) {
                    console.log(response.data);
                    let item = customFeeds.filter((item) => item.feedId == response.data.split(": ")[1])[0];
                    item.isActive = !item.isActive;
                    //updateOrAddFeed(customFeeds, item[0]);


                    setCustomFeeds((prevFeeds) => {
                        const feedIndex = prevFeeds.findIndex(feed => feed.feedId === item.feedId);
                        if (feedIndex !== -1) {
                            const updatedFeeds = [...prevFeeds];
                            updatedFeeds[feedIndex] = item;
                            return updatedFeeds;
                        } else {
                            return [...prevFeeds, item];
                        }
                    });
                    showSuccessToast(`Selected feed is ${item.isActive ? 'activated' : 'deactivated'}.`);
                } else {
                    console.error('Custom feeds failed to update status: ' + response.status);
                }
            })
            .catch((error) => {
                console.error("Custom feeds failed to fetch update status:", error);
            });
    }

    function capitalizeFirstChar(str) {
        if (typeof str !== 'string' || str.length === 0) {
            return str; // Return the input as-is if it's not a string or is empty
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className='fix-wrapper'>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <div className='LandingTopWrapper'>
                        <div className='LandingHeading' style={{ marginBottom: '25px' }}>
                            <Button variant="contained" size="medium" onClick={createNewFeed}
                                className='buttonCreateFeed'>
                                CREATE FEED
                            </Button>
                            <p className='welcomeText'>Custom Feed<span className='companyName'></span></p>
                        </div>
                        {
                            showLoader === true &&
                            <Stack
                                sx={{ color: 'grey.500' }}
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <CircularProgress color="secondary" />
                            </Stack>
                        }
                        {
                            showBlankPage === true &&
                            <div className='LandingTopWrapper'>
                                <div className='NoFeedText'>
                                    <p>No feed ingested in your website.</p>
                                    <p>Click on “Add Feed” button to ingest your first feed.</p>
                                </div>
                            </div>
                        }
                        {
                            customFeeds.length > 0 &&
                            <div>
                                {customFeeds.map((item, index) => {
                                    let outputType = 'xml';
                                    if (item.outputFormat === 'JSON') {
                                        outputType = 'JSON'
                                    }
                                    return (
                                        <Accordion
                                            key={index}
                                            defaultExpanded={index === 0}
                                            expanded={expanded === `panel${index}`}
                                            onChange={handleChange(`panel${index}`)}
                                            className='accordianClass'
                                        >
                                            <AccordionSummary expandIcon={null} className='accordianSummaryClass'>
                                                <p style={{ fontSize: '24px', paddingRight: '20px', color: '#323232' }}>
                                                    {expanded === `panel${index}` ? '-' : '+'}
                                                </p>
                                                <p className='feedName'>{item.name}</p><p className='feedId'>({item.feedId})</p>
                                                <Typography variant="body2" style={{ marginLeft: 'auto', color: statusColor(item.isActive), fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, letterSpacing: '-0.03999999910593033px', textAlign: 'left' }}>
                                                    {item.isActive ? 'Active' : 'Inactive'}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className='feedAccordian'>
                                                    <div>
                                                        <div className="inline-container"><p className='staticTextStyling'>Output Format : </p><p className='textStyling'>{item.outputFormat}</p></div>
                                                        <div className="inline-container"><p className='staticTextStyling'>Story Type : </p><p className='textStyling'>{item.contentTypes?.map(capitalizeFirstChar)?.join(', ')}</p></div>
                                                        <div className="inline-container"><p className='staticTextStyling'>Custom Type : </p><p className='textStyling'>{getFilteringTypeLabel(item.filteringType)}</p></div>
                                                        <div className="inline-container-feed"><p className='staticTextStyling'>Included Section/Subsection : </p><p className='textStyling'>{checkForWebsiteMsid(item.includeSecAndSubSecIds)}</p></div>
                                                        <div className="inline-container"><p className='staticTextStyling'>Included Tags : </p><p className='textStyling'>{item?.orIncludeTagIds?.join(', ')}</p></div>
                                                        <div className="inline-container"><p className='staticTextStyling'>Sort By : </p><p className='textStyling'>{item?.sortBy === 'DATE' ? 'Published Date' : (item?.sortBy === 'UPDATEDATE' ? 'Updated Date' : 'Rank')}</p></div>

                                                    </div>
                                                    <div>
                                                        <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                                                            <IconButton onClick={() => onClickEditCustomFeed(item)}><EditNoteRoundedIcon /></IconButton>
                                                            <IconButton disabled={(item.outputFormat === 'RSS') ? true : false} onClick={() => onClickConfigureCustomFeed(item)}><SettingsApplicationsOutlinedIcon /></IconButton>
                                                            <Tooltip title={(item.isActive) ? `http://${props.websiteValue.websiteDomain}/customfeeds/${item.name.replace(/\s/g, "-")}/${item.feedId}` : ``} arrow>
                                                                <span>
                                                                    <IconButton
                                                                        disabled={(!item.isActive)}
                                                                        component="a"
                                                                        href={`http://${props.websiteValue.websiteDomain}/customfeeds/${item.name.replace(/\s/g, "-")}/${item.feedId}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer" // Adds security
                                                                    ><RemoveRedEyeOutlinedIcon /></IconButton>
                                                                </span>
                                                            </Tooltip>
                                                            <Divider orientation="vertical" flexItem />
                                                            <Switch
                                                                checked={item.isActive}
                                                                onChange={(e) => handleSwitchChange("isActive", e.target.checked, item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }
                                )}
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
            <Dialog open={openOverlay} onClose={handleCloseOverlay}>
                <AddNewFeedExport handleCloseOverlay={handleCloseOverlay} selectedItem={selectedItem} getAllFeedsOnPage={getAllFeedsOnPage} websiteValue={props.websiteValue}></AddNewFeedExport>
            </Dialog >
            <Dialog open={openOverlay1} onClose={handleCloseOverlay1}>
                <AddConfiguraiton handleCloseOverlay={handleCloseOverlay1} selectedItem={selectedItem}></AddConfiguraiton>
            </Dialog >
        </div >
    );
}