const initialData = {
    componentsList: [],
    layoutDataJson: [
        {
        //header
          type: "row",
          columns: [
            { type: "col", content: "Column 1 Content", style: {height: "100px", width: "50%"}, component: "Header", props: { cartItems: [{title: "test 1"}, {title: "test 2"}, {title: "test 3"}],}},
            // { type: "col", content: "Column 2 Content", style: {height: "10px", width: "50%"} }
          ]
        }
      ]
}

const PagebuilderReducer = (state = initialData, action) => {
    //console.log("action", action)

  if(action.type == "SET_COMPONENT_LIST_DATA") {
    return {
        ...state,
        componentsList: [...state.componentsList, ...action.payload]
      };
  }

    return state;
}

export default PagebuilderReducer;