// src/store/sagas.js
import { takeEvery, put, all } from 'redux-saga/effects';
// Import your action types and creators
import HomeSaga from '../pages/Home/HomeSaga';


// Define your sagas
function* mySaga() {
  try {
    // Perform asynchronous tasks
    // Dispatch actions using put() as needed
  } catch (error) {
    // Handle errors
  }
}

// Watch for specific action types
function* watchMySaga() {
  yield takeEvery('SOME_ACTION_TYPE', mySaga);
}

export default function* rootSaga() {
  yield all([
    // watchMySaga(),
    // Add other watchers here
    HomeSaga(),
  ]);
}