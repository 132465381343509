// src/reducers/index.js
import { combineReducers } from 'redux';
import PagebuilderReducer from "../pages/Home/HomeReducer";
// import cartReducer from './cartReducer';

const rootReducer = combineReducers({
  pagebuilderData: PagebuilderReducer,
  
});

export default rootReducer;
