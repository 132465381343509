import each from 'lodash/each';
import { uniqueStyles } from './Utils';
 

export const getCss = (editor) => {
  const rules = editor.CssComposer.getAll();
  let result = '';

  const { atRules, notAtRules } = splitRules(rules);
  notAtRules.forEach(rule =>  result += rule.toCSS());
  sortMediaObject(atRules).forEach(item => {
    let rulesStr = '';
    const atRule = item.key;
    const mRules = item.value;

    mRules.forEach(rule => {
      const ruleStr = rule.getDeclaration();

      if (rule.get('singleAtRule')) {
        result += `${atRule}{${ruleStr}}`;
      } else {
        rulesStr += ruleStr;
      }
    });

    if (rulesStr) result += `${atRule}{${rulesStr}}`;
  });

  result = uniqueStyles(editor, result);
  return result;
}

const splitRules = (rules) => {
  const atRules = {};
  const notAtRules = [];

  rules.forEach(rule => {
    const atRule = rule.getAtRule();

    if (atRule) {
      const mRules = atRules[atRule];

      if (mRules) {
        mRules.push(rule);
      } else {
        atRules[atRule] = [rule];
      }
    } else {
      notAtRules.push(rule);
    }
  });

  return {
    atRules,
    notAtRules,
  };
}


const getQueryLength = (mediaQuery) => {
  const length = /(-?\d*\.?\d+)\w{0,}/.exec(mediaQuery);
  if (!length) return Number.MAX_VALUE;

  return parseFloat(length[1]);
}

const sortMediaObject = (items = {}) => {
  const itemsArr = [];
  each(items, (value, key) => itemsArr.push({ key, value }));
  return itemsArr.sort(
    (a, b) => getQueryLength(b.key) - getQueryLength(a.key)
  );
}