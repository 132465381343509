
import { put, takeEvery, all } from 'redux-saga/effects';
import { setComponentListData } from './Actions';
import { API_URLS } from '../../core/constants';

function* fetchDataAsync() {
  try {

    //console.log("i am inside homeSaga")
    // Simulate an API call
    // const data = yield response.json();
    yield put(
      setComponentListData([
        {
          name: "Card",
          props:
          {
            metaData: { headline: 'Tsunami of self-delusion Imran Khan is creating in Pakistan', imageUrl: `${API_URLS.STATIC_TOIMG}/thumb/imgsize-18212,msid-94420449,width-600,resizemode-4/94420449.jpg` },
            //onClick: () => console.log("ffff")
          }
        },
      ]))

  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

function* watchFetchData() {
  yield takeEvery("FETCH_COMPONENTS_DATA", fetchDataAsync);
}

export default function* rootSaga() {
  yield all([watchFetchData()]);
}
