import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CONST_PERMISSIONS, PAGE_BUILDER_ENDPOINTS } from "../../core/constants";
import { getLocalStorage, getUserSession, updateSessionObject } from "../../core/Utils";
import Home from "../../pages/Home/Home";
const YOUR_RETURN_URL = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/userAuthenticate";
let host = window.location.href;

const Login = () => {
  let host = window.location.href;
  console.log("first");
  //host = host.replace(/\/$/, "") + "/";
  host = host.replace(/#createSite$/, "");
  getUserSession()
    .then(response => {
      console.log("second");
      debugger;
      if (response != null && response) {
        if (
          response.permissions !== undefined && response.permissions && response.permissions.length > 0
          // &&
          //response.permissions.find((op) => op.hostId === response.tokenPayload.hostId) !== undefined &&
          //response.permissions.find((op) => op.hostId === response.tokenPayload.hostId).permission.includes(CONST_PERMISSIONS.PAGE_BUILDER)
          //response.tenantIdPermissions && response.tenantIdPermissions.includes(CONST_PERMISSIONS.PAGE_BUILDER)
          ) {
          console.log("Valid permission");
        } else {
          window.location.href = window.location.origin + "/dashboard";
          return;
        }
        updateSessionObject("metaData", response);
        let params = new URLSearchParams(window.location.search);
        let redirectTo = params.get('redirectTo');

        if (redirectTo) {
          window.location.href = redirectTo;
        } else {
          let pathname = window.location.pathname;
          if (pathname && pathname.includes("/")) {
            pathname = pathname.split("/")[1];
            if (pathname == "pagebuilder") {
              let hash = '';
              const value = getLocalStorage('hash');
              if (value) {
                hash = '#' + value;
              }
              pathname = "pagebuilder/Home" + hash;
            }
          }
          window.location.href = window.location.origin + "/" + pathname;
        }
      } else {
        window.iamAuthSDK.login();
        //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
      }

    }).catch((error) => {
      debugger;
      console.log("Error while getting session data: ", error);
      if (error.response && error.response.status === 401) {
        window.iamAuthSDK.login();
        //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
      }
    });

  return <div></div>;
};

export default Login;