import { GLOBAL_DATE_FORMAT, GLOBAL_FONT_FAMILY, GLOBAL_FONT_WEIGHT, GLOBAL_LINE_SPACING } from "../../core/constants";

export default function TypographyHTMLCode(colorStrings){  
  return(
    `<div class="gjs-sm-sector gjs-sm-sector__general no-select">
    <div class="gjs-sm-sector-title" data-sector-title="" onclick="this.classList.contains('gsm-open') ? this.classList.remove('gsm-open') : this.classList.add('gsm-open')">
      <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
      <div class="gjs-sm-sector-label">Colors</div>
    </div>
      <div id="colorProperties" class="gsm-sector-field">
        <div class="gjs-sm-property gjs-sm-composite gjs-sm-property__margin gjs-sm-property--full">
          <!--<div class="gjs-sm-label" data-sm-label="">
            <span class="gjs-sm-icon " title="">
              Theme Color
            </span>
          </div>-->

          <div class="gjs-fields" data-sm-fields="" style="width: fit-content;">
          <!--<div class="gjs-sm-field gjs-sm-composite">-->
          <span id="gjs-sm-input-holder">
          <div class="gjs-sm-label" data-sm-label="">
            <span class="gjs-sm-icon" style="
              padding-left: 40px;
              word-spacing: 26px;
          ">P S T Tx</span>
          </div>
          <div class="color-wrapper">`+colorStrings[0]+`</div>

          </span>
        </div>
        </div>

        <!--<div class="gjs-sm-property gjs-sm-composite gjs-sm-property__margin gjs-sm-property--full">
          <div class="gjs-sm-label" data-sm-label="">
            <span class="gjs-sm-icon " title="">
              Content Color
            </span>
          </div>

          <div class="gjs-fields" data-sm-fields="" style="width: fit-content;">
          <div class="gjs-sm-field gjs-sm-composite">
          <span id="gjs-sm-input-holder">
          
          `+colorStrings[1]+`

          </span>
          </div>
        </div>
        </div>

        <div class="gjs-sm-property gjs-sm-composite gjs-sm-property__margin gjs-sm-property--full">
          <div class="gjs-sm-label" data-sm-label="">
            <span class="gjs-sm-icon " title="">
              Background Color
            </span>
          </div>

          <div class="gjs-fields" data-sm-fields="" style="width: fit-content;">
          <div class="gjs-sm-field gjs-sm-composite">
          <span id="gjs-sm-input-holder">
          
          `+colorStrings[2]+`

          </span>
          </div>
        </div>
        </div>-->
      
      </div>
    </div>
    
    
    <div class="gjs-sm-sector gjs-sm-sector__general no-select">
      <div class="gjs-sm-sector-title" data-sector-title="" onclick="this.classList.contains('gsm-open') ? this.classList.remove('gsm-open') : this.classList.add('gsm-open')">
        <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
        <div class="gjs-sm-sector-label">Typography</div>
      </div>
      <div id="typographyProperties" class="clearfix accordion-global-body gsm-sector-field">
     
      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
      <div class="global-block-headings">H1</div>
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
        Font family
      </span>
      
    </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
        <span><select id="typography-h1-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
        <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
        </div>
      </div>
    </div>
    </div>
    
    <div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
        Font size
      </span>
      </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input id="typography-h1-font-size" type="number" min="1" max="100"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h1-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
        <div class="gjs-field-arrow-u" data-arrow-up=""></div>
        <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
    </div></div>
    </div>
    
    <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
        Font weight / style
      </span>
    </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
        <span id="gjs-sm-input-holder"><select id="typography-h1-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
        <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
        </div>
      </div>
    </div>
    </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
        Character Spacing
      </span>
      
    </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-h1-character-spacing"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h1-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
        <div class="gjs-field-arrow-u" data-arrow-up=""></div>
        <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
    </div></div>
    </div>
    <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
        Text Transform
      </span>
      
    </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
        <span><select id="typography-h1-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
        <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
        </div>
      </div>
    </div>
    </div>
   

    <!-- h2 -->
    <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
    <div class="global-block-headings">H2</div>
    <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Font family
    </span>
    
  </div>
    <div class="gjs-fields" data-sm-fields="">
    <div class="gjs-field gjs-select">
      <span><select id="typography-h2-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
      <div class="gjs-sel-arrow">
        <div class="gjs-d-s-arrow"></div>
      </div>
    </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
    <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Font size
    </span>
    
  </div>
    <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
    <span class="gjs-input-holder"><input id="typography-h2-font-size" type="number" min="1" max="100"/></span>
    <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h2-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
    <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
    </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
    <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Font weight / style
    </span>
    
  </div>
    <div class="gjs-fields" data-sm-fields="">
    <div class="gjs-field gjs-select">
      <span id="gjs-sm-input-holder"><select id="typography-h2-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
      <div class="gjs-sel-arrow">
        <div class="gjs-d-s-arrow"></div>
      </div>
    </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
    <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Character Spacing
    </span>
    
  </div>
    <div class="gjs-fields" data-sm-fields="">
    <div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-h2-character-spacing"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h2-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
        <div class="gjs-field-arrow-u" data-arrow-up=""></div>
        <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
    </div>
    </div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
    <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Text Transform
    </span>
    
  </div>
    <div class="gjs-fields" data-sm-fields="">
    <div class="gjs-field gjs-select">
      <span><select id="typography-h2-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
      <div class="gjs-sel-arrow">
        <div class="gjs-d-s-arrow"></div>
      </div>
    </div>
  </div>
  </div>

      <!-- h3 -->
      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
      <div class="global-block-headings">H3</div>
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font family
      </span>
      
  </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="typography-h3-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font size
      </span>
      
  </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input id="typography-h3-font-size" type="number" min="1" max="100"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h3-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font weight / style
      </span>
      
  </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span id="gjs-sm-input-holder"><select id="typography-h3-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Character Spacing
      </span>
      
  </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-h3-character-spacing"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h3-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Text Transform
      </span>
      
  </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="typography-h3-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
  </div>
  </div>

  <!-- h4 -->
  <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
  <div class="global-block-headings">H4</div>
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
      Font family
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
      <span><select id="typography-h4-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
      <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
      </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
      Font size
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
  <span class="gjs-input-holder"><input id="typography-h4-font-size" type="number" min="1" max="100"/></span>
  <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h4-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
  <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
  </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
      Font weight / style
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
      <span id="gjs-sm-input-holder"><select id="typography-h4-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
      <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
      </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
      Character Spacing
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input id="typography-h4-character-spacing" type="number" min="1" max="100"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h4-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
      Text Transform
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
      <span><select id="typography-h4-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
      <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
      </div>
  </div>
  </div>
  </div>


  <!-- h5 -->
  <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
  <div class="global-block-headings">H5</div>
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font family
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span><select id="typography-h5-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font size
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
  <span class="gjs-input-holder"><input id="typography-h5-font-size" type="number" min="1" max="100"/></span>
  <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h5-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
  <div class="gjs-field-arrows" data-arrows="">
  <div class="gjs-field-arrow-u" data-arrow-up=""></div>
  <div class="gjs-field-arrow-d" data-arrow-down=""></div>
  </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font weight / style
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span id="gjs-sm-input-holder"><select id="typography-h5-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Character Spacing
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
  <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-h5-character-spacing"/></span>
  <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h5-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
  <div class="gjs-field-arrows" data-arrows="">
  <div class="gjs-field-arrow-u" data-arrow-up=""></div>
  <div class="gjs-field-arrow-d" data-arrow-down=""></div>
  </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Text Transform
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span><select id="typography-h5-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div>


  <!-- h6 -->
  <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
  <div class="global-block-headings">H6</div>
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font family
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span><select id="typography-h6-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font size
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
  <span class="gjs-input-holder"><input id="typography-h6-font-size" type="number" min="1" max="100"/></span>
  <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h6-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
  <div class="gjs-field-arrows" data-arrows="">
  <div class="gjs-field-arrow-u" data-arrow-up=""></div>
  <div class="gjs-field-arrow-d" data-arrow-down=""></div>
  </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Font weight / style
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span id="gjs-sm-input-holder"><select id="typography-h6-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Character Spacing
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
  <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-h6-character-spacing"/></span>
  <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-h6-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
  <div class="gjs-field-arrows" data-arrows="">
  <div class="gjs-field-arrow-u" data-arrow-up=""></div>
  <div class="gjs-field-arrow-d" data-arrow-down=""></div>
  </div>
  </div></div>
  </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
  <div class="gjs-sm-label" data-sm-label="">
  <span class="gjs-sm-icon " title="">
  Text Transform
  </span>
  
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-select">
  <span><select id="typography-h6-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
  <div class="gjs-sel-arrow">
      <div class="gjs-d-s-arrow"></div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>

    <!-- body -->
    <div class="gjs-sm-sector gjs-sm-sector__general no-select">
      <div class="gjs-sm-sector-title" data-sector-title=""  onclick="this.classList.contains('gsm-open') ? this.classList.remove('gsm-open') : this.classList.add('gsm-open')">
        <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
        <div class="gjs-sm-sector-label">Body</div>
      </div>
      <div id="bodyProperties" class="clearfix accordion-global-body gsm-sector-field">
          
      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
      <div class="global-block-headings">Body</div>
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font family
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="typography-p-font-family">`+GLOBAL_FONT_FAMILY+`</select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
      </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__font-size">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font size
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input id="typography-p-font-size" type="number" min="1" max="100"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-p-font-size-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
      </div></div>
      </div><div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-weight">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Font weight / style
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span id="gjs-sm-input-holder"><select id="typography-p-font-weight">`+GLOBAL_FONT_WEIGHT+`</select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
      </div><div class="gjs-sm-property gjs-sm-number gjs-sm-integer gjs-sm-property__letter-spacing gjs-sm-property--full">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Character Spacing
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields=""><div class="gjs-field gjs-field-integer">
      <span class="gjs-input-holder"><input type="number" min="1" max="100" id="typography-p-character-spacing"/></span>
      <span class="gjs-field-units"><select class="gjs-input-unit" id="typography-p-character-spacing-end"><option value="" disabled="" hidden="">-</option><option selected="" value="px">px</option><option value="%">%</option><option value="em">em</option><option value="rem">rem</option><option value="vh">vh</option><option value="vw">vw</option></select></span>
      <div class="gjs-field-arrows" data-arrows="">
      <div class="gjs-field-arrow-u" data-arrow-up=""></div>
      <div class="gjs-field-arrow-d" data-arrow-down=""></div>
      </div>
      </div></div>
      </div>
      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Text Transform
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="typography-p-text-transform"><option value="None">None</option><option value="Lowercase">Lowercase</option><option value="Uppercase">Uppercase</option></select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
  </div>
  </div>
  </div>

  <div class="gjs-sm-sector gjs-sm-sector__general no-select">
      <div class="gjs-sm-sector-title" data-sector-title="" onclick="this.classList.contains('gsm-open') ? this.classList.remove('gsm-open') : this.classList.add('gsm-open')">
        <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
        <div class="gjs-sm-sector-label">Date/Time</div>
      </div>
      <div id="dateAndTimeProperties" class="clearfix accordion-global-body gsm-sector-field">

      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Date Format
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="date-time-format">
          `+GLOBAL_DATE_FORMAT+`
      </select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
      </div>

      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family gjs-sm-property--full">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Published / Updated
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="published-or-updated">
      <option value="published">Published Time</option>
      <option value="updated">Updated Time</option>
      </select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
      </div>

      <!--<div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
        <div class="gjs-sm-label" data-sm-label="">
          <span class="gjs-sm-icon " title="">
            Time Format
          </span>
        </div>
        <div class="gjs-fields" data-sm-fields="">
          <div class="gjs-field gjs-select">
              <input type="radio" name="time-format" value="12-hours" style="-webkit-appearance:radio;width:20px;"/> 12-hours<br>
              <input type="radio" name="time-format" value="24-hours" style="-webkit-appearance:radio;width:20px;"/> 24-hours
          </div>
        </div>
      </div>-->
    </div>
  </div>


  <div class="gjs-sm-sector gjs-sm-sector__general no-select">
  <div class="gjs-sm-sector-title" data-sector-title="" onclick="document.getElementById('headerAndBodyScripts').style.display == 'none'?document.getElementById('headerAndBodyScripts').style.display = 'block':document.getElementById('headerAndBodyScripts').style.display = 'none'">
    <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
    <div class="gjs-sm-sector-label">Global Header/Body Script</div>
  </div>
 <div id="headerAndBodyScripts" class="clearfix accordion-global-body" style="display: none">

<div class="gjs-sm-property gjs-sm-composite gjs-sm-property__margin gjs-sm-property--full">
  <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Header Script
    </span>
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-field-integer">
    <textarea id="globalHeaderScript" name="globalHeaderScript" value="" rows="4" cols="50"></textarea>
  </div>
  </div>
</div>

<div class="gjs-sm-property gjs-sm-composite gjs-sm-property__margin gjs-sm-property--full">
  <div class="gjs-sm-label" data-sm-label="">
    <span class="gjs-sm-icon " title="">
      Body Script
    </span>
  </div>
  <div class="gjs-fields" data-sm-fields="">
  <div class="gjs-field gjs-field-integer">
    <textarea id="globalBodyScript" name="globalBodyScript" value="" rows="4" cols="50"/></textarea>
  </div>
  </div>
</div>

</div>
</div>

      <!--<div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Time Zone
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
      <span><select id="time-zone">
      <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
        <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
        <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
        <option value="US/Alaska">(GMT-09:00) Alaska</option>
        <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
        <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
        <option value="US/Arizona">(GMT-07:00) Arizona</option>
        <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
        <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
        <option value="America/Managua">(GMT-06:00) Central America</option>
        <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
        <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
        <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
        <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
        <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
        <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
        <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
        <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
        <option value="America/Manaus">(GMT-04:00) Manaus</option>
        <option value="America/Santiago">(GMT-04:00) Santiago</option>
        <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
        <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
        <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
        <option value="America/Godthab">(GMT-03:00) Greenland</option>
        <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
        <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
        <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
        <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
        <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
        <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
        <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
        <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
        <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
        <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
        <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
        <option value="Asia/Amman">(GMT+02:00) Amman</option>
        <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
        <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
        <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
        <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
        <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
        <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
        <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
        <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
        <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
        <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
        <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
        <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
        <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
        <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
        <option value="Asia/Baku">(GMT+04:00) Baku</option>
        <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
        <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
        <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
        <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
        <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
        <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
        <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
        <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
        <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
        <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
        <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
        <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
        <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
        <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
        <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
        <option value="Australia/Perth">(GMT+08:00) Perth</option>
        <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
        <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
        <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
        <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
        <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
        <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
        <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
        <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
        <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
        <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
        <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
        <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
        <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
        <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
        <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
      </select></span>
      <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
      </div>
      </div>
      </div>
      </div>
  </div>
  </div>-->

  <!--<div class="gjs-sm-sector gjs-sm-sector__general no-select">
      <div class="gjs-sm-sector-title" data-sector-title="" onclick="document.getElementById('breadcrumbsProperties').style.display == 'none'?document.getElementById('breadcrumbsProperties').style.display = 'block':document.getElementById('breadcrumbsProperties').style.display = 'none'">
        <div class="gjs-sm-sector-caret"><svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z"></path></svg></div>
        <div class="gjs-sm-sector-label">Breadcrumbs</div>
      </div>
      <div id="breadcrumbsProperties" style="">
      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Breadcrumbs seprator
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
          <input type="text" id="breadcrumbs-seprator"/>
      </div>
      </div>
      </div>

      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Anchor text for home page
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
          <input type="text" id="anchor-text-for-homepage"/>
      </div>
      </div>
      </div>

      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Prefix for search result page
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
          <input type="text" id="prefix-for-search-result-page"/>
      </div>
      </div>
      </div>

      <div class="gjs-sm-property gjs-sm-select gjs-sm-property__font-family">
      <div class="gjs-sm-label" data-sm-label="">
      <span class="gjs-sm-icon " title="">
      Anchor text for 404 page
      </span>
      
      </div>
      <div class="gjs-fields" data-sm-fields="">
      <div class="gjs-field gjs-select">
          <input type="text" id="anchor-text-for-404"/>
      </div>
      </div>
      </div>
  </div>
  </div>
  </div>-->`
  );
}