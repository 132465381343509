import { PAGE_BUILDER_ENDPOINTS, RENDERING_ENDPOINTS } from '../core/constants';
import { httpMiddleware } from '../../src/core/Utils';

export const deepCopy = function (arr) {
    let copy = [];
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            copy[i] = deepCopy(arr[i]);
        } else if (typeof arr[i] === 'object' && arr[i] !== null) {
            copy[i] = deepCopyObject(arr[i]);
        } else {
            copy[i] = arr[i];
        }
    }
    return copy;
}
export const deepCopyObject = function(obj) {
    let copy = {};
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            copy[key] = deepCopy(obj[key]);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            copy[key] = deepCopyObject(obj[key]);
        } else {
            copy[key] = obj[key];
        }
    }
    return copy;
}




