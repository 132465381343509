import React from 'react';


const SearchBox = ({
  inputValue,
  loading,
  options,
  suggestionClicked,
  handleSearchChange,
  handleResultClick,
  searchboxindex,
  level,
  setactivesearchbox,
  activesearchbox,
  parentIndex

}) => {

  const onfocus = () => {
    setactivesearchbox([level, searchboxindex])
  }

  return (
    <div className='searchBoxcls'>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => handleSearchChange(searchboxindex, parentIndex, e)}
        placeholder="Search..."
        style={{ backgroundColor: 'white' }}
        onFocus={onfocus}
      />
      {loading && <p>Loading...</p>}
      {activesearchbox[0] == level && activesearchbox[1] == searchboxindex && options && options?.data && options?.data?.response && !suggestionClicked && (
        <div className='suggestedcls'>
          {options.data.response.filter(option => option.status === "2").map((option, optindex) => (
            <div key={optindex} onClick={() => handleResultClick(searchboxindex, parentIndex, option)}>
              {option.path} - {option.msid}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
