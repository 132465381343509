import { getSessionObject, httpMiddleware } from "../Utils";
import React, { useState, useEffect } from "react";
import { ShowActiveUsersPopup } from "./ShowActiveUsersPopup";
import { PAGE_BUILDER_ENDPOINTS } from "../constants";
let timestamp = 0;

export function UserSessionComponent() {
  let pageId = 0;
  const [popupData, setPopupData] = useState(null);
  const [previousPageId, setPreviousPageId] = useState(0);

  const updateUserSession = () => {
    //const [data, setData] = useState([]);
    // console.log("updateUserSession called")

    let pageMetaData = JSON.parse(getSessionObject("metaData"));
    if (pageMetaData) {
      let clientId = pageMetaData.clientId;
      let websiteId = pageMetaData.websiteId;
      pageId = pageMetaData.pageId;
      setPreviousPageId(pageId);

      if (pageId !== previousPageId)
        setPopupData(null);
      if (pageId && websiteId !== 0) {
        const config = {
          url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updatePageUserSession?pageId=" + pageId + "&websiteId=" + websiteId + "&clientId=" + clientId,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        httpMiddleware(config)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .then((data) => {
            {
              if (Date.now() - timestamp >= 180000 || popupData === null) {
                setPopupData(data.activeUsers);
                timestamp = Date.now();
              }
            }
          })
          .catch((error) => {
            console.error("Error updating pageUserSession data:", error);
          });
      };
    }
  }
  useEffect(() => {
    updateUserSession();
    const intervalId = setInterval(() => {
      updateUserSession();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [popupData]);

  return (
    <div>
      {popupData != null && <ShowActiveUsersPopup jsonData={popupData} />}
    </div>
  );

}

export function UserSessionComponentOtherPages() {
  const [popupData, setPopupData] = useState(null);

  const updateUserSessionOtherPages = () => {

    let pageMetaData = JSON.parse(getSessionObject("metaData"));
    if (pageMetaData) {
      let clientId = pageMetaData.clientId;
      let websiteId = pageMetaData.websiteId;

      if (websiteId !== 0) {
        const config = {
          url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/updatePageUserSessionOtherPages?websiteId=" + websiteId + "&clientId=" + clientId,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        httpMiddleware(config)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .then((data) => {
            {
              if (Date.now() - timestamp >= 180000 || popupData === null) {
                setPopupData(data.activeUsers);
                timestamp = Date.now();
              }
            }
          })
          .catch((error) => {
            console.error("Error updating pageUserSession data:", error);
          });
      };
    }
  }
  useEffect(() => {
    updateUserSessionOtherPages();
    const intervalId = setInterval(() => {
      updateUserSessionOtherPages();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [popupData]);

  return (
    <div>
      {popupData != null}
    </div>
  );

}

// export const fetchUserSession = () => {

//   let pageMetaData = JSON.parse(getSessionObject("metaData"));
//   let clientId = pageMetaData.clientId;
//   let websiteId = pageMetaData.websiteId;
//   let pageId = pageMetaData.pageId;
//   //let userId=;

//   const config = {
//     url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/pageBuilder/getPageSession?pageId=" + pageId + "&websiteId=" + websiteId + "&clientId=" + clientId,
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };

//   httpMiddleware(config)
//     .then((response) => {
//       if (response.status === 200) {
//         return response.data;
//       } else {
//         return null;
//       }
//     }).then((data) => {
//       return data;

//     })
//     .catch((error) => {
//       console.error("Error fetching pageUserSession data:", error);
//     });
// };