import { bindActionCreators } from 'redux';

export const fetchComponentListData = (val) => ({
  type: "FETCH_COMPONENTS_DATA",
  payload: val,
});

export const setComponentListData = (val) => ({
  type: "SET_COMPONENT_LIST_DATA",
  payload: val,
});

// ... other action creator definitions

const boundActionCreators = (dispatch) => bindActionCreators({
  fetchComponentListData,
  setComponentListData,
  // ... other action creators
}, dispatch);

export default boundActionCreators;