import React, { useEffect, useState } from 'react';
import Home from './Home';
import { getUserSession } from '../../core/Utils';
import { CONST_PERMISSIONS, PAGE_BUILDER_ENDPOINTS } from '../../core/constants';
const YOUR_RETURN_URL = PAGE_BUILDER_ENDPOINTS.DOMAIN + "/userAuthenticate";

const HomeWrapper = () => {
    const [userSession, setUserSession] = useState(null);

    useEffect(() => {
        let host = window.location.href;
        getUserSession()
            .then(response => {
                if (response != null && response) {
                    setUserSession(response);
                } else {
                    window.iamAuthSDK.login();
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                }
            })
            .catch(error => {
                console.error("Error while getting session data: ", error);
                if (error.response && error.response.status === 401) {
                    //window.location.href = PAGE_BUILDER_ENDPOINTS.IAM_DOMAIN + "/identity/login?ru=" + YOUR_RETURN_URL + "?host=" + host;
                    window.iamAuthSDK.login();
                }
            });
    }, []);

    if (!userSession) {
        return null;
    }

    if (
        userSession.permissions !== undefined && userSession.permissions && userSession.permissions.length > 0 
        //&&
        //userSession.tenantIdPermissions &&
        //userSession.permissions.find((op) => op.hostId === userSession.tokenPayload.hostId) !== undefined &&
        //userSession.permissions.find((op) => op.hostId === userSession.tokenPayload.hostId).permission.includes(CONST_PERMISSIONS.PAGE_BUILDER)
        //userSession.tenantIdPermissions && userSession.tenantIdPermissions.includes(CONST_PERMISSIONS.PAGE_BUILDER)
        ) {
        return <Home />;
    } else {
        window.location.href = window.location.origin + "/dashboard";
        return null;
    }
};

export default HomeWrapper;
