import { Box, Button, FormGroup, Grid } from "@mui/material";

export const AllSectionOverlay = (props) => {

    const proceedWithClosure = () => {
        props.handleCloseOverlay();
        props.sendData(props.websiteValue);
    }

    const stopTheAction = () => {
        props.handleCloseOverlay();
    }

    return (
        <>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <div className='LandingTopWrapperCss'>
                        <div className='LandingHeadingPopup'>
                            <p className='welcomeText' style={{ textAlign: 'center' }}>All sections will be selected if “search section” field is left empty. Do you want to continue?</p>
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Box display="flex" justifyContent="center" gap={2}>
                                <Button variant="contained" onClick={stopTheAction}>No</Button>
                                <Button variant="outlined" onClick={proceedWithClosure}>Yes</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>

    );
} 