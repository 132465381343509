import React, { useState, useEffect } from "react";
import { getPageTemplateById, savePageTemplate, saveandPublishPageTemplate } from "./PageTemplateService";
import { getSessionObject, getFormattedCss, updateSessionObject } from "../Utils";
import "./verifySavePageTemplate.css";
import Button from '@mui/material/Button';
//let pageMetaData = null;

export const SavePagePopupComponent = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [sessionLastUpdated, setSessionLastUpdated] = useState(null);
  //const [pageMetaData, setPageMetaData] = useState(null);

  const handleRevert = () => {
    props.toggleSaveModal(false);
    if (!props.state.publishToProd)
      savePageTemplate(pageData);
    else {
      saveandPublishPageTemplate(pageData);
    }
    setShowPopup(false);
    props.toggleSaveModal(false);
  };
  const handleShow = async () => {
    let pageData = await getPageTemplateById(pageMetaData);
    if (window.gpEditor) {
      window.gpEditor.setComponents(JSON.parse(pageData.json));
      window.gpEditor.setStyle(getFormattedCss(pageData.css));
    }
    props.toggleSaveModal(false);

    props.state.publishToProd = false;
    setShowPopup(false);
    props.toggleSaveModal(false);
  };
  useEffect(() => {
    const pageMetaData = JSON.parse(getSessionObject("metaData"));
    if (pageMetaData != null) {
      setSessionLastUpdated(pageMetaData.updatedAt);
    }

    const fetchData = async () => {
      try {
        let dateObject = new Date(sessionLastUpdated);
        let sessionLastUpdatedTimeMillis = dateObject.getTime() + 3000;
        if (pageData == null && pageMetaData != null) {
          let data = await getPageTemplateById(pageMetaData);
          if (Object.keys(data) && Object.keys(data).length !== 0) {
            setPageData(data);
          }
        }
        if (pageData != null) {
          if (pageData.updatedAt != null) {
            const lastupdatedtime = pageData.updatedAt;
            const _dateObject = new Date(lastupdatedtime);
            const lastUpdatedTimeMillis = _dateObject.getTime();
            if (lastUpdatedTimeMillis > sessionLastUpdatedTimeMillis) {
              setShowPopup(true);
            } else {
              if (!props.state.publishToProd) {
                savePageTemplate(pageData);
              }
              else {
                saveandPublishPageTemplate(pageData);
              }
              props.toggleSaveModal(false);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [props, pageData]);
  return (
    <div>
      {showPopup && (
        <div className="overlay">
          <ConfirmationPopup
            message={"Resolving Editing Conflict"}
            onConfirm={handleRevert}
            onCancel={handleShow}
          />
        </div>
      )}
    </div>
  );
};
export const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
  const handleRevert = () => {
    onConfirm();
  };
  const handleShow = () => {
    onCancel();
  };
  return (
    <div className="confirmation-popup">
      <div className="popup-content">
        <h2>{message}</h2>
        <p>Another user has made changes to this page template. Do you want to keep their changes or override with your own ?</p>
        <div className="btn-wrapper">
          <Button className="mr-30" variant="outlined" onClick={handleRevert}>Override Change</Button>
          <Button variant="contained" onClick={handleShow}>Keep Changes</Button>
        </div>
      </div>
    </div>
  );
};

