import React, { useEffect, useRef, useState,} from 'react'
import {createSessionifMissing, getMUICache, loadStyles, httpMiddleware, logoutUserSession } from '../../core/Utils';
import Grid from '@mui/material/Grid';
import { CacheProvider } from '@emotion/react';
// import DashBoard from './DashBoard.css'
import LandingPage from './LandingPage.css'
import logoImage from "../../images/dashboard-logo.svg";
import OrgIcon from "../../images/org-icon.png";
import AppsIcon from '@mui/icons-material/Apps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { PAGE_BUILDER_ENDPOINTS, CONSTANTS } from "../../core/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';



const  LandingMainPage = (props) => {
    const cache = getMUICache();
    const [show, setShow]=useState(false);
    const [dropShow, setDropShow]=useState(false);
    const [webListShow, setWebListShow]=useState(false);
    const[activeState, desabileState]=useState(false);
    const[activeStateLog, desabileStateLog]=useState(false);
    const[activeWebList, desabileWebList]=useState(false);
    const [userEmail, setUserEmail] = useState("");
    const refOne=useRef(null);
    const refTwo=useRef(null);
    const refThree=useRef(null);
    const [websitOptions, setOptions] = useState([]);
    const link = document.querySelector("link[rel~='icon']");


    function handleClick(){
        desabileState(activeState => !activeState);
    }
    function handleLogout(){
        desabileStateLog(activeStateLog => !activeStateLog);
    }
    function handleWebList(){
        desabileWebList(activeWebList => !activeWebList);
    }
    useEffect(() => {
        createSessionifMissing().then( res => {
            if( res && res && res?.tokenPayload?.userEmail ) {
                let userEmail = res.tokenPayload.userEmail;                
                setUserEmail(userEmail);

                document.addEventListener("click" , handleClickOutside, true);

                // document.addEventListener("click" , handleClickLogButton, true);
                document.addEventListener("click" , handleClickWebList, true);

                // Make an API request to fetch your data
                const config = {
                    method: 'GET',
                    url: PAGE_BUILDER_ENDPOINTS.DOMAIN + "/getAllWebsitesByClient",
                    };
                
                    httpMiddleware(config)
                    .then((response) => {
                        if (!response.status) {
                        throw new Error('Network response was not ok');
                        }
                
                        return response.data;
                    })
                    .then((data) => {
                        setOptions(data);
                        // console.log("akhvsb jlk: ", websitOptions);
                        // sessionStorage.setItem('currentWebsiteList', JSON.stringify(data));
                        // handleSelectChange(data[0], "firstLoad"); // Update the options state with the data from the API
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
                    }
                } );
                // add newtitle
                document.title = 'PublishStory Dashboard';

                
                if (link !== null) {
                  link.href = 'https://pagebuilder.publishstory.co/stresources/custom-icons/PS%20favicon.ico'; // Replace '/favicon-page1.ico' with your favicon path for Page 1
                }
    }, []);

    const handleClickOutside = (e) =>{
        if(refOne && refOne.current && refOne.current.contains(e.target)){
            // console.log("clicked in side one")
        }else{
            // console.log("clicked out side one")
            setShow(0);
        }

    }

    // const handleClickLogButton = (e) =>{

    //     if(refTwo && refTwo.current && refTwo.current.contains(e.target)){
    //         console.log("clicked in side two")
    //     }else{
    //         console.log("clicked out side two")
    //         setDropShow(0);
    //     }

    // }

    const handleClickWebList = (e) =>{

        if(refThree && refTwo.current && refTwo.current.contains(e.target)){
            // console.log("clicked in side three")
        }else{
            // console.log("clicked out side two")
            setWebListShow(0);
        }

    }


    function getInitials(input) {
        const words = input.split(" ");
        const initials = words.map(word => word.charAt(0));
        if (initials.length === 1) {
          return initials[0].toUpperCase();
        }
        return (initials[0] + initials[1]).toUpperCase();
      }
      
    let company_name = "Company Name";
    let company_email = "company.name@gmail.com";
    let company_name_initials = "CN";
    if (sessionStorage.getItem("metaData") && JSON.parse(sessionStorage.getItem('metaData')).name) {
        company_name = JSON.parse(sessionStorage.getItem('metaData')).name;
        company_name_initials = getInitials(company_name);
        if(JSON.parse(sessionStorage.getItem("metaData")).tokenPayload && JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail) {
            company_email = JSON.parse(sessionStorage.getItem("metaData")).tokenPayload.userEmail;
        }
    }

    function getlogoImageUrl(data) {
        console.log("CONSTANTS : ", CONSTANTS);
        if(data.websiteDomain && data.websiteResouces && data.websiteResouces.schemaLogo && CONSTANTS && CONSTANTS.API_URL && CONSTANTS.API_URL.VSP_IMAGES) {
            if(CONSTANTS.API_URL.VSP_IMAGES == "") {
                return 'https://' + data.websiteDomain + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            } else {
                return CONSTANTS.API_URL.VSP_IMAGES + '/thumb/' + data.websiteResouces.schemaLogo + '.cms?imgsize=422424&amp;width=96&amp;resizemode=4';
            }
        } else {
            return OrgIcon
        }
    }

    const [pageTemplateData, setPageTemplateData] = useState(null);
    const dymincUrl = "https://pagebuilder.publishstory.co";

      const landingNinedotsJson=[
        // {
        //     mediaPath:dymincUrl + "/stresources/custom-icons/home.svg",
        //     mediaHoverPath:dymincUrl + "/stresources/custom-icons/home_selected.svg",
        //     links:[
        //         {
        //             title: "Home",
        //             path:"https://app.publishstory.co/dashboard/"
        //         }
        //     ]
        // },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Authoring_denmark.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Authoring",
                    // path:"https://app.publishstory.co/cms/"
                    path:"/cms/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Reporting_cmsanalytics.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Reporting",
                    // path:"https://app.publishstory.co/reporting/cms-analytics/"
                    path:"/reporting/cms-analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Analytics_ibeat.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/cmsanalytics_selected.svg",
            links:[
                {
                    title: "Analytics",
                    // path:"https://app.publishstory.co/analytics/"
                    path:"/analytics/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Pagebuilder_m360.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/dots-page_builder_selected.svg",
            links:[
                {
                    title: "Page Builder",
                    // path:"https://app.publishstory.co/pagebuilder/"
                    path:"/pagebuilder/"
                }
            ]
        },

        {
            mediaPath:dymincUrl + "/stresources/custom-icons/assetmanager_chronicle.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/denmark_selected.svg",
            links:[
                {
                    title: "Asset Manager",
                    path:"/"
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Admin_coral.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/Chronicle_selected.svg",
            links:[
                {
                    title: "Admin",
                    // path:"https://app.publishstory.co/admin/"
                    path:"/admin/"
                }
            ]
        },
        {
            mediaPath:dymincUrl + "/stresources/custom-icons/Comment moderator.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Comment Moderator",
                    path:"/", 
                }
            ]
        },


        {
            mediaPath:dymincUrl + "/stresources/custom-icons/VideoAuthoring_slike.png",
            // mediaHoverPath:dymincUrl + "/stresources/custom-icons/ibeat_selected.svg",
            links:[
                {
                    title: "Video Authoring",
                    path:"/"
                }
            ]
        }

      ]


      const footerLinksJson = [
        {
          links: [
            {
              title: "All rights reserved",
            //   path: "/dashboard/copyright"
            },
            {
                title: "Privacy Policy",
                path: "/dashboard/privacy-policy"
              },
              {
                title: "Terms & Conditions and Grievance Redressal Policy",
                path: "/dashboard/terms-conditions"
              },
              {
                title: "About us",
                // path: "/dashboard/about"
              }

          ]
        },

      ]
// /thumb/104894223.cms?imgsize=422424&amp;width=96&amp;resizemode=4

    if(!(userEmail)) {
        return <></>
    }

    return(
        <>
         <div className='MainBodyWrapper' styles={LandingPage}>
            <div className='LandingPageHeader'>
                <div className='LandingPageHeaderWrapper'>
                    <div className='LandingPageLogo'>
                        <figure><img src={logoImage} /></figure>
                    </div>
                    <div className='RightSideMenuWrap'>
                        <div className='MenuSelectItems'>
                        <p className='inNewsroomTiitle'>Newsroom</p>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <Select labelId="SelectPublisher" id="SelectPublisherHelper" label="publishers" defaultValue={0}>
                                {websitOptions.map((data, i) =>{
                                        return <MenuItem key={ 'jj_' + i} className='slectItemsCommon' value={i}>
                                            <img src={getlogoImageUrl(data)}/>
                                            {data.websiteName}</MenuItem>
                                       
                                })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='moreAppsWrapper' id='_9dots_more_apps_wrapper'></div>
                        {/* <div className={`AppsIconsWrap ${activeState ? "activeState": ''}`} onClick={handleClick}>
                            <button onClick= {()=>setShow(!show)} className='dotsButton' ref={refOne}> <AppsIcon></AppsIcon></button>
                            {show? <div className='ShowIconsOnClick'>
                                <ul>
                                {landingNinedotsJson.map((data, i) =>{
                                    console.log('data',data);
                                    
                                    return <li key={i + "1"}>
                                        {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                       return <a href={item.path} target='_blank' key={l + "3"}><i className='IconWrap'>
                                       <figure className='nineDotsIcon'><img src={data.mediaPath} /></figure>
                                       <figure className='nineDotsIconSelected'><img src={data.mediaHoverPath} /></figure>
                                       </i><span>{item.title}</span></a>
                                    })}
                                    </li>
                                   
                                })}
                                </ul>
                                 
                            </div>:null
                            }
                        </div> */}
                        <div className={`UserNameWrap ${activeStateLog ? "activeStateLog": ''}`} onClick={handleLogout}>
                            <button className='logButtonDrop' onClick= {()=>setDropShow(!dropShow)} ref={refTwo}>{company_name_initials}</button>
                            {dropShow? <div className='logOutWrap'>
                            <div className='userDetailsWrap'>
                            <div className='userNameDetails'>{company_name}</div>  
                              <div className='userEmailDetails'>{company_email}</div>
                            </div>
                            <Tooltip title="Logout">
                            <button onClick={logoutUserSession}><FontAwesomeIcon icon={faPowerOff} /> Logout</button>
                            </Tooltip>
                            </div>:null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='fix-wrapper'>
                <CacheProvider value={cache}>
                    <Grid container item md={12}>
                        <div className='tremsAndConditionStaticContentWrap'>
                            <p className='tremsAndConditionStaticContentheading'>Privacy Policy</p>

                            <p className='tremsAndConditionStaticContent'>
                                We at <strong>TIMES INTERNET LIMITED (TIL, Company, we, our, us)</strong> respect your privacy and are committed to
                                protecting your personal data. In this privacy policy, we explain how we collect, use, and process your personal
                                information when you access ‘PublishStory.co’ (“Website”) or associated mobile application(s) (each, an App
                                and together, <strong>Apps</strong>), for delivery of Services. Unless indicated otherwise, this privacy policy further applies to
                                any related websites or other online properties owned or controlled by the Company (together with the
                                Websites and <strong>Apps</strong>, the <strong>Platforms</strong>). Services mean to include all the content, software as a service,
                                information, and any other services made available to you “as is” from our Platforms.
                            </p>
                            <p className='tremsAndConditionStaticContent'>This Privacy Policy must be read in conjunction and together with the Terms of Use of the particular Platform
                                you are using, available at <a href='#'>PublishStory.co/privacy</a>. Please note that links from our Website may take you to
                                external websites which are not covered by this policy. We recommend that you check their privacy policies
                                before you access, use, or download from such sites. We will not be responsible for the content, function, or
                                information collection policies of these external websites.</p>
                            <p className='tremsAndConditionStaticContent'>By accessing, downloading, and using the Platform(s) that we operate, which has a direct link to this Policy,
                                you agree to be governed by the Privacy Policy of the Company.</p>
                            <p className='tremsAndConditionStaticContent'>By agreeing to this Privacy Policy, you represent that you shall not access our Platforms and/or Services by
                                means of any mechanism or technology which conceals your actual geo-location or provides incorrect details
                                of your location, (for example, use a virtual private network (VPN) to access our Services). We shall not be
                                responsible or liable for any processing or collection of your information if you use these mechanisms to
                                access our Platforms and Services.</p>


                            <p className='tremsAndConditionStaticContentheading'>1. Information We Collect</p>
                            <p className='tremsAndConditionStaticContent'>We will only collect your personal information where it is necessary for us to provide you with a
                                service at your request, such as when you contact us, register an account on our Website, avail of
                                subscription services, or access the content on the Platform(s). We have discussed the manners
                                of our collection of information, herein in greater detail for your reference:</p>

                            <p className='tremsAndConditionStaticContentheading'><strong>1.1</strong> Information submitted to us:</p>
                            <p className='tremsAndConditionStaticContent'>You give us information about you whenever you use our Services, such as by doing the
                                following, some of the following categories of information are collected directly by us, and some
                                are provided by you directly for the performance of certain functions:</p>
                            <ul className="bulletList">
                                <li>Accessing our Platforms by means of any web browser or device;</li>
                                <li>Registration, subscribing for our Services on our Platforms;</li>
                                <li>Enquiring about our products or services;</li>
                                <li>Purchasing our subscription packages on the Platforms;</li>
                                <li>Initiating and maintaining correspondence with us.</li>

                            </ul>
                            <p className='tremsAndConditionStaticContent'>This information may include but not be limited to the following:</p>
                            <ul className="bulletList">
                                <li>Social media plugin details like – Facebook, Google, LinkedIn, Apple ID or any

                                    other– for the purpose of identification</li>
                                <li>Contact information such as email address, mobile number;</li>
                                <li>Payment information (this is securely collected and processed by our payment

                                    service provider exclusively);</li>
                                <li>Additional information relevant to your use of our Platforms and Services, such as
                                    one time password sent for registration, your marketing preferences, place of
                                    employment and designation, survey responses and feedback;</li>
                                <li>Financial information – Investment details, compensation details, salary range,

                                    where the relevant Service of the Platform requires such indicators.</li>
                            </ul>
                            <p className='tremsAndConditionStaticContent'>We take extra precautions to ensure that such sensitive personal data is kept secure and
                                confidential, and we will only retain this data for as long as necessary for the purposes for which
                                we collect it.</p>

                            <p className='tremsAndConditionStaticContentheading'><strong>1.2</strong> Information we collect automatically:</p>
                            <p className='tremsAndConditionStaticContent'>We collect information using cookies and other similar technologies to help distinguish you from
                                other users of our Platforms, and streamline your online.</p>
                            <p className='tremsAndConditionStaticContent'>When you visit our Platforms, we may collect the following information:</p>
                            <ul className="bulletList">
                                <li>Your activity logs on the Platform(s) - Which pages you access, view and which

                                    links you follow;</li>
                                <li>Your IP address/ device&#39;s serial number or unique identification number and

                                    general location, including country, address and pin code;</li>
                                <li>A device identifier (cookie or IP address or Device ID or Android Advertiser ID /

                                    iDFA ID), or any other identifier;</li>
                                <li>Details of the hardware and software that you are using to access the Platforms
                                    such as your computer/device name, your operating system, browser type and
                                    version, application version, operator name, screen size, CPU speed, and
                                    connection speed;</li>
                                <li>Any passwords that you use on our Platforms or any other authentication token

                                    used to login;</li>
                                <li>URL and time stamps;</li>
                                <li>Details of your visits to our Platforms and the resources that you access, including,
                                    but not limited to, traffic data, location data, weblogs and other communication
                                    data.</li>
                            </ul>

                            <p className='tremsAndConditionStaticContentheading'><strong>1.3</strong> Information we collect about you from other sources.</p>
                            <p className='tremsAndConditionStaticContent'>We may collect information about you from other sources. This may include the following:</p>
                            <ul className="bulletList">
                                <li>Publicly available information.</li>
                                <li>Information you have shared publicly, including on social media. We collect your e-
                                    mail ID and the relevant public profile information, which may include your name,
                                    profile picture, age, gender, and any other pertinent information from such social
                                    media account which is, necessary for verification of your identity.</li>
                                <li>We also receive information from you when you interact with our pages, Apps,

                                    groups, accounts, or posts on social media platforms.</li>
                            </ul>
                            <p className='tremsAndConditionStaticContent'>This list is not exhaustive, and, in specific instances, we may need to collect additional data for
                                the purposes set out in this policy.</p>

                            <p className='tremsAndConditionStaticContentheading'><strong>1.4</strong> Information we receive about you from other sources</p>
                            <p className='tremsAndConditionStaticContent'>Sometimes you will have given your consent to other websites, services or third parties to
                                provide information to us.</p>
                            <p className='tremsAndConditionStaticContent'>It could include information from third parties that we work with to provide our products and
                                services, such as delivery companies, technical support companies and advertising companies.
                                Whenever we receive information about you from these third parties, we will only use it in such
                                manner that is already communicated to you, as per this Policy, and for such defined purposes
                                only. We may further collect additional analytics reports, information from other services,
                                websites, who work together with us.</p>
                            <p className='tremsAndConditionStaticContent'>When you chose any subscription or paid service, we or our payment gateway provider may
                                collect and/or process your personal information such as address or billing information,
                                including your credit/debit card number and expiration date etc. as per the terms of this Policy.
                                Any financial information provided by you will be processed by such third parties who provide
                                the functionalities for such Services to us, for effective processing. We encourage you to
                                familiarize yourself with the privacy policies provided by such third parties prior to providing
                                them with your information or entering into any transactions with them.</p>
                            <p className='tremsAndConditionStaticContent'><a href='#'>Marketing and Analytics</a></p>

                            <p className='tremsAndConditionStaticContent'>We may use Web Beacons to track your online usage patterns in an anonymous manner, without
                                collecting your personally identifiable information. We may also use web beacons in HTML
                                emails that we send subscribers who have opted in to receive email from us, to determine
                                whether our recipients have opened those emails and/or clicked on links in those emails. We use
                                this information to inter-alia deliver our web pages to you upon request, to tailor our Platform(s)
                                to the interests of our users, to measure traffic within our Platform(s) to improve the quality,
                                functionality and interactivity of our Platform(s) and let advertisers know the geographic
                                locations from where our Users come without personally identifying the users.</p>
                            <p className='tremsAndConditionStaticContent'>We place website beacons to collect data for our partners, we require our partners to inform
                                users on their privacy policy page that the webpage uses website beacons. This option will only
                                be recorded in your current browser and will not be recorded in your user history.</p>

                            <p className='tremsAndConditionStaticContentheading'>2. Purpose of Collection of Information.</p>
                            <p className='tremsAndConditionStaticContent'>We collect information (including Personal Information) to provide the Services and for the

                                purposes as outlined below including but not limited to:</p>
                            <ul className="bulletList">
                                <li>to help us identify you when you log into the Platform and when you register an

                                    account with us, and, to map a specific profile with an authorized user;</li>
                                <li>to help facilitate in-app purchases and make subscribed and paid content available

                                    to you;</li>
                                <li>to enhance the quality of services that we provide and improve your experience

                                    during browsing;</li>
                                <li>to personalize your browsing experience on the Platform(s);</li>
                                <li>to recommend videos, content, and news to users they might be interested in;</li>
                                <li>for providing location-based services;</li>
                                <li>for the performance of a contractual and legal obligation;</li>
                                <li>to communicate with you;</li>
                                <li>to provide you with news, special offers, general information, commercial
                                    communications about other products and services along with marketing
                                    information and surveys;</li>
                                <li>to provide and process service requests initiated by you.</li>
                                <li>Notification of any changes in the terms of use or privacy policy;</li>
                                <li>Resolution of any queries related to your use of Services.</li>
                                <li>Any other new services developed by us, our affiliates, subsidiaries and /or third-

                                    party partners</li>
                            </ul>
                            <p className='tremsAndConditionStaticContentheading'>3. Sharing and Use</p>

                            <p className='tremsAndConditionStaticContent'><strong>3.1.</strong> We may share your information with our trusted partners or third parties who provide us with

                                support services, for meeting the obligations agreed to between you and us.</p>
                            <p className=' tremsAndConditionStaticContent'><strong>3.2</strong> We may use and/or disclose your information with the Company-controlled businesses,
                                affiliates, subsidiaries and other entities within the Times group of companies, other third-party
                                business partners, service providers including but not limited to services providers, advertising
                                networks, technology partner, social networks and any other third parties:</p>
                            <ul className="bulletList">
                                <li>to assist them in reaching out to you in relation to their programs or campaigns
                                    (including marketing and sales) and to process your query/requests (such as job
                                    application) and/or provide the services;</li>
                                <li>to process payments on our behalf. Our payment provider will collect and process
                                    your payment details; we will not store or have access to your full credit or debit
                                    card details, except any transactional information for the purpose of reconciliation
                                    of transactions on our Platform(s).</li>
                                <li>to send e-mails, instant messages, social media messages and SMS messages

                                    and/or manage contact management systems.</li>
                                <li>recommendations services, ad tech services, analytic services, including but not
                                    limited to click stream information, browser type, time and date, information
                                    about your interactions with advertisements and other content, including through
                                    the use of cookies, beacons, mobile ad identifiers, and similar technologies, in
                                    order to provide content, advertising, or functionality or to measure and analyze
                                    ad performance, on our Services or Platforms and on 3 rd party platforms.</li>
                                <li>serve ads on our Platforms, affiliate websites, and any other digital platform
                                    available on the internet. However, the privacy policy of such digital platforms
                                    shall be applicable upon the processing of information on such third-party digital
                                    platforms.</li>
                                <li>to assist and to reach out to you in relation to various programs or campaigns

                                    (including marketing and sales) and/or to process your query/requests.</li>
                            </ul>
                            <p className='tremsAndConditionStaticContent'><strong>3.3</strong> We may further be required to share your personal information in certain exceptional

                                circumstances; this would be where we believe that the disclosure is:</p>
                            <ul className="bulletList">
                                <li>Required by the law, or in order to comply with judicial proceedings, court orders

                                    or legal or regulatory proceedings.</li>
                                <li>Necessary to protect the safety of our employees, our property or the public.</li>
                                <li>Necessary for the prevention or detection of crime, including exchanging
                                    information with other companies or organizations for the purposes of fraud
                                    protection and credit risk reduction.</li>
                                <li>Proportionate as part of a merger, business, or asset sale, in the event that this
                                    happens we will share your information with the prospective seller or buyer
                                    involved.</li>

                            </ul>

                            <p className='tremsAndConditionStaticContentheading'>4. Data Retention</p>
                            <p className='tremsAndConditionStaticContent'><strong>4.1</strong> We will only store your personal information for as long as we need it for the purposes for which
                                it was collected. Retention periods for records will also be based on the type of record, the
                                nature of the activity and the product and service that the same is associated with, linked to.</p>
                            <p className='tremsAndConditionStaticContent'><strong>4.2</strong> We retain your information till such period that is required for the purposes of us meeting your
                                requests on our Platforms, and in compliance with the applicable laws, and statutory
                                requirements.</p>

                            <p className='tremsAndConditionStaticContentheading'>5. Information Security.</p>
                            <p className='tremsAndConditionStaticContent'><strong>5.1</strong> We take commercially reasonable technical, physical, and organizational steps to safeguard any
                                information you provide to us, and to protect it from unauthorized access, loss, misuse, or
                                alteration.</p>
                            <p className='tremsAndConditionStaticContent'><strong>5.2</strong> We try to ensure that all information you provide to us is transferred securely via the website
                                (always check for the padlock symbol in your browser, and “https” in the URL, to ensure that
                                your connection is secure).</p>
                            <p className='tremsAndConditionStaticContent'><strong>5.3</strong> All information you provide to us is stored on secure servers. Where we have given you (or
                                where you have chosen) a password which enables you to access certain parts of our website,
                                you are responsible for keeping this password confidential. We ask you not to share a password
                                with anyone.</p>
                            <p className='tremsAndConditionStaticContent'><strong>5.4</strong> Although we take reasonable security precautions, no computer system or transmission of
                                information can ever be completely secure or error-free, and you should not expect that your
                                information will remain private under all circumstances. The Company will not be liable for any
                                damages of any kind arising from the use of the Platform, including, but not limited to any
                                indirect, incidental, special, consequential or punitive damages, or any loss of profits or
                                revenues, whether incurred directly or indirectly or any loss of data, use, good-will, or other
                                intangible losses.</p>
                            <p className='tremsAndConditionStaticContentheading'>6. Data Subject Rights</p>
                            <p className='tremsAndConditionStaticContent'>If you require any further information about your rights as explained below, or if you would like
                                to exercise any of your rights, please reach out to us by writing to the Grievance Officer of the
                                Company.</p>
                            <p className='tremsAndConditionStaticContent'><strong>6.1</strong> You have the right to access your personal data;</p>
                            <p className='tremsAndConditionStaticContent'><strong>6.2</strong> You have the right to correct any inaccurate or incomplete personal data;</p>
                            <p className='tremsAndConditionStaticContent'><strong>6.3</strong> You have the right to withdraw your consent;</p>
                            <p className='tremsAndConditionStaticContent'><strong>6.4</strong> You have the right to request deletion of your account.</p>
                            <p className='tremsAndConditionStaticContent'>However, we may maintain backup copies of your information, to the extent necessary to
                                comply with our legal obligations.</p>

                            <p className='tremsAndConditionStaticContentheading'>7. Cross-Border Transfers</p>
                            <p className='tremsAndConditionStaticContent'><strong>7.1</strong> We operate globally and may transfer your personal information to individual companies of the
                                Times Internet Limited affiliated companies or third parties in locations around the world for the
                                purposes described in this privacy policy, only upon satisfaction that the country has an
                                adequate and appropriate level of protection, and the transfer of information is lawful. We shall
                                comply with our legal and regulatory obligations in relation to your Information, including having
                                a lawful basis for transferring Personal Information and putting appropriate safeguards in place
                                to ensure an adequate level of protection for the Personal Information. We will also ensure that
                                the recipient in such alternate country is obliged to protect your Information at a standard of
                                protection comparable to the protection under applicable laws. Our lawful basis for such
                                transfer will be either on the basis of content or one of the safeguards permissible by laws.</p>

                            <p className='tremsAndConditionStaticContentheading'>8. Children Privacy</p>
                            <p className='tremsAndConditionStaticContent'><strong>8.1</strong> You must have attained the age of majority to be able to use and access our Services. If you are a
                                minor in your jurisdiction, your registration and use of our Services must be with the supervision
                                of an adult.</p>
                            <p className='tremsAndConditionStaticContent'><strong>8.2</strong> As a parent or legal guardian, please do not allow your minors under your care to submit
                                Personal Information to us. In the event that such Personal Information of a minor is disclosed to
                                us, you hereby consent to the processing of the minor’s Personal Information and accept and
                                agree to be bound by this Privacy Policy and take responsibility for his or her actions.</p>
                            <p className='tremsAndConditionStaticContent'><strong>8.3</strong> However, if the Company is notified that the personal data of individuals below the age of
                                majority has been collected by Us, without user or parental consent, We shall take all necessary
                                steps to delete such information from our servers. As a parent or legal guardian, you are
                                encouraged to reach out to the Grievance Officer, for redressal of any complaints in relation to
                                collection of children data.</p>

                            <p className='tremsAndConditionStaticContentheading'>9. Changes to the Privacy Policy</p>
                            <p className='tremsAndConditionStaticContent'>Any changes we make to our privacy policy in the future, to incorporate necessary changes in
                                technology, or applicable law, will be posted on this page and, where appropriate, notified to
                                you by e-mail. Your use of the Services after such notice shall be deemed as acceptance of such
                                changes to our Privacy Policy. Please check back frequently to see any updates or changes to our
                                privacy policy.</p>
                            <p className='tremsAndConditionStaticContent'>This version was last updated on 25 th April 2024 and historic versions can be obtained by
                                contacting us.</p>

                            <p className='tremsAndConditionStaticContentheading'>10. Grievance Redressal</p>
                            <p className='tremsAndConditionStaticContent'>If you have any complaints, or concerns with regards to the use, storage, deletion, and disclosure
                                of your personal information provided to Us, you may reach out to the designated Grievance
                                Officer. The Grievance Officer appointed by Times Internet Limited is <span>Mr. Angad Singh</span> who can
                                be contacted at <a href='mailto:grievance.publishstory@timesinternet.in'>grievance.publishstory@timesinternet.in</a></p>
                            <p className='tremsAndConditionStaticContent'>You can also write to:</p>
                            <ul className='tremsAdress'>
                                <li><strong>Address:</strong></li>
                                <li><strong>Times Internet Limited</strong></li>
                                <li>391, Udyog Vihar, Phase - III,</li>
                                <li>Gurgaon, Haryana 122002, India</li>
                                <li>Ph: 0124-4518550</li>
                            </ul>
                            <p className='tremsAndConditionStaticContent'>Please ensure that you do not share any feedback communication with an unauthorized member
                                of the Company; the Company will not be responsible for the resolution of your query, in such
                                case. If you choose to write to Us, you must address such communication to “Grievance Officer”
                                and provide the following information:</p>
                            <ol className='laparent'>
                                <li>Your identification details – Name, Times Identification ID, if applicable;</li>
                                <li>Details of your specific concern(s);</li>
                                <li>Clear statement as to whether the information is personal information or sensitive

                                    personal information;</li>
                                <li>Your address, telephone number or e-mail address;</li>
                                <li>A statement that you have a good-faith belief that the information has been
                                    processed incorrectly or disclosed without authorization, as the case may be;</li>
                                <li>A statement, under penalty of perjury, that the information in the notice is
                                    accurate, and that the information being complained about belongs to you;</li>
                            </ol>

                            <p className='tremsAndConditionStaticContent'>The Company may reach out to you to confirm or discuss certain details about your complaint
                                and issues raised.</p>
                        </div>
                    </Grid>
                </CacheProvider>
            </div>

            <div className='landingPageFooter'>
                <CacheProvider value={cache}>
                <Grid container>
                        <Grid item md={12} xs={12}>
                            <div className='landingFooterText'>
                                <p>publishstory.co , Copyright © 2024 Times Internet Ltd.</p>
                            </div>
                            
                            <div className='landingFooterLinks'>
                                    <ul>
                                    {footerLinksJson.map((data, i) =>{
                                        console.log('data',data);
                                        
                                        return <li>
                                            {data.links && data.links.length > 0 && data.links.map((item, l) => {
                                        return <a target='_blank' href={item.path}><span>{item.title}</span></a>
                                        })}
                                        </li>
                                    
                                    })}
                                    </ul>
                            </div>
                            </Grid>
                            </Grid>
                </CacheProvider>
            </div>
        </div>
        </>
    )

}

export default LandingMainPage;

