import { Autocomplete, Box, Button, Checkbox, Dialog, FormControl, FormGroup, FormHelperText, Grid, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import './AddNewFeedExport.css'
import { Filtering_Type, PAGE_BUILDER_ENDPOINTS } from '../../core/constants';
import React, { useEffect, useState } from 'react';
import { getSessionObject, showErrorToast, showSuccessToast } from '../../core/Utils';
import MyAutocompleteComponent from './MyAutoCompleteComponent';
import { ClosePopup } from './ClosePopup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AllSectionOverlay } from './AllSectionOverlay';
import { hashSync } from 'm360-react-components';



export const AddNewFeedExport = (props) => {
    const [selectedValues, setSelectedValues] = useState(["article", "slideshow", "webstory", "video", "audio", "immersivestory"]);
    const [formData, setFormData] = useState({
        outputFormat: 'XML',
        filteringType: 'p',
        sortBy: 'DATE',
        contentTypes: ["article", "slideshow", "webstory", "video", "audio", "immersivestory"],
        sections: []
    });
    const [tagError, setTagError] = useState(false);
    const [sectionValue, setSectionValue] = useState([]);
    const [error, setError] = useState({});
    const [closeOverlay, setCloseOverlay] = useState(false);
    const [allSectionOverlay, setAllSectionOverlay] = useState(false);
    const [isDisabledAuto, setIsDisabledAuto] = useState(false);
    const [isDisabledTag, setIsDisabledTag] = useState(false);
    const [onBlur, setOnBlur] = useState(false);

    const [feedFields, setFeedFields] = useState([
        {
            "name": "item",
            "tagName": "item",
            "checkbox": true
        },
        {
            "name": "title",
            "tagName": "title",
            "checkbox": true
        },
        {
            "name": "description",
            "tagName": "description",
            "checkbox": true
        },
        {
            "name": "link",
            "tagName": "link",
            "checkbox": true
        },
        {
            "name": "itemId",
            "tagName": "itemId",
            "checkbox": true
        },
        {
            "name": "media",
            "tagName": "media",
            "checkbox": true
        }
    ]);

    const [sortByArray, setSortByArray] = useState([
        { label: "Rank", value: "RANK" },
        // { label: "Published Time", value: "DATE" },
        // { label: "Updated Time", value: "UPDATEDATE" },
    ]);

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'name' && value !== '') {
            // Updated regular expression
            const specialChars = /^[a-zA-Z0-9-\s]+$/;
            if (!specialChars.test(value)) {
                if (!error.hasOwnProperty("nameSpecial")) {
                    setError({ "nameSpecial": true });
                }
            } else {
                const newState = { ...error };
                delete newState.name;
                delete newState.nameSpecial;
                setError(newState);
            }
        } else if (name === 'name' && value === '') {
            if (!error.hasOwnProperty("name")) {
                setError({ "name": true })
            }
        }
        if (name === 'orIncludeTagIds' && value === '') {
            setTagError(false);
            setOnBlur(false);
        }
    };

    useEffect(() => {
        if (!formData.sortBy || !sortByArray.some(item => item.value === 'DATE')) {
            handleInputChange('sortBy', sortByArray[0].value);
        }
        if (formData.filteringType !== 'keyword-search') {
            setIsDisabledAuto(false);
            setIsDisabledTag(true);
        } else {
            setIsDisabledAuto(true);
            setIsDisabledTag(false);
        }
    }, [formData, handleInputChange, sortByArray]);

    useEffect(() => {
        //populate the data
        if (props.selectedItem !== null) {
            setFormData(props.selectedItem);
            if (props.selectedItem.contentTypes && props.selectedItem.contentTypes.length > 0) {
                setSelectedValues(props.selectedItem.contentTypes)
            }
            if (props.selectedItem.includeSecAndSubSecIds && props.selectedItem.includeSecAndSubSecIds.length > 0) {
                setSectionValue(props.selectedItem.includeSecAndSubSecIds);
            }

            if (props.selectedItem.filteringType === 'p') {
                setSortByArray([{ label: "Rank", value: "RANK" }])
            } else if (props.selectedItem.filteringType === 'h' || props.selectedItem.filteringType === 'keyword-search') {
                setSortByArray([{ label: "Rank", value: "RANK" },
                { label: "Published Time", value: "DATE" },
                { label: "Updated Time", value: "UPDATEDATE" }])
            } else {
                setSortByArray([{ label: "Published Time", value: "DATE" },
                { label: "Updated Time", value: "UPDATEDATE" }])
            }
        }
    }, [props.selectedItem])

    const selectContainerInnerOnChange = (event) => {
        if (event.target.value.length >= 3) {
            let url = PAGE_BUILDER_ENDPOINTS.DENMARK_SEARCH_NEWSROOM + '?hostId=' + getSessionObject('hostId') + '&searchTerms=' + event.target.value;
            try {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.withCredentials = true;
                xhr.send();
                if (xhr.status === 200) {
                    let top100Films = [];
                    if (JSON.parse(xhr.responseText)?.response?.length > 0) {
                        const result = JSON.parse(xhr.responseText)?.response;
                        result.forEach((item) => {
                            if (item.status == 2) {
                                item.status = 'Active'
                            } else {
                                item.status = 'In-active'
                            }
                            const obj = {
                                name: item.category,
                                msid: item.msid,
                                path: item.path,
                                status: item.status
                            }
                            top100Films.push(obj);
                        })

                    }
                    return top100Films;
                } else {
                    return []
                }
            } catch (error) {
                // Handle and possibly log the error
                console.error('Error:', error);
                return [];
            }
        } else {
            return [];
        }
    };


    function selectContainerOnChange(name, value) {
        let newValue = value.map(num => num.toString());
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    }

    const handleArrInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setSelectedValues(value);
    };

    const validateTag = (value) => {
        const hostId = getSessionObject("hostId");
        if (hostId && value) {
            let url = PAGE_BUILDER_ENDPOINTS.MENU_MANAGEMENT_TAGCHECK_API;
            const params = `?hostId=${hostId}&searchKey=${value}&perPage=1&pageNum=0&type=1001,1024,7,1025`;
            let params_decode = decodeURI(params);
            const headerString = hashSync(params_decode, 6);
            url += params;

            try {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, false); // The third parameter is set to false to make it synchronous
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                xhr.setRequestHeader('p-nonce', headerString);
                //xhr.withCredentials = true;
                xhr.send();
                if (xhr.status === 200) {
                    const valid = JSON.parse(xhr.responseText)?.items?.length > 0 ? false : true
                    setTagError(valid)
                    setOnBlur(true)
                    return valid;
                } else {
                    console.error('Request search failed with status: ' + xhr.status);
                    showErrorToast("Some Went Wrong, Please try again later!");
                    setTagError(true)
                    setOnBlur(true)
                }
            } catch (error) {
                // Handle and possibly log the error
                console.error('Error:', error);
                setTagError(true)
                setOnBlur(true)
                throw error; // You may choose to rethrow the error if needed
            }
        } else {
            setTagError(false)
            setOnBlur(true)
        }
    }


    function submitCustomFeed() {
        if (typeof formData.name === 'undefined' || formData.name === '') {
            setError({ "name": true })
            return;
        } else {
            const newState = { ...error };
            delete newState.name;
            setError(newState);
        }
        if (formData?.contentTypes?.length === 0) {
            showErrorToast("Story type cannot be blank.")
            return;
        }
        if (tagError && formData.filteringType === 'keyword-search') {
            showErrorToast("Please enter the valid tag.")
            return;
        }
        let tags = typeof formData.orIncludeTagIds === "object" ? formData.orIncludeTagIds : [formData.orIncludeTagIds];
        if (tags.length > 0 && tags[0] === '' && formData.filteringType === 'keyword-search') {
            showErrorToast("Please enter the valid tag.")
            return;
        }
        if (tags.length === 0 && formData.filteringType === 'keyword-search') {
            showErrorToast("Please enter the valid tag.")
            return;
        }
        if (formData?.sections?.length === 0 && formData.filteringType !== 'keyword-search') {
            setAllSectionOverlay(true);
            return;
        }

        sendData();
    }

    function sendData(websiteValue) {
        if (Object.keys(error).length === 0) {
            let sectionss = [];
            if (typeof websiteValue !== 'undefined' && typeof websiteValue.denmarkObject !== 'undefined' && typeof websiteValue.denmarkObject.msid !== 'undefined') {
                sectionss = [websiteValue.denmarkObject.msid.toString()];
            } else {
                sectionss = formData.sections;
            }
            let sendData = {
                isActive: true,
                name: formData.name,
                feedFields: feedFields,
                includeSecAndSubSecIds: sectionss,
                orIncludeTagIds: typeof formData.orIncludeTagIds === "object" ? formData.orIncludeTagIds : [formData.orIncludeTagIds],
                outputFormat: formData.outputFormat,
                contentTypes: formData.contentTypes,
                itemsPerPage: 10,
                filteringType: formData.filteringType,
                sortBy: formData.sortBy
            }
            if (props.selectedItem !== null) {
                sendData.isActive = props.selectedItem.isActive;
                sendData.feedId = props.selectedItem.feedId;
                sendData.feedFields = props.selectedItem.feedFields;
            }


            let metaData = JSON.parse(sessionStorage.getItem('metaData'));
            let url = PAGE_BUILDER_ENDPOINTS.DOMAIN + `/feeds/createOrUpdate?websiteId=${metaData.websiteId}`;
            try {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', url, false); // Change to POST method
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.withCredentials = true;

                xhr.send(JSON.stringify(sendData));

                if (xhr.status === 201) {
                    const out = JSON.parse(xhr.responseText);
                    //showSuccessToast("Feed created successfully with feed id: " + out?.feedId);
                    showSuccessToast(`Your feed has been created with default configuration. Please select configure the feed for any changes.`)
                    props.handleCloseOverlay();
                    props.getAllFeedsOnPage();
                } else if (xhr.status === 200) {
                    const out = JSON.parse(xhr.responseText);
                    showSuccessToast("Feed updated successfully with feed id: " + out?.feedId);
                    props.handleCloseOverlay();
                    props.getAllFeedsOnPage();
                } else {
                    console.error('Request addSiteResources failed with status: ' + xhr.status);
                    showErrorToast("Some Went Wrong, Please try again later!");
                }
            } catch (error) {
                console.error('Request addSiteResources failed with status: ' + xhr.status);
                showErrorToast("Some Went Wrong, Please try again later!");
            }
        }
    }

    const closeTheWindow = () => {
        if (props.selectedItem !== null && JSON.stringify(props.selectedItem) !== JSON.stringify(formData))
            setCloseOverlay(true)
        else if (props.selectedItem === null && JSON.stringify(formData) !== '{"outputFormat":"XML","filteringType":"p","sortBy":"RANK","contentTypes":["article","slideshow","webstory","video","audio","immersivestory"],"sections":[]}')
            setCloseOverlay(true)
        else
            props.handleCloseOverlay();
    }

    const onCloseOverlay = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setCloseOverlay(false);
    };

    const onAllSectionOverlay = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setAllSectionOverlay(false);
    };

    function capitalizeFirstChar(str) {
        if (typeof str !== 'string' || str.length === 0) {
            return str; // Return the input as-is if it's not a string or is empty
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function enableDisableChange(val) {
        if (val === 'p' || val === 'cp' || val === 'h' || val === 'lh' || val === 'ch' || val === 'sh') {
            // setIsDisabledAuto(false);
            // setIsDisabledTag(true);
            setSectionValue([]);
            setFormData((prevData) => ({
                ...prevData,
                sections: [],
                orIncludeTagIds: []
            }));
        } else {
            // setIsDisabledAuto(true);
            // setIsDisabledTag(false);
            setSectionValue([]);
            setFormData((prevData) => ({
                ...prevData,
                sections: [],
                orIncludeTagIds: []
            }));
        }
        if (val === 'p') {
            setSortByArray([{ label: "Rank", value: "RANK" }])
            setFormData((prevData) => ({
                ...prevData,
                sortBy: 'RANK'
            }));
        } else if (val === 'h' || val === 'keyword-search') {
            setSortByArray([{ label: "Rank", value: "RANK" },
            { label: "Published Time", value: "DATE" },
            { label: "Updated Time", value: "UPDATEDATE" }])
            setFormData((prevData) => ({
                ...prevData,
                sortBy: 'RANK'
            }));
        } else {
            setSortByArray([{ label: "Published Time", value: "DATE" },
            { label: "Updated Time", value: "UPDATEDATE" }])
            setFormData((prevData) => ({
                ...prevData,
                sortBy: 'DATE'
            }));
        }
    }

    const sanitizeInput = (value) => {
        // Remove invalid characters
        // const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        // // Ensure the first character is not a digit or period
        // if (/^[0-9.]/.test(sanitizedValue)) {
        //     return sanitizedValue.slice(1);
        // }
        return value.substring(0, 50);

    };

    return (
        <>
            <div className="RectangleFeed" >
                {(
                    <>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <FormGroup className='formGroupAddNewFeed'>
                                    <div className='LandingTopWrapperCss'>
                                        <div className='LandingHeading'>
                                            <p className='welcomeText'>Create Custom Feed</p>
                                        </div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <p className="inputUpperTextNewFeed">Name<sup>*</sup></p>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <OutlinedInput
                                                        required
                                                        id="filled-required1"
                                                        variant="filled"
                                                        className="customInputFiled"
                                                        inputProps={{
                                                            style: { background: '#F8FAFC' }
                                                        }}
                                                        value={formData.name || ''}
                                                        //error={domainError}
                                                        onChange={(e) => {
                                                            let sant = sanitizeInput(e.target.value);
                                                            handleInputChange('name', sant)
                                                        }}
                                                    //helperText={domainError ? 'This domain is invalid or already exists in the publish story' : ''}
                                                    />
                                                    {/* <MyFormHelperText /> */}
                                                    {error?.name && <FormHelperText style={{ color: "red", top: '-15px', position: 'relative' }}>Enter feed name</FormHelperText>}
                                                    {error?.nameSpecial && <FormHelperText style={{ color: "red", top: '-15px', position: 'relative' }}>Only alphanumeric characters are allowed</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <p className="inputUpperTextNewFeed">Output Format<sup>*</sup></p>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <Select
                                                        required
                                                        id="filled-required2"
                                                        className="customInputFiled"
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        value={formData.outputFormat || ('XML' && handleInputChange('outputFormat', 'XML'))}
                                                        onChange={(e) => handleInputChange('outputFormat', e.target.value)}
                                                        style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                    >
                                                        <MenuItem value={'XML'}>XML</MenuItem>
                                                        <MenuItem value={'JSON'}>JSON</MenuItem>
                                                        <MenuItem value={'RSS'}>RSS</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <div className='LandingHeading'>
                                            <p className='filteringOptions'>Filtering Options</p>
                                        </div>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <p className="inputUpperTextNewFeed">Story Type</p>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <Select
                                                        labelId="demo-mutiple-checkbox-label"
                                                        id="filled-required3"
                                                        multiple
                                                        className="customInputFiled"
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        value={formData.contentTypes || ["article", "slideshow", "webstory", "video", "audio", "immersivestory"]}
                                                        onChange={(e) => handleArrInputChange('contentTypes', e.target.value)}
                                                        renderValue={(selected) => selected.map(capitalizeFirstChar).join(', ')}
                                                        style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                    >
                                                        <MenuItem value={'article'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Article" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('article') > -1} />
                                                        </MenuItem>
                                                        <MenuItem value={'slideshow'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Slideshow" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('slideshow') > -1} />
                                                        </MenuItem>
                                                        <MenuItem value={'webstory'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Webstory" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('webstory') > -1} />
                                                        </MenuItem>
                                                        <MenuItem value={'video'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Video" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('video') > -1} />
                                                        </MenuItem>
                                                        <MenuItem value={'audio'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Podcast" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('audio') > -1} />
                                                        </MenuItem>
                                                        <MenuItem value={'immersivestory'} className='addNewFeedMenuItem'>
                                                            <ListItemText primary="Immersive Story" className='addNewFeedListItemText' />
                                                            <Checkbox className="addNewFeedCheckbox" checked={selectedValues.indexOf('immersivestory') > -1} />
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <p className="inputUpperTextNewFeed">Filtering Type</p>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <Select
                                                        required
                                                        id="filled-required4"
                                                        className="customInputFiled"
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        value={formData.filteringType || ('p' && handleInputChange('filteringType', 'p'))}
                                                        onChange={(e) => {
                                                            handleInputChange('filteringType', e.target.value);
                                                            enableDisableChange(e.target.value);
                                                        }}
                                                        style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                    >
                                                        {Filtering_Type.map((item) => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <div className="inline-container">
                                                    <p className="inputUpperTextNewFeed"
                                                        style={{
                                                            color: isDisabledAuto ? 'grey' : 'black'
                                                        }}
                                                    >Search Section
                                                    </p>
                                                    <Tooltip
                                                        title="All sections will be selected if this field is left empty."
                                                        arrow
                                                        style={{
                                                            color: isDisabledAuto ? 'grey' : 'black'
                                                        }}
                                                    >
                                                        <span>
                                                            <InfoOutlinedIcon
                                                                style={{ height: '18px' }} />
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <MyAutocompleteComponent
                                                        isDisabledAuto={isDisabledAuto}
                                                        //selectContainerOnChange={(e) => selectContainerOnChange('sections', event.target.parentElement.childNodes[1].innerText.split(" - ")[0])}
                                                        selectContainerOnChange={(e) => selectContainerOnChange('sections', e.map(item => item.msid ? item.msid : item))}
                                                        selectContainerInnerOnChange={selectContainerInnerOnChange}
                                                        values={sectionValue}
                                                        formData={formData} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <p className="inputUpperTextNewFeed"
                                                    style={{
                                                        color: isDisabledTag ? 'grey' : 'black'
                                                    }}
                                                >Search Tag</p>
                                                <FormControl fullWidth className="formControlWrapper">
                                                    <OutlinedInput
                                                        required
                                                        id="filled-required5"
                                                        disabled={isDisabledTag}
                                                        variant="filled"
                                                        className="customInputFiled"
                                                        style={{
                                                            color: isDisabledTag ? 'grey' : 'black',
                                                            background: isDisabledTag ? '#F6F6F6' : 'rgb(248, 250, 252)'
                                                        }}
                                                        inputProps={{
                                                            style: { background: isDisabledTag ? '#F6F6F6' : 'rgb(248, 250, 252)' }
                                                        }}
                                                        value={(typeof formData.orIncludeTagIds === 'object' && formData.orIncludeTagIds.length > 0) ? formData.orIncludeTagIds[0] : formData.orIncludeTagIds || ''}
                                                        error={tagError}
                                                        onChange={(e) => {
                                                            handleInputChange('orIncludeTagIds', e.target.value);
                                                        }}
                                                        onBlur={(e) => {
                                                            validateTag(e.target.value);
                                                        }}
                                                    />
                                                    {formData.orIncludeTagIds && formData.orIncludeTagIds != "" && tagError === false && onBlur && <FormHelperText style={{ color: 'green', top: '-15px', position: 'relative' }}>Valid Tag</FormHelperText>}
                                                    {formData.orIncludeTagIds && formData.orIncludeTagIds != "" && tagError === true && onBlur && <FormHelperText style={{ color: 'red', top: '-15px', position: 'relative' }}>Invalid Tag</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl fullWidth className="formControlWrapper">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <p className="inputUpperTextNewFeed">Sort By</p>
                                                    <Select
                                                        required
                                                        id="filled-required6"
                                                        className="customInputFiled"
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        // value={formData.sortBy || ('DATE' && handleInputChange('sortBy', 'DATE'))}
                                                        // onChange={(e) => handleInputChange('sortBy', e.target.value)}
                                                        value={formData.sortBy || ''}
                                                        onChange={(e) => handleInputChange('sortBy', e.target.value)}
                                                        style={{ backgroundColor: '#F8FAFC', textAlign: '-webkit-left' }}
                                                    >
                                                        {sortByArray.map((item) => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                        <FormControl fullWidth className="formControlWrapper">
                                            <Grid container spacing={2}>
                                                <Grid item sm={12}>
                                                    <Box display="flex" justifyContent="center" gap={2}>
                                                        <Button variant="contained" onClick={submitCustomFeed}>Submit</Button>
                                                        <Button variant="outlined" onClick={closeTheWindow}>Cancel</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </div>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </>
                )}
            </div >
            <Dialog open={closeOverlay} onClose={onCloseOverlay} className='closePopup'>
                <ClosePopup handleCloseOverlay={onCloseOverlay} parentClose={props.handleCloseOverlay}></ClosePopup>
            </Dialog >
            <Dialog open={allSectionOverlay} onClose={onAllSectionOverlay} className='closePopup'>
                <AllSectionOverlay handleCloseOverlay={onAllSectionOverlay} websiteValue={props.websiteValue} sendData={sendData}></AllSectionOverlay>
            </Dialog >
            <div className="Clear" style={{ width: 36, height: 36, position: 'absolute', right: 0, cursor: 'pointer' }} onClick={closeTheWindow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 64 64" fill="none">
                    <path d="M44.6263 40.8662C45.0168 41.2568 45.0168 41.8899 44.6263 42.2804L42.2805 44.6262C41.89 45.0168 41.2568 45.0168 40.8663 44.6262L32.0001 35.76L23.1339 44.6262C22.7433 45.0168 22.1102 45.0168 21.7196 44.6262L19.3739 42.2804C18.9833 41.8899 18.9833 41.2568 19.3739 40.8662L28.2401 32L19.3739 23.1338C18.9833 22.7433 18.9833 22.1101 19.3739 21.7196L21.7196 19.3738C22.1102 18.9833 22.7433 18.9833 23.1339 19.3738L32.0001 28.24L40.8663 19.3738C41.2568 18.9833 41.89 18.9833 42.2805 19.3738L44.6263 21.7196C45.0168 22.1101 45.0168 22.7433 44.6263 23.1338L35.7601 32L44.6263 40.8662Z" fill="black" />
                </svg>
            </div>
        </>
    );
}