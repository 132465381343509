import React from "react";
import ReactDOM from "react-dom";
import "./gjs.scss";
import "./layout.css";
import Grapesjs from "grapesjs";
import "../../index.css";
import { connect } from "react-redux";
import BaseReactComponent from "../../core/base-react-component";
import FactoComponents from "../../core/facto-components";
// import { Card } from "@facto-ui/cms360";
import { getCss } from "../../core/get-css";
// import LayoutComponents from "../../core/layout-components";
import { persistData, logoutUserSession, getSessionObject, getDefaultMsids, removeLocalStorage } from "../../core/Utils";

import { GetAllWebsites } from "../../core/Service/PageTemplateService";
import { SavePagePopupComponent } from "../../core/Service/VerifySavePageTemplate";
import { createCustomTraits, addCustomPanelTools } from '../../core/custom-fields'
import logoImage from "../../images/logo_pagebuilder.svg";
import { UserSessionComponent } from "../../core/Service/PageUserSessionService";


export function MyCustomPanel() {
  return <div>
    <button onClick={() => alert('Button clicked')}>Custom Button</button>
  </div>
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    window.gpEditor = "";
    this.state = {
      isAnyUnSavedChangesPreset: false,
      showSaveModal: false,
      publishToProd: false,
      pageTemplateData: null,
      previewBtnClicked: false,
      globalComponentAdded: false
    };
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    removeLocalStorage('hash');
  }


  toggleSaveModal = (value) => {
    this.setState({ showSaveModal: value });
  };

  setPublishToProd = (value) => {
    this.setState({ publishToProd: value });
  };

  setPageTemplateData = () => {
    if (this.state.pageTemplateData === null)
      this.setState({ ...this.state, pageTemplateData: undefined });
    else if (this.state.pageTemplateData === undefined)
      this.setState({ ...this.state, pageTemplateData: null });
  };

  handleBeforeUnload = (e) => {
    const { isAnyUnSavedChangesPreset } = this.state;
    if (isAnyUnSavedChangesPreset) {
      e.preventDefault();
      e.returnValue = "Are you sure?";
    }
  };

  handleCustomToolTip = () => {
    window.gpEditor.Panels.getButton("options", "sw-visibility").set("attributes", { title: "View Grid" });
    window.gpEditor.Panels.getButton("options", "sw-visibility").set("id", "sw-visibility_1");
    window.gpEditor.Panels.getButton("options", "fullscreen").set("attributes", { title: "Full Screen" });
    window.gpEditor.Panels.getButton("options", "fullscreen").set("id", "fullscreen_1");
    window.gpEditor.Panels.getButton("views", "open-tm").set("attributes", { title: "Component Properties" });
    window.gpEditor.Panels.getButton("views", "open-tm").set("id", "open-tm_1");
    window.gpEditor.Panels.getButton("views", "open-layers").set("attributes", { title: "Layout" });
    window.gpEditor.Panels.getButton("views", "open-layers").set("id", "open-layers_1");
    window.gpEditor.Panels.getButton("views", "open-blocks").set("attributes", { title: "Component Library" });
    window.gpEditor.Panels.getButton("views", "open-blocks").set("id", "open-blocks_1");
    window.gpEditor.DeviceManager.get('Mobile portrait').set("width", 370, "");
  }

  customPgBuilderHandle = () => {
    let trtHeader = document.querySelector(".trt-header");
    if (trtHeader) {
      trtHeader.innerHTML = "Please select an element within the builder canvas to configure its properties";
    }

    let previewBtn = document.querySelector(`[title=Preview]`);

    previewBtn.addEventListener("click", () => {
      let elm = document.querySelector(".custom-pagebuilder");
      elm.classList.add("preview")


      if (!this.state.previewBtnClicked) {
        let removePreviewBtn = document.querySelector(".fa-eye-slash");
        removePreviewBtn.addEventListener("click", () => {
          let elm = document.querySelector(".custom-pagebuilder");
          elm.classList.remove("preview")
        })
        this.setState({
          previewBtnClicked: true
        })
      }
    })

    this.handleCustomToolTip();
  }

  componentDidMount() {
    this.initializeEditor();
  }
  openTraitManager = () => {
    window.gpEditor.runCommand("open-tm");
  };

  stopMethodOnClick = (e) => {
    // Call the stop method
    if (e.target.title == "More apps") {
      return false;
    }

    window.gpEditor.Panels.getButton("views", "nine-dots").set("active", false);
    document.body.removeEventListener('click', this.stopMethodOnClick);
  }

  addPanelTools = (editor) => {
    const panelManager = editor.Panels;
    editor.getConfig().showDevices = 0;
    editor.Panels.addPanel({
      id: 'devices', buttons: [
        { name: "dsf", id: "set-device-desktop", command: { run: function (e) { return e.setDevice("Desktop") }, stop() { } }, togglable: true, className: "fa fa-desktop", active: true },
        { name: "dssdf", id: "set-device-mobile", command: { run: function (e) { return e.setDevice("Mobile portrait") }, stop() { } }, togglable: true, className: "fa fa-mobile", }
      ]
    });

    editor.Panels.addButton('options', {
      id: 'clear-canvas',
      className: 'fa fa-trash',
      command: 'clear-canvas',
      togglable: true,
      attributes: {
        title: 'Clear Canvas', // Tooltip text
      },
    });

    editor.Panels.addButton('options', {
      id: 'custom-panel',
      className: 'fa fa-undo',
      command: 'undo',
      togglable: true,
      attributes: {
        title: 'Undo', // Tooltip text
      },
    });

    editor.Panels.addButton('options', {
      id: 'custom-panel2',
      className: 'fa fa-repeat',
      command: 'redo',
      togglable: true,
      attributes: {
        title: 'Redo', // Tooltip text
      },
    });

    editor.Commands.add("clear-canvas", {
      run: (editor, sender) => {
        //console.log("sender", sender)
        editor.DomComponents.clear(); // Clear all components from the canvas
      },
      stop() { }
      // You can add more options if needed
    });

    editor.Commands.add("undo", {
      run: (editor) => {
        editor.runCommand("core:undo")
      },
      stop() { }
    });

    editor.Commands.add("redo", {
      run: (editor) => editor.runCommand("core:redo"),
      stop() { }
    });

    editor.Panels.addButton("views", {
      id: "save-global-settings",
      className: "fa fa-sliders",
      togglable: true,
      command: "save-global-command",
      attributes: {
        title: "Global Settings",
      },
    });

    editor.Panels.addButton("views", {
      id: "nine-dots",
      className: "fa fa-th",
      command: "nine-dots",
      togglable: true,
      attributes: {
        title: "More apps", // Tooltip text
        // label: <div></div>
      },
    });

    var overlay;

    editor.Commands.add("nine-dots", {
      run: () => {

        if (!overlay) {
          overlay = document.createElement('div');
          overlay.innerHTML = `
          <div class="more-appsoverlay">
          <ul>
          <li title="Authoring">
            <a href="https://app.publishstory.co/authoring/" target="#" title="Publish your content via headless CMS">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Authoring_denmark.png">
        
              </i>
              <p>Authoring</p>
            </a>
          </li>
          <li title="Reporting">
          <a href="https://app.publishstory.co/reporting/cms-analytics/" target="#" title="Reporting">
            <i>
              <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Reporting_cmsanalytics.png">
      
            </i>
            <p>Reporting</p>
          </a>
        </li>
        
          <li title="Analytics">
            <a href="https://app.publishstory.co/analytics/" target="#" title="Analytics">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Analytics_ibeat.png">
        
              </i>
              <p>Analytics</p>
            </a>
          </li>
        
          <li title="Page Builder">
            <a href="https://app.publishstory.co/pagebuilder/" target="#" title="Page Builder">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Pagebuilder_m360.png">
              </i>
              <span>Page Builder</span>
            </a>
          </li>
        
          <li title="Asset Manager">
            <a href="/" target="#" title="Asset Manager">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/assetmanager_chronicle.png">
              </i>
              <span>Asset Manager</span>
            </a>
          </li>
          <li title="Admin">
            <a href="https://app.publishstory.co/admin/" target="#" title="Admin">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Admin_coral.png">
        
              </i>
              <p>Admin</p>
            </a>
          </li>
          <li title="Comment Moderator">
            <a href="/" target="#" title="Comment Moderator">
              <i>
                <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/Comment%20moderator.png">
              </i>
              <span>Comment Moderator</span>
            </a>
          </li>
          <li title="Video Authoring">
          <a href="/" target="#" title="Video Authoring">
            <i>
              <img src="https://pagebuilder.publishstory.co/stresources/custom-icons/VideoAuthoring_slike.png">
            </i>
            <span>Video Authoring</span>
          </a>
        </li>
        
        </ul>
          </div>
          
          `
          const panels = editor.Panels.getPanel('views')
          panels.set('appendContent', overlay).trigger('change:appendContent');
        }
        overlay.style.display = "block";
        document.body.addEventListener('click', this.stopMethodOnClick);
      },
      stop() {
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    });

    editor.Panels.addButton("views", {
      id: "logout",
      className: "fa fa-sign-out",
      command: "logout",
      attributes: {
        title: "Logout", // Tooltip text
      },
    });

    editor.Commands.add("logout", {
      run: function (editor) {
        logoutUserSession();
      }.bind(this),
      stop() { }
    });

    editor.Panels.addButton("options", [
      {
        id: "savebtn",
        className: "fa fa-save",
        command: "save-db",
        togglable: false,
        attributes: {
          title: "Save",
        },
      },
    ]);

    // editor.Panels.addButton("options", [
    //   {
    //     id: "save-layout",
    //     className: "fa fa-columns",
    //     command: "save-layout",
    //     togglable: true,
    //     attributes: {
    //       title: "Save Layout",
    //     },
    //   },
    // ]);

    editor.Commands.add("save-db", {
      run: function (editor, sender) {
        let comps = JSON.stringify(editor.getComponents());
        let css = JSON.stringify(getCss(editor));
        persistData(comps, css);
        this.toggleSaveModal(true);
        this.setPublishToProd(false);
        editor.Panels.getButton("options", "savebtn").set("attributes", { title: "Already Saved" });
      }.bind(this),
      stop() {
        editor.Panels.getButton("options", "savebtn").set("attributes", { title: "Save" });
      }
    });

    editor.Panels.addButton("options", [
      {
        id: "publishBtn",
        className: "fa fa-upload",
        command: "publish-db",
        togglable: false,
        attributes: {
          title: "Publish",
        },
      },
    ]);

    editor.Commands.add("publish-db", {
      run: function (editor, sender) {
        let comps = JSON.stringify(editor.getComponents());
        let css = JSON.stringify(getCss(editor));
        persistData(comps, css);
        this.toggleSaveModal(true);
        this.setPublishToProd(true);
        editor.Panels.getButton("options", "publishBtn").set("attributes", { title: "Already Published" });
      }.bind(this),
      stop() {
        editor.Panels.getButton("options", "publishBtn").set("attributes", { title: "Publish" });
      }
    });

    // editor.Commands.add("save-layout", {
    //   run: function (editor, sender) {
    //     let comps = JSON.stringify(editor.getComponents());
    //     let css = JSON.stringify(getCss(editor));
    //     persistData(comps, css);
    //     savePageLayout();
    //   },
    //   stop() {} 
    // });

    // Add a custom button with an icon

    editor.on("run:clear-canvas", () => {
      persistData("", "");
    });

    let element = null;

    editor.Commands.add("save-global-command", {
      run: () => {
        if (!element) {
          let globalJson = JSON.parse(getSessionObject('globalJson'));
          let globalCss = JSON.parse(getSessionObject('globalCss'));
          let siteResourceData = getDefaultMsids();
          // let themeData=getThemePagesByWebsite();
          //console.log("site: ",siteResourceData);
          const editMenuDiv = document.createElement('div');
          ReactDOM.render(<GlobalSettingComponent siteResource={siteResourceData} themeJson={globalJson} themeCss={globalCss} />, editMenuDiv);
          const panels = editor.Panels.getPanel('views-container')
          panels && panels.set('appendContent', editMenuDiv).trigger('change:appendContent');
          element = editMenuDiv
        }
        element.style.display = 'block';
      },
      stop: () => {
        if (element) {
          element.style.display = 'none';
          element = null;
        }
      }
    });


    editor.on("storage:autosave", () => {
      //console.log("save timestamp");
    });
  };

  isAnyChangeInScreen = (value) => {
    this.setState({
      isAnyUnSavedChangesPreset: value,
    });
  };

  initializeEditor = () => {
    window.gpEditor = Grapesjs.init({
      fromElement: 1,
      color: 'white',
      container: '#gjs',
      fromElement: false,
      showDevices: true,
      // autorender: false,
      traits: true,
      noticeOnUnload: false,
      isAnyChangeInScreen: this.isAnyChangeInScreen,
      jsInHtml: true,
      plugins: [BaseReactComponent, FactoComponents],
      pluginsOpts: {
        "gjs-blocks-basic": {
          "blocks": ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video', 'map'],
          "labelLink": "new Link"
        }
      },

      layerManager: {
        stylePrefix: 'm360',
      },

      blockManager: {
        stylePrefix: 'm360'
      },
    });

    createCustomTraits(window.gpEditor);
    addCustomPanelTools(window.gpEditor, this.toggleSaveModal, this.setPublishToProd);
    this.customPgBuilderHandle();
  };

  render() {
    return (
      <>
        <UserSessionComponent />
        <div className="custom-pagebuilder">
          <div className="container">
            {this.state.showSaveModal && <SavePagePopupComponent state={this.state} toggleSaveModal={this.toggleSaveModal}></SavePagePopupComponent>}

            <div className="topbar" id="website-box">
              <figure><img src={logoImage} /></figure>
              <GetAllWebsites pageTemplateData={this.state.pageTemplateData} setPageTemplateData={this.setPageTemplateData}></GetAllWebsites>
            </div>

            <div id="gjs" className="editor-row">
              <div className="panel__devices"></div>
              <div className="panel__basic-actions"></div>
              <div className="editor-canvas">
                <div id="'page-id" ><h1></h1></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  componentListData: state.pagebuilderData.componentsList,
  layOutDataJsonVal: state.pagebuilderData.layoutDataJson,
});

export default connect(mapStateToProps)(Home);